import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
// import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
// import Iconify from 'src/components/Iconify';
import axios from 'axios';
import { base_url } from 'src/common';
import { Typography, Stack, TextField, Switch, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const useStyle = makeStyles((theme) => ({
  modalMain: {
    width: '530px',

    // height: '530px',

    // backgroundColor: theme.palette.primary.dark,

    border: '1px solid rgba(239, 239, 239, 0.1)',

    borderRadius: '10px',

    borderColor: '#fff',
    padding: '15px 15px',
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '15px 15px',
    },
    '& .heading': {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
    },
  },
}));

export default function AddDialogue(props) {
  const { setAddOpen, addOpen, getList, clientId, setEditOpen, editOpen, editData } = props;
  const classes = useStyle();
  console.log('editData', editData);
  const [loadingState, setloadingState] = useState(false);
  const [checked, setChecked] = useState(false);
  // const [edit, IsEdit] = useState(false);

  const token = sessionStorage.getItem('libra_admin_token');

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Enter proper email address').required('Email is required'),
    job_title: Yup.string().required('Job title is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      job_title: '',
      is_default: '0',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setloadingState(true);
      console.log('values', values);
      // navigate('/dashboard', { replace: true });
      const duplicateArr = { ...values };
      duplicateArr.client_id = clientId;
      console.log(duplicateArr);
      switch (addOpen) {
        case true:
          axios
            .post(`${base_url}/api/client-sponsors`, duplicateArr, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
              setloadingState(false);
              console.log(res);
              if (res?.data?.status === true) {
                setAddOpen(false);
                toast.success('Sponsor added successfully');
                clearData();
                getList();
              }
            })
            .catch((err) => {
              setloadingState(false);

              if (err?.response?.data?.error) {
                toast.error(err?.response?.data?.error);
              } else {
                toast.error('Server Error');
              }
            });
          break;
        case false:
          axios
            .put(`${base_url}/api/client-sponsors/${editData?.id}`, duplicateArr, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              setloadingState(false);
              console.log('res', res);
              if (res?.data?.status === true) {
                setEditOpen(false);
                toast.success('Sponsor Update successfully');
                clearData();
                getList();
              }
            })
            .catch((err) => {
              setloadingState(false);

              if (err?.response?.data?.error) {
                toast.error(err?.response?.data?.error);
              } else {
                toast.error('Server Error');
              }
            });
          break;
        default:
          break;
      }
    },
  });

  const { errors, touched, setFieldValue, resetForm, handleSubmit, getFieldProps } = formik;
  console.log('values', editData?.is_default);
  useEffect(() => {
    setFieldValue('name', editData?.name);
    setFieldValue('email', editData?.email);
    setFieldValue('job_title', editData?.job_title);
    setChecked(editData?.is_default);
  }, [editData, editOpen]);
  useEffect(() => {
    clearData();
  }, [addOpen]);
  const clearData = () => {
    resetForm();
  };

  return (
    <div>
      <Dialog
        open={addOpen || editOpen}
        onClose={() => {
          setAddOpen(false);
          setEditOpen(false);
          clearData();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ classes: { root: classes.modalMain } }}
      >
        <DialogTitle id="alert-dialog-title" className="heading">
          {addOpen ? 'Add Sponsor' : 'Update Sponser'}
        </DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Job Title"
                  {...getFieldProps('job_title')}
                  error={Boolean(touched.job_title && errors.job_title)}
                  helperText={touched.job_title && errors.job_title}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="body1">Set primary sponsor</Typography>
                  {console.log('checked', checked)}
                  <Switch
                    sx={{ background: 'rgba(0,0,0,0.1)' }}
                    // defaultChecked={editData?.is_default ? '1' : '0'}
                    checked={checked}
                    // {...getFieldProps('is_default')}
                    onChange={(e) => {
                      setFieldValue('is_default', e.target.checked ? '1' : '0');
                      setChecked(e.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Box>
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mt: 3 }}>
                <Button
                  onClick={() => {
                    setAddOpen(false);
                    setEditOpen(false);
                    clearData();
                  }}
                  variant="outlined"
                  sx={{ marginRight: '1rem' }}
                  size="large"
                >
                  Cancel
                </Button>
                <LoadingButton size="large" type="submit" variant="contained" loading={loadingState}>
                  {addOpen ? 'Submit' : 'Update'}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
        {/* <DialogActions>
          <Button
            onClick={() => {
              setAddOpen(false);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>

      <Toaster />
    </div>
  );
}

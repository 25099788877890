import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';

// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// component
import Iconify from '../../../components/Iconify';
import axios from 'axios';
import { base_url } from 'src/common';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loadingState, setloadingState] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setloadingState(true);
      // console.log('values', values);
      // navigate('/dashboard', { replace: true });
      axios
        .post(`${base_url}/api/login`, values)
        .then((res) => {
          console.log('res', res);
          setloadingState(false);
          if (res?.data?.status === true) {
            toast.success(`Welcome back ${res?.data?.data?.user?.name}`);
            sessionStorage.setItem('libra_admin_token', res?.data?.data?.user?.api_token);
            sessionStorage.setItem('mentoring_user', JSON.stringify(res?.data?.data?.user));
            if (res?.data?.data?.user?.role === 'company') {
              sessionStorage.setItem('Company_id', res?.data?.data?.user?.id);
            } else if (res?.data?.data?.user?.role === 'client' || res?.data?.data?.user?.role === 'coach') {
              sessionStorage.setItem('all_coach_token', res?.data?.data?.user?.api_token);
              localStorage.setItem('all_coach', JSON.stringify(res?.data?.data?.user));
              localStorage.setItem('get_id', JSON.stringify(res?.data?.data?.user?.id));
              sessionStorage.setItem('Company_id', res?.data?.data?.user?.company?.id);
            }

            setTimeout(() => {
              navigate('/dashboard/app', { replace: true });
            }, 2000);
          }
        })
        .catch((err) => {
          setloadingState(false);
          console.log(err?.response);
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            toast.error('Network error');
          }
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
            {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Forgot Password?"
          /> */}
            <Typography sx={{ mx: 1 }}>Forgot Password? </Typography>

            <Link component={RouterLink} variant="subtitle2" to="/forget_password" underline="hover">
              Contact Us
            </Link>
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loadingState}>
            Login
          </LoadingButton>
        </Form>
      </FormikProvider>
      <Toaster />
    </>
  );
}

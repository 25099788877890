import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { base_url } from 'src/common';
import { Autocomplete, Box, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RatingStar } from 'rating-star';

const useStyle = makeStyles((theme) => ({
  modalMain: {
    width: '530px',

    // height: '530px',

    // backgroundColor: theme.palette.primary.dark,

    border: '1px solid rgba(239, 239, 239, 0.1)',

    borderRadius: '10px',

    borderColor: '#fff',
    padding: '15px 15px',
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '15px 15px',
    },
    '& .heading': {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
    },
  },
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '38px',
    color: '#101828',
    paddingBottom: '32px',
  },
  management: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingTop: '32px',
    paddingBottom: '4px',
  },
  body1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
  },
  ParticipantDetails: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingBottom: '4px',
  },
  body2text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
    marginTop: '0px !important',
  },
  btncontainer: {
    '& .Mui-selected': {
      color: '#6941C6',
    },
    '& button': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#344054',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '12px',
      paddingRight: '12px',
      marginRight: '1rem',
      border: '0px !important',
      borderRadius: '5px !important',
      '&:focus': {
        color: '#6941C6',
      },
      '& svg': {
        width: '20px !important',
        height: '20px !important',
        marginLeft: '12px',
      },
    },
  },
  addButton: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '144px',
    height: '40px',
    background: '#1570EF',
    border: '1px solid #1570EF',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    '&:hover': {
      background: '#1570EF',
    },
  },
  dataToShow: {
    height: '56px',
    width: '100%',
    borderRadius: '8px',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    padding: '15px',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    background: '#F9FAFB',
  },
  allInputFields: {
    // '& input': {
    //   boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    //   borderRadius: '8px',
    //   background: '#F9FAFB',
    // },
    '& .MuiOutlinedInput-root': {
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      background: '#F9FAFB',
    },
    '& .MuiTextField-root': {
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      background: '#F9FAFB',
    },
  },
}));

export default function FeedbackDetails(props) {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);
  const classes = useStyle();

  const [rating, setRating] = useState(location?.state?.rating);

  const token = sessionStorage.getItem('libra_admin_token');

  const LoginSchema = Yup.object().shape({
    // client_id: Yup.string().required('Client name is required'),
    // participant_id: Yup.string().required('Participant name is required'),
    // coach_id: Yup.string().required('Coach name is required'),
    // programme_id: Yup.string().required('Programme name is required'),
    // session_id: Yup.string().required('Programme name is required'),
    // session_groups: Yup.string().required('Session group name is required'),
    feedback: Yup.string().required('Feedback is required').max(1000, 'maximum 1000 characters'),
    rating: Yup.string().required('Rating is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // client_id: '',
      // participant_id: '',
      // coach_id: '',
      // programme_id: '',
      // session_groups: '',
      feedback: '',
      rating: '',
      // session_id: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {},
  });

  const { errors, touched, setFieldValue, resetForm, handleSubmit, getFieldProps, values } = formik;
  useEffect(() => {
    setFieldValue('feedback', location?.state?.feedback);
    setFieldValue('rating', location?.state?.rating);
  }, []);
  const clearData = () => {
    resetForm();
  };
  const onRatingChange = (val) => {
    setRating(val);
    console.log(typeof val);
    setFieldValue('rating', val);
  };

  return (
    <div className={classes.allInputFields}>
      <Typography variant="h1" className={classes.heading}>
        Feedback
      </Typography>
      <Divider />
      <Typography variant="body1" className={classes.management}>
        Overview
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        This is where you can manage feedback about the sessions.
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack
            spacing={3}
            style={{ background: '#FFFFFF', padding: '24px', borderRadius: '10px', border: '1px solid #EAECF0' }}
          >
            <Grid container style={{ marginBottom: '50px' }}>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Session Group ID
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                <Stack spacing={2} alignItems="center">
                  {' '}
                  {/* <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allSessions}
                    onChange={(e, value) => {
                      setFieldValue('session_groups', value?.id);

                      setSessionGroupID(value);
                    }}
                    onOpen={handleBlur}
                    getOptionLabel={(options) => options?.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.session_groups && errors.session_groups)}
                        fullWidth
                        helperText={touched.session_groups && errors.session_groups}
                        name="session_groups"
                        variant="outlined"
                        label="Session Group Name"
                      />
                    )}
                    fullWidth={true}
                  /> */}
                  {console.log("location?.state?.session?.session_group?.name",location?.state?.session)}
                  <Box className={classes.dataToShow}>{location?.state?.session?.session_group?.id}</Box>
                  {/* <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={SessionGroupID?.sessions}
                    onChange={(e, value) => {
                      setFieldValue('session_id', value?.id);
                    }}
                    onOpen={handleBlur}
                    getOptionLabel={(options) => options?.session_number?.toString()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.session_id && errors.session_id)}
                        fullWidth
                        helperText={touched.session_id && errors.session_id}
                        name="session_id"
                        variant="outlined"
                        label="Session ID -> Session number"
                      />
                    )}
                    fullWidth={true}
                  /> */}
                  <Box className={classes.dataToShow}>{location?.state?.session?.session_number}</Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} lg={5}></Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Client
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                {/* <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allClients}
                  onChange={(e, value) => {
                    setFieldValue('client_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  // renderOption={(option) => <>{option.name}</>}
                  getOptionLabel={(allClients) => allClients?.name}
                  // includeInputInList
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.client_id && errors.client_id)}
                      fullWidth
                      helperText={touched.client_id && errors.client_id}
                      name="client_id"
                      variant="outlined"
                      label="Client"
                    />
                  )}
                  fullWidth={true}
                /> */}
                <Box className={classes.dataToShow}>{location?.state?.session?.session_group?.client?.name}</Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={5}></Grid>
            </Grid>
            <Grid container style={{ marginTop: '50px' }}>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Coach name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                {/* <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allCoaches}
                  onChange={(e, value) => {
                    setFieldValue('coach_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.coach_id && errors.coach_id)}
                      fullWidth
                      helperText={touched.coach_id && errors.coach_id}
                      name="coach_id"
                      variant="outlined"
                      label="Coach name"
                    />
                  )}
                  fullWidth={true}
                /> */}
                <Box className={classes.dataToShow}>{location?.state?.session?.session_group?.coach?.name}</Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={5}></Grid>
            </Grid>
            <Grid container style={{ marginTop: '50px' }}>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Participant name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                {/* <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allParticipants}
                  onChange={(e, value) => {
                    setFieldValue('participant_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.participant_id && errors.participant_id)}
                      fullWidth
                      helperText={touched.participant_id && errors.participant_id}
                      name="participant_id"
                      variant="outlined"
                      label="Participant Name"
                    />
                  )}
                  fullWidth={true}
                /> */}
                <Box className={classes.dataToShow}>{location?.state?.session?.session_group?.participant?.name}</Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={5}></Grid>
            </Grid>
            <Grid container style={{ marginTop: '50px' }}>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Programme name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                {/* <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allProgrammes}
                  onChange={(e, value) => {
                    setFieldValue('programme_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.programme_id && errors.programme_id)}
                      fullWidth
                      helperText={touched.programme_id && errors.programme_id}
                      name="programme_id"
                      variant="outlined"
                      label="Programme Name"
                    />
                  )}
                  fullWidth={true}
                /> */}
                <Box className={classes.dataToShow}>{location?.state?.session?.session_group?.programme?.name}</Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={5}></Grid>
            </Grid>

            <Grid container style={{ marginTop: '50px' }}>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Feedback
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                  }}
                >
                  What did you think of the session?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={7}>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                    textAlign: 'end',
                  }}
                >
                  {values.feedback.length || 0}/1000 charaters
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="username"
                  multiline
                  rows={5}
                  type="text"
                  placeholder="I found the session great, I would like to work more on..."
                  {...getFieldProps('feedback')}
                  error={Boolean(touched.feedback && errors.feedback)}
                  helperText={touched.feedback && errors.feedback}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={2}></Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Rating
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                    marginRight: '6rem',
                  }}
                >
                  Rate the session out of 5. 1 being the lowest, 5 being the highest.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={9}>
                <RatingStar id="clickable" clickable={false} rating={rating} onRatingChange={onRatingChange} />
                <Box
                  sx={{
                    lineHeight: '1.5',
                    fontSize: '0.75rem',
                    fontFamily: 'Public Sans,sans-serif',
                    fontWeight: '400',
                    textAlign: 'left',
                    marginTop: '3px',
                    marginRight: '14px',
                    marginBottom: '0',
                    marginLeft: '14px',
                    color: '#FF4842',
                  }}
                >
                  {errors.rating}
                </Box>
              </Grid>
            </Grid>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mt: 3 }}>
            <Button
              onClick={() => {
                navigate('/dashboard/feedback', { replace: true });
                clearData();
              }}
              variant="outlined"
              sx={{ marginRight: '1rem' }}
              size="large"
            >
              Go back
            </Button>
            {/* <LoadingButton size="large" type="submit" variant="contained" loading={loadingState}>
              Save
            </LoadingButton> */}
          </Stack>
        </Form>
      </FormikProvider>
      {/* <SessionTab /> */}

      <Toaster />
    </div>
  );
}

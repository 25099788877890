import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';
// material
// import SearchBar from 'material-ui-search-bar';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

import { Card, Stack, Button, Container, Typography, Grid, Icon, Box, InputBase } from '@mui/material';
// components

import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import cloud from '../../../assets/images/cloud.svg';
import edit from '../../../assets/images/edit.svg';
import del from '../../../assets/images/delete.svg';
import cloud2 from '../../../assets/images/export.svg';
import axios from 'axios';
import { base_url } from 'src/common';
import DeleteDialogue from './dialogues/delete';
import MUIDataTable from 'mui-datatables';

// import Scrollbar from 'src/components/Scrollbar';
const useStyle = makeStyles((theme) => ({
  pagination: {
    '& li': {
      '&:first-child': {
        marginRight: 'auto !important',
        '& button': {
          width: '6rem',
        },
      },
      '&:last-child': {
        marginLeft: 'auto !important',
        '& button': {
          width: '5rem',
        },
      },
    },
  },
  deleteBtn: {
    '& span': {
      margin: '0px',
    },
  },
  editBtn: {
    '& span': {
      margin: '0px',
    },
  },
  tableManagement: {
    '& .MuiToolbar-root ': {},
  },
}));
// ----------------------------------------------------------------------

const svgIcon = (
  <Icon>
    <img alt="edit" src={cloud} />
  </Icon>
);

const deleteIcon = (
  <Icon>
    <img alt="edit" src={del} />
  </Icon>
);

const editIcon = (
  <Icon>
    <img alt="edit" src={edit} />
  </Icon>
);
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  // marginLeft: 'auto !important',
  border: '1px solid gray',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  '& input': {
    width: '600px !important',
  },
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function Sessions() {
  const navigate = useNavigate();
  const { role } = JSON.parse(sessionStorage.getItem('mentoring_user')) || '';
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [count, setCount] = useState(0);

  const classes = useStyle();

  const [page, setPage] = useState(1);

  // const [searched, setSearched] = useState('');
  const [editData, setEditData] = useState(null);

  //   const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPage = 10;

  const [tableData, setTableData] = useState({});

  const [dataInCSV, setDataInCSV] = useState('');
  const token = sessionStorage.getItem('libra_admin_token');
  useEffect(() => {
     async function fetchData() {
    await axios
      .get(`${base_url}/api/feedback/export`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setDataInCSV(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
    fetchData();
  }, [tableData?.feedback]);

  const getRowOption = (apiValue) => {
    let firstValue = 0;
    let secondValue = 0;
    let thirdValue = 0;
    if (apiValue > 29) {
      if(apiValue > 500){
         firstValue = 10;
         secondValue = 100;
         thirdValue = apiValue
      }else{
       firstValue = Math.round(apiValue / 30) * 10
       secondValue = Math.round(apiValue / 20) * 10
       thirdValue = apiValue
      }
    }else if (apiValue > 20 && apiValue <= 29 ) {
      firstValue = 10;
      secondValue = 20;
      thirdValue = apiValue
    }else if(apiValue <= 20 && apiValue > 10){
      firstValue = 10;
      secondValue = apiValue;
    } else {
      firstValue = apiValue;
    }
    return { firstValue, secondValue, thirdValue }
  }
  const getList = async (noOfRow) => {
    await axios
      .get(`${base_url}/api/feedback?page=${page}&per_page=${noOfRow ? noOfRow : 10}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const { firstValue, secondValue, thirdValue } = getRowOption(res?.data?.data?.total);
        let rowOption = [];
        if (firstValue) rowOption.push(firstValue)
        if (secondValue) rowOption.push(secondValue)
        if (thirdValue) rowOption.push(thirdValue)
        setOptions({ ...options, rowsPerPageOptions: rowOption });
        setCount(Math.ceil(res?.data?.data?.total / rowsPerPage));
        setTableData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [options,setOptions] = useState({
    filterType: 'dropdown',
    responsive: 'standard',
    search: false,
    download: true,
    print: false,
    selectableRows: false,
    pagination: true,
    rowsPerPageOptions: [10, 20, 50, 'All'],
    onChangeRowsPerPage:  getList,
    downloadOptions: {
      filterOptions: { useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true }
    },
  });
  useEffect(() => {
    getList();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    console.log(newPage, event);
    setPage(newPage);
  };

  //   console.log('tableData', tableData);

  const handleEdit = (index) => {
    const data = tableData?.feedback[index];
    setEditData(data);
    navigate('/dashboard/feedback/edit-feedback', {
      state: data,
    });
  };
  const feedbackOnclick = (data) => {
    navigate('/dashboard/feedback/feedback-details', {
      state: data,
    });
  };
  const handleDelete = (index) => {
    const data = tableData?.feedback[index];
    setEditData(data);
    setDeleteOpen(true);
  };
  let columns = [];
  if (role === 'admin') {
    columns = [
      // {
      //   name: '',
      //   label: '',
      //   options: {
      //     filter: false,
      //     customBodyRender: (value, tableMeta, update) => {
      //       let rowIndex = Number(tableMeta.rowIndex) + 1;
      //       return <span>{rowIndex}</span>;
      //     },
      //   },
      // },
      {
        name: 'session',
        label: 'Name',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const arr = tableData?.feedback[tableMeta?.rowIndex];
            return (
              <span
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => {
                  feedbackOnclick(arr);
                }}
              >
                {value ? value : '---'}
                {/* {value?.session_group?.name || '---'} */}
              </span>
            );
          },
        },
      },
      {
        name: 'session_number',
        label: 'Session number',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value?.session_number || '---'}</span>;
          },
        },
      },
      {
        name: 'feedback_comment',
        label: 'Feedback',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value?.session_group?.participant?.name || '---'}</span>;
          },
        },
      },
      {
        name: 'participant',
        label: 'Participant',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value?.session_group?.participant?.name || '---'}</span>;
          },
        },
      },
      {
        name: 'rating',
        label: 'Rating',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'date',
        label: 'Date',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: '',
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  startIcon={deleteIcon}
                  className={classes.deleteBtn}
                  onClick={() => {
                    handleDelete(dataIndex);
                  }}
                />
                <Button
                  startIcon={editIcon}
                  className={classes.editBtn}
                  onClick={() => {
                    handleEdit(dataIndex);
                  }}
                />
              </Stack>
            );
          },
        },
      },
    ];
  } else if (role === 'company' || role === 'client') {
    columns = [
      // {
      //   name: '',
      //   label: '',
      //   options: {
      //     filter: false,
      //     customBodyRender: (value, tableMeta, update) => {
      //       let rowIndex = Number(tableMeta.rowIndex) + 1;
      //       return <span>{rowIndex}</span>;
      //     },
      //   },
      // },
      {
        name: 'Name',
        label: 'Name',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const arr = tableData?.feedback[tableMeta?.rowIndex];
            console.log("value=====>>>>>",value)
            return (
              <span
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => {
                  feedbackOnclick(arr);
                }}
              >
                {/* {value} */}
                {value || '---'}
              </span>
            );
          },
        },
      },
      {
        name: 'session_number',
        label: 'Session number',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>Session {value}</span>;
            // return <span>{value?.session_number || '---'}</span>;
          },
        },
      },
      {
        name: 'feedback_comment',
        label: 'Feedback',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value?.session_group?.participant?.name || '---'}</span>;
          },
        },
      },
      {
        name: 'participant',
        label: 'Participant',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value?.session_group?.participant?.name || '---'}</span>;
          },
        },
      },
      {
        name: 'rating',
        label: 'Rating',
        options: {
          filter: false,
          sort: false,
        },
        
      },
      {
        name: 'date',
        label: 'Date',
        options: {
          filter: false,
          sort: false,
        },
      },
    ];
  } else if (role === 'coach') {
    columns = [
      // {
      //   name: '',
      //   label: '',
      //   options: {
      //     filter: false,
      //     customBodyRender: (value, tableMeta, update) => {
      //       let rowIndex = Number(tableMeta.rowIndex) + 1;
      //       return <span>{rowIndex}</span>;
      //     },
      //   },
      // },
      {
        name: 'session',
        label: 'Name',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const arr = tableData?.feedback[tableMeta?.rowIndex];
            return (
              <span
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => {
                  feedbackOnclick(arr);
                }}
              >
                {/* {value} */}
                {value?.session_group?.name || '---'}
              </span>
            );
          },
        },
      },
      {
        name: 'client',
        label: 'Client name',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value?.session_group?.client?.name || '---'}</span>;
          },
        },
      },
      {
        name: 'programme',
        label: 'Programme name',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value?.session_group?.programme?.name || '---'}</span>;
          },
        },
      },
      {
        name: 'session_number',
        label: 'Session number',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>Session {value}</span>;
            // return <span>{value?.session_number || '---'}</span>;
          },
        },
      },
      {
        name: 'participant',
        label: 'Participant',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value?.session_group?.participant?.name || '---'}</span>;
          },
        },
      },
      {
        name: 'rating',
        label: 'Rating',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'date',
        label: 'Date',
        options: {
          filter: false,
          sort: false,
        },
      },
    ];
  } else if (role === 'participant') {
    columns = [
      // {
      //   name: '',
      //   label: '',
      //   options: {
      //     filter: false,
      //     customBodyRender: (value, tableMeta, update) => {
      //       let rowIndex = Number(tableMeta.rowIndex) + 1;
      //       return <span>{rowIndex}</span>;
      //     },
      //   },
      // },
      // {
      //   name: 'session',
      //   label: 'Name',
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       const arr = tableData?.feedback[tableMeta?.rowIndex];
      //       return (
      //         <span
      //           style={{ cursor: 'pointer', textDecoration: 'underline' }}
      //           onClick={() => {
      //             feedbackOnclick(arr);
      //           }}
      //         >
      //           {value}
      //           {/* {value?.session_group?.name || '---'} */}
      //         </span>
      //       );
      //     },
      //   },
      // },
      // {
      //   name: 'session',
      //   label: 'Client name',
      //   options: {
      //     filter: true,
      //     sort: false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return <span>{value?.session_group?.client?.name || '---'}</span>;
      //     },
      //   },
      // },
      {
        name: 'programme',
        label: 'Programme name',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value?.session_group?.programme?.name || '---'}</span>;
          },
        },
      },
      {
        name: 'feedback_comment',
        label: 'Feedback',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value?.session_group?.participant?.name || '---'}</span>;
          },
        },
      },
      {
        name: 'session_number',
        label: 'Session number',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>Session {value}</span>;
            // return <span>{value?.session_number || '---'}</span>;
          },
        },
      },
      // {
      //   name: 'session',
      //   label: 'Participant',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return <span>{value?.session_group?.participant?.name || '---'}</span>;
      //     },
      //   },
      // },
      {
        name: 'coach',
        label: 'Coach name',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value?.session_group?.coach?.name || '---'}</span>;
          },
        },
      },
      {
        name: 'rating',
        label: 'Rating',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'date',
        label: 'Date',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: '',
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  startIcon={deleteIcon}
                  className={classes.deleteBtn}
                  onClick={() => {
                    handleDelete(dataIndex);
                  }}
                />
                <Button
                  startIcon={editIcon}
                  className={classes.editBtn}
                  onClick={() => {
                    handleEdit(dataIndex);
                  }}
                />
              </Stack>
            );
          },
        },
      },
    ];
  }

  // const options = {
  //   filterType: 'dropdown',
  //   // filter: false,
  //   search: false,
  //   download: true,
  //   print: false,
  //   selectableRows: false,
  //   pagination: true,
  //   rowsPerPageOptions: [10, 50, 500],
  //   onChangeRowsPerPage:  getList,
  //   downloadOptions: {
  //     filterOptions: { useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true }
  //   }
  //   // textLabels: {
  //   //   pagination: {
  //   //     next: 'Next >',
  //   //     previous: '< Previous',
  //   //     rowsPerPage: 'Total items Per Page',
  //   //     displayRows: 'OF',
  //   //   },
  //   // },
  // };
  const onchangeHandler = (value) => {
    // setSearched(value);
    setTimeout(async () => {
      await axios
        .get(`${base_url}/api/feedback?search=${value}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          console.log(res?.data?.data);
          setCount(Math.ceil(res?.data?.data?.feedback?.length / rowsPerPage));
          setTableData(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000);
  };

  let exportTableData = [];
  if (tableData?.feedback) {
    exportTableData = [...tableData?.feedback].map((data,i)=>{   
      console.log("data======================>>>>>>>>>>>>>>>>>>>",data)
      // let clientNames =  data?.client?.name;
      let programmeName = data?.session?.session_group?.programme?.name;
      let session_number = data?.session?.session_number;
      let feedback_comment = data?.feedback;
      let participant = data?.participant?.name;
      let rating = data?.rating;
      let date = data?.feedback_date;
      let client_name = data?.session?.session_group?.client?.name
      let programme = data?.session?.session_group?.programme?.name
      let coach = data?.session?.session_group?.coach?.name
      return {
        Name: programmeName, 
        session_number: session_number,
        participant: participant,
        feedback_comment: feedback_comment,
        rating: rating,
        date: date,
        client : client_name,
        programme : programme,
        coach : coach,
      }
    });
  }

  return (
    <Page title="Feedback">
      {console.log("tableData?.feedback",tableData?.feedback)}
      <Container maxWidth={false}>
        <Grid container sx={{ margin: '20px 0' }}>
          <Grid item xs={6} sm={6}>
            <Typography variant="h4" gutterBottom>
              Feedback
            </Typography>
          </Grid>
          <Grid item xs={0} sm={1} xl={3} />
          <Grid item xs={6} sm={5} xl={3}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              {/* <Button
                variant="outlined"
                sx={{ minHeight: '40px', color: '#344054', borderColor: '#D0D5DD' }}
                startIcon={svgIcon}
              >
                Import
              </Button> */}
              {/* <Button
                component="a"
                href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
                download="filename.csv"
                variant="outlined"
                sx={{
                  minHeight: '40px',
                  color: '#344054',
                  borderColor: '#D0D5DD',
                  marginRight: role === 'participant' && '15px',
                }}
                startIcon={
                  <Icon>
                    <img alt="edit" src={cloud2} />
                  </Icon>
                }
              >
                Export
              </Button> */}
              {role === 'participant' ? (
                <Button
                  variant="contained"
                  sx={{ minHeight: '40px' }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={() => {
                    navigate('/dashboard/feedback/add-feedback', { replace: true });
                  }}
                >
                  Add New
                </Button>
              ) : null}
            </div>
          </Grid>
        </Grid>

        <Card sx={{ marginBottom: '24px' }}>
          <Box className={classes.tableManagement}>
            <Stack direction="row" alignItems="start" justifyContent="center" marginTop="24px">
              {/* <Typography variant="h4" sx={{ padding: '10px' }}>
              All companies{' '}
            </Typography>
            <Typography
              sx={{
                color: '#6941C6',
                backgroundColor: '#F9F5FF',
                borderRadius: '16px',
                width: '100px',
                textAlign: 'center',
              }}
            >
              {tableData?.length} records
            </Typography> */}
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search', placeholder: 'Search table' }}
                  onChange={(e) => {
                    onchangeHandler(e.target.value);
                  }}
                />
              </Search>
            </Stack>
            {/* <Divider /> */}

            <Scrollbar>
              <MUIDataTable
                title={
                  <h6
                    style={{
                      fontFamily: 'Public Sans,sans-serif',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      fontSize: '18px',
                      lineHeight: '28px',
                      color: '#101828',
                      textAlign: 'start',
                      display: 'flex',
                    }}
                  >
                    All Feedback
                    <span
                      style={{
                        fontFamily: 'Public Sans,sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#6941C6',
                        background: '#F9F5FF',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '2px 8px',
                        width: 'fit-content',
                        marginLeft: '1rem',
                      }}
                    >
                      {tableData?.total || tableData?.feedback?.length} records
                    </span>
                  </h6>
                }
                data={exportTableData}
                columns={columns}
                options={options}
              />
            </Scrollbar>
            <Box className={classes.pagination}>
              <Pagination
                count={count}
                onChange={handleChangePage}
                page={page}
                shape="rounded"
                color="primary"
                defaultPage={1}
              />
            </Box>
          </Box>
        </Card>
      </Container>
      {/* <AddDialogue setAddOpen={setAddOpen} addOpen={addOpen} getList={getList} /> */}
      {/* <EditDialogue setEditOpen={setEditOpen} editOpen={editOpen} getList={getList} editData={editData} /> */}
      <DeleteDialogue setDeleteOpen={setDeleteOpen} deleteOpen={deleteOpen} getList={getList} editData={editData} />
    </Page>
  );
}

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { base_url } from 'src/common';
import { Autocomplete, Box, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { useDropzone } from 'react-dropzone';
import MaterialDropZone from './materialDropZone';
import {
  Dropzone,
  FileItem,
  FullScreenPreview,
  createListOfMultiTypeFile,
  makeSynthticFileValidate,
} from '@dropzone-ui/react';
import _ from 'lodash';
const useStyle = makeStyles((theme) => ({
  modalMain: {
    width: '530px',

    // height: '530px',

    // backgroundColor: theme.palette.primary.dark,

    border: '1px solid rgba(239, 239, 239, 0.1)',

    borderRadius: '10px',

    borderColor: '#fff',
    padding: '15px 15px',
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '15px 15px',
    },
    '& .heading': {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
    },
  },
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '38px',
    color: '#101828',
    paddingBottom: '32px',
  },
  management: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingTop: '32px',
    paddingBottom: '4px',
  },
  body1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
  },
  ParticipantDetails: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingBottom: '4px',
  },
  body2text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
    marginTop: '0px !important',
  },
  wrapper_class: {
    padding: '1rem',
    border: '1px solid #ccc',
    marginBottom: '1.5rem',
  },
  editor_class: {
    backgroundColor: 'lightgray',
    padding: '1rem',
    border: '1px solid #ccc',
  },
  toolbar_class: {
    border: '1px solid #ccc',
  },
  dropzone: {
    textSlign: 'center',
    padding: '20px',
    width: '512px',
    height: '126',
    backgroundColor: '#FFFFFF',
    color: '#bdbdbd',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px dashed #EAECF0',
    borderRadius: '8px',
    '& img': {
      width: '50px',
    },
  },
  documentCardContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  documentCard: {
    maxWidth: '100px',
    maxHeight: '100px',
    marginLeft: '20px',
  },
  documentImaage: {
    maxWidth: '50px',
  },
  documentName: {
    display: 'inlineBlock',
    width: '50px',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
}));

export default function AdminEditProgramme(props) {
  const navigate = useNavigate();

  const classes = useStyle();
  const location = useLocation();
  // console.log('location?.state ==>>', location?.state);
  let materialDocumentsUpdate = [];
  for (let loc of location?.state?.material_documents) {
    // console.log('loc', loc);
    materialDocumentsUpdate.push(loc);
  }
  let itineraryDocumentsUpdate = [];
  // console.log('location?.state?.itinerary_documents ===>>>', location?.state?.itinerary_documents);
  for (let loc of location?.state?.itinerary_documents) {
    // console.log(' itinerary_documents ==>>>   loc', loc);
    itineraryDocumentsUpdate.push(loc);
  }
  // console.log('update', update);
  // console.log('loc', location?.state);
  const formData = new FormData();
  const [loadingState, setloadingState] = useState(false);
  const [dateValue, setDateValue] = React.useState(null);
  const [allClients, setallClients] = useState([]);
  const [allGroups, setallGroups] = useState([]);
  const [allCoaches, setallCoaches] = useState([]);
  const [companyVal, setcompanyVal] = useState({});
  const [allCompanies, setallCompanies] = useState([]);
  const [companyID, setcompanyID] = useState(null);
  // console.log('all', allClients, allGroups, allCoaches, companyVal, allCompanies, dateValue);
  // Dropzone/ui
  const [materialFiles, setMaterialFiles] = useState([]);
  const [materialDocumentsFiles, setMaterialDocumentsFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState(undefined);

  //
  const [ItineraryeditorState, setItineraryEditorState] = useState(() => EditorState.createEmpty());
  const [materialeditorState, setmaterialEditorState] = useState(() => EditorState.createEmpty());
  const [uploadOrDownloadCount, setUploadOrDownloadCount] = useState([]);
  useEffect(() => {
    setItineraryEditorState(
      EditorState?.createWithContent(
        ContentState?.createFromBlockArray(convertFromHTML(location?.state?.itinerary || ''))
      )
    );
    setFieldValue('itinerary', location?.state?.itinerary || '');
  }, []);
  useEffect(() => {
    setmaterialEditorState(
      EditorState?.createWithContent(
        ContentState?.createFromBlockArray(convertFromHTML(location?.state?.program_material || ''))
      )
    );
    setFieldValue('program_material', location?.state?.program_material || '');
  }, []);

  useEffect(() => {
    console.log('itineraryDocumentsUpdate', itineraryDocumentsUpdate);
    setMaterialDocumentsFiles(materialDocumentsUpdate);
    // setFieldValue("material_documents", materialDocumentsUpdate);
    // setFieldValue("itinerary_documents", itineraryDocumentsUpdate);
    setFiles(itineraryDocumentsUpdate);
  }, []);
  const [groupval, setgroupVal] = useState({});
  const [clientval, setclientVal] = useState([]);
  const [coachval, setcoachVal] = useState([]);

  const token = sessionStorage.getItem('libra_admin_token');
  const getAllCompanies = async () => {
    await axios
      .get(`${base_url}/api/companies`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallCompanies(res?.data?.data?.companies);
        const companies = res?.data?.data?.companies?.find((item) => item.id === location?.state?.company_id);
        setcompanyVal(companies);
        setFieldValue('company_id', companies?.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getClients = async () => {
    await axios
      .get(`${base_url}/api/clients`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log('res', res);
        setallClients(res?.data?.data?.clients);
        setclientVal(location?.state?.clients);
        setFieldValue('client_ids', location?.state?.clients);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const getGroups = async () => {
    await axios
      .get(`${base_url}/api/groups`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallGroups(res?.data?.data?.groups);
        const groups = res?.data?.data?.groups?.find((item) => item.id === location?.state?.group_id);
        setgroupVal(groups);
        setFieldValue('group_id', groups?.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCoaches = async () => {
    await axios
      .get(`${base_url}/api/coaches`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallCoaches(res?.data?.data?.coaches);
        setcoachVal(location?.state?.coaches);
        setFieldValue('coach_ids', location?.state?.coaches);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const date = new Date(`${location.state.cohort_year}-01-01`);

  useEffect(() => {
    getAllCompanies();
    getClients();
    getGroups();
    getCoaches();
    setDateValue(date);
    setFieldValue('name', location?.state?.name);
    setFieldValue('cohort_year', location?.state?.cohort_year);
  }, []);

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    client_ids: Yup.array().required('Client name is required').min(1, 'Client field must have atleast 1 item'),
    group_id: Yup.string().required('Group name is required'),
    coach_ids: Yup.array().required('Coach name is required').min(1, 'Coach field must have atleast 1 item'),
    cohort_year: Yup.string().required('Cohort year is required'),
    company_id: Yup.string().required('Coaching Provider name is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      client_ids: [],
      group_id: '',
      coach_ids: [],
      cohort_year: '',
      itinerary: '',
      program_material: '',
      material_documents: [],
      itinerary_documents: [],
      company_id: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setloadingState(true);
      console.log('valuesupdate', values);
      const dupObj = { ...values };
      const ids = dupObj.coach_ids.map((item) => item.id);
      dupObj.coach_ids = ids.toString();
      const clientids = dupObj.client_ids.map((item) => item.id);
      dupObj.client_ids = clientids.toString();
      console.log(dupObj);
      formData.append('_method', 'put');
      formData.append('name', dupObj?.name);
      formData.append('client_ids', dupObj?.client_ids);
      formData.append('coach_ids', dupObj?.coach_ids);
      formData.append('cohort_year', dupObj?.cohort_year);
      formData.append('group_id', dupObj?.group_id);
      formData.append('itinerary', dupObj?.itinerary);
      formData.append('program_material', dupObj?.program_material);
      console.log('dupObj.itinerary_documents ===>>', dupObj.itinerary_documents);
      if (dupObj.itinerary_documents.length > 0) {
        for (let i = 0; i < dupObj.itinerary_documents.length; i++) {
          formData.append('itinerary_documents[]', dupObj?.itinerary_documents[i] && dupObj?.itinerary_documents[i]);
        }
      }
      console.log('dupObj.material_documents ===>>', dupObj.material_documents);
      if (dupObj.material_documents.length > 0) {
        for (let i = 0; i < dupObj.material_documents.length; i++) {
          formData.append('material_documents[]', dupObj?.material_documents[i].file);
        }
      }

      // if (files.length > 0) {
      //   for (let i = 0; i < files.length; i++) {
      //     formData.append('itinerary_documents[]', files[i] && files[i]);
      //   }
      // }
      // console.log("dupObj.material_documents ===>>", dupObj.material_documents)
      // if (materialDocumentsFiles.length > 0) {
      //   for (let i = 0; i < materialDocumentsFiles.length; i++) {
      //     formData.append('material_documents[]', materialDocumentsFiles[i] && materialDocumentsFiles[i]);
      //   }
      // }

      axios
        .post(`${base_url}/api/programmes/${location?.state?.id}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setloadingState(false);
          console.log(res);
          if (res?.data?.status === true) {
            toast.success('Updated successfully');
            clearData();
            setTimeout(() => {
              clearData();
              navigate('/dashboard/programmes', { replace: true });
            }, 2000);
          }
        })
        .catch((err) => {
          setloadingState(false);

          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            toast.error('Server Error');
          }
        });
    },
  });

  const {values, errors, touched, handleBlur,setValues, setFieldValue, resetForm, handleSubmit, getFieldProps } = formik;

  // console.log('getFieldProps check==>', getFieldProps);

  const clearData = () => {
    resetForm();
  };
  function onEditorStateChange(state) {
    setItineraryEditorState(state);
    ItineraryconvertContentToHTML();
  }
  const ItineraryconvertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return (
            <a href={entity.data.url} target={entity?.data?.targetOption}>
              {originalText}
            </a>
          );
        }
        return originalText;
      },
    })(ItineraryeditorState.getCurrentContent());
    setFieldValue('itinerary', currentContentAsHTML);
  };
  function onmaterialEditorStateChange(state) {
    setmaterialEditorState(state);
    materialconvertContentToHTML();
  }
  const materialconvertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return (
            <a href={entity.data.url} target={entity?.data?.targetOption}>
              {originalText}
            </a>
          );
        }
        return originalText;
      },
    })(materialeditorState.getCurrentContent());
    setFieldValue('program_material', currentContentAsHTML);
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.Custom', '.jpeg', '.png', '.jpg', '.docx', '.pdf'],
      //   'application/pdf': ['.pdf'],
      //   'application/docs': ['.docx'],
    },
    // accept: 'image/png, image/jpeg,image/jpg,application/pdf,application/docs',
  });
  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  // React dropzone/ui
  const updateFiles = (incommingFiles) => {
    console.log('incomming files', incommingFiles);
    const update = [];
    incommingFiles?.map((item) => update.push(item?.file));
    setFieldValue('itinerary_documents', update);

    setFiles(incommingFiles);
  };
  const onDelete = (id) => {
    axios
      .post(
        `${base_url}/api/programmes/deletedoc/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setFiles(files.filter((x) => x.id !== id));
      });
  };
  const onDeleteMaterialDocuments = (key,id) => {
    axios
      .post(
        `${base_url}/api/programmes/deletedoc/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setMaterialDocumentsFiles(materialDocumentsFiles.filter((x) => x.id !== id));
      });
  };
  const handleSee = (imageSource) => {
    console.log('imageSource', imageSource);
    setFieldValue(imageSource);
  };
  const handleClean = (files) => {
    console.log('list cleaned', files);
  };
  //
  useEffect(() => {
    setFieldValue('itinerary_documents', acceptedFiles);
  }, [acceptedFiles]);

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  useEffect(() => {
    const selectedCompanyData = async () => {
      await axios
        .get(`${base_url}/api/companies/${companyID}/users`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setallClients(res?.data?.data?.clients);
          setallCoaches(res?.data?.data?.coaches);

          setgroupVal({});
          setcoachVal([]);
          setclientVal([]);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    selectedCompanyData();
  }, [companyID]);

  const showTimer = () => {
    const timer = setInterval(() => {
      setUploadOrDownloadCount(
        (beforeValue) => {
          const lastIndex = formik.values.material_documents.length;

          if (!beforeValue[lastIndex] || beforeValue[lastIndex] < 100) {
            beforeValue[lastIndex] = (beforeValue[lastIndex] ? beforeValue[lastIndex] : 0) + 5;
          } else {
            beforeValue[lastIndex] = 100;
          }
          if (beforeValue[lastIndex] >= 100) {
            clearInterval(timer);
          }
          return [...beforeValue]
        });
    }, 500)
  }

  const materialDocuments =
    formik.values.material_documents?.length > 0
      ? formik.values.material_documents
      : materialDocumentsFiles?.length > 0
      ? materialDocumentsFiles?.length
      : [];

  return (
    <div>
      <Typography variant="h1" className={classes.heading}>
        {location?.state?.name}
      </Typography>
      <Divider />
      <Typography variant="body1" className={classes.management}>
        Management
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        Manage which client, coach and participant can view this programme.
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
              }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={companyVal}
                options={allCompanies}
                onChange={(e, value) => {
                  formik.resetForm();
                  setFieldValue('company_id', value?.id);
                  setcompanyVal(value);
                  setcompanyID(value?.id);
                }}
                style={{ marginBottom: '25px' }}
                onOpen={handleBlur}
                getOptionLabel={(options) => options.name || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(touched.company_id && errors.company_id)}
                    fullWidth
                    helperText={touched.company_id && errors.company_id}
                    name="company_id"
                    variant="outlined"
                    label="Coaching Provider"
                  />
                )}
                fullWidth={true}
              />
              <Stack direction="row" spacing={5}>
                <Autocomplete
                  disablePortal
                  value={clientval}
                  multiple
                  id="combo-box-demo"
                  options={allClients}
                  onChange={(e, value) => {
                    console.log('client_id', value);
                    setFieldValue('client_ids', value);
                    setclientVal(value);
                  }}
                  onOpen={handleBlur}
                  // renderOption={(option) => <>{option.name}</>}
                  getOptionLabel={(allClients) => allClients?.name || ''}
                  // includeInputInList
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.client_ids && errors.client_ids)}
                      fullWidth
                      helperText={touched.client_ids && errors.client_ids}
                      name="client_ids"
                      variant="outlined"
                      label="Client"
                    />
                  )}
                  fullWidth={true}
                />
                <Autocomplete
                  value={coachval}
                  multiple
                  disablePortal
                  id="tags-standard"
                  options={allCoaches}
                  onChange={(e, value) => {
                    setFieldValue('coach_ids', value);
                    setcoachVal(value);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options?.name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.coach_ids && errors.coach_ids)}
                      fullWidth
                      helperText={touched.coach_ids && errors.coach_ids}
                      name="coach_ids"
                      variant="outlined"
                      label="Coaches"
                    />
                  )}
                  fullWidth={true}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <Autocomplete
                  value={groupval}
                  disablePortal
                  id="combo-box-demo"
                  options={allGroups}
                  onChange={(e, value) => {
                    setFieldValue('group_id', value?.id);
                    setgroupVal(value);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options.name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.group_id && errors.group_id)}
                      fullWidth
                      helperText={touched.group_id && errors.group_id}
                      name="group_id"
                      variant="outlined"
                      label="Participant group"
                    />
                  )}
                  fullWidth={true}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3} style={{ width: '100%' }}>
                    <DatePicker
                      views={['year']}
                      label="Cohort year"
                      disablePast
                      value={dateValue}
                      onChange={(value) => {
                        setDateValue(value);
                        console.log(value);
                        setFieldValue('cohort_year', moment(value).format('YYYY'));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.cohort_year && errors.cohort_year)}
                          fullWidth
                          helperText={touched.cohort_year && errors.cohort_year}
                          name="cohort_year"
                          variant="outlined"
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Stack>
            </Stack>
            <Typography variant="body1" className={classes.ParticipantDetails}>
              Programme details
            </Typography>
            <Typography variant="subtitle1" className={classes.body2text}>
              Add details for the programme.
            </Typography>
            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
                marginTop: '0px !important',
              }}
            >
              <Stack direction="row" spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Programme name*"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Stack>
              <Grid container style={{ marginBottom: '50px', marginTop: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                      paddingBottom: '5px',
                    }}
                  >
                    Itinerary
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#667085',
                    }}
                  >
                    Add the programme Itinerary.
                  </Typography>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Editor
                    defaultEditorState={ItineraryeditorState}
                    editorState={ItineraryeditorState}
                    wrapperClassName={classes.wrapper_class}
                    editorClassName={classes.editor_class}
                    toolbarClassName={classes.toolbar_class}
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                      options: ['inline', 'blockType', 'list', 'link'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                      },
                    }}
                  />
                  If you not want to change files than dont select anything.
                  <section className="container">
                    <Box>
                      {/* <div {...getRootProps({ className: `${classes.dropzone}` })}>
                        <input {...getInputProps()} />
                        <img src="/static/icons/Featuredicon.png" />
                        <p
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#667085',
                          }}
                        >
                          <span
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: '500',
                              fontSize: '14px',
                              lineHeight: '20px',
                              color: '#6941C6',
                            }}
                          >
                            Click to upload
                          </span>
                          or drag and drop
                        </p>
                        <p
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '18px',
                            textAlign: 'center',
                            color: '#667085',
                          }}
                        >
                          PDF, .docx or JPG,JPEG and PNG(max. 5mb)
                        </p>
                      </div> */}
                    </Box>

                    {/* <aside>
                      <h4>Accepted files:</h4>
                      <ul>{acceptedFileItems}</ul>
                      <h4>Rejected files:</h4>
                      <ul>{fileRejectionItems}</ul>
                    </aside> */}
                    <Dropzone
                      style={{ minWidth: '550px' }}
                      view={'grid'}
                      onChange={updateFiles}
                      minHeight="195px"
                      onClean={handleClean}
                      value={files}
                      // maxFiles={10}
                      //header={false}
                      // footer={false}
                      maxFileSize={20971520}
                      label="(Max. 20mb) can be uploaded"
                      //label="Suleta tus archivos aquí"
                      // accept="image/*, application/pdf"
                      // uploadingMessage={"Uploading..."}
                      // url="https://my-awsome-server/upload-my-file"
                      //of course this url doens´t work, is only to make upload button visible
                      //uploadOnDrop
                      //clickable={false}
                      fakeUploading
                      //localization={"FR-fr"}
                      disableScroll
                    >
                      {files?.length > 0 &&
                        files?.map((file) => (
                          <FileItem
                            {...file}
                            imageUrl={file.path}
                            key={file.id}
                            onDelete={onDelete}
                            onSee={handleSee}
                            //localization={"ES-es"}
                            resultOnTooltip
                            preview
                            info
                            hd
                          />
                        ))}
                    </Dropzone>

                    <div className={classes.documentCardContainer}>
                      {/* {console.log("files=====>>>>>>>",files)} */}
                      {files?.length > 0 &&
                        files?.map((file) => (
                          <div className={classes.documentCard}>
                            <img
                              className={classes.documentImaage}
                              src="https://play-lh.googleusercontent.com/emmbClh_hm0WpWZqJ0X59B8Pz1mKoB9HVLkYMktxhGE6_-30SdGoa-BmYW73RJ8MGZQ"
                            />
                            <p className={classes.documentName}>
                              {file?.file?.name
                                ? file?.file?.name
                                : file?.path?.substring(file?.path?.lastIndexOf('/') + 1)}
                            </p>
                            <button type="button" onClick={() => onDelete(file.id)}>
                              DELETE
                            </button>
                          </div>
                        ))}
                    </div>
                    <FullScreenPreview
                      imgSource={imageSrc}
                      openImage={imageSrc}
                      onClose={(e) => handleSee(undefined)}
                    />
                  </section>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                      paddingBottom: '5px',
                    }}
                  >
                    Programme materials
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#667085',
                    }}
                  >
                    Add the programme materials.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Editor
                    defaultEditorState={materialeditorState}
                    editorState={materialeditorState}
                    wrapperClassName={classes.wrapper_class}
                    editorClassName={classes.editor_class}
                    toolbarClassName={classes.toolbar_class}
                    onEditorStateChange={onmaterialEditorStateChange}
                    toolbar={{
                      options: ['inline', 'blockType', 'list', 'link'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                      },
                    }}
                  />
                  If you not want to change files than dont select anything.
                  <MaterialDropZone 
                    classes={classes} 
                    setFieldValue={setFieldValue} 
                    values={formik.values.material_documents}
                    setFiles={setMaterialFiles}
                    resetTimer={() => { setUploadOrDownloadCount([...uploadOrDownloadCount, 0]); showTimer() }}
                    files={materialFiles}  
                  />
                  <div className={classes.documentCardContainer}>
                    {console.log("materialDocumentsFiles====>>>>>>>>",materialDocumentsFiles)}
                    {materialDocumentsFiles.length > 0 &&
                      materialDocumentsFiles?.map(
                        (file) => (
                          <div>
                            <img
                              className={classes.documentImaage}
                              src="https://play-lh.googleusercontent.com/emmbClh_hm0WpWZqJ0X59B8Pz1mKoB9HVLkYMktxhGE6_-30SdGoa-BmYW73RJ8MGZQ"
                            />
                            <p className={classes.documentName}>
                              {file?.file?.name
                                ? file?.file?.name
                                : file?.name
                                ? file?.name
                                : file?.path?.substring(file?.path?.lastIndexOf('/') + 1)}
                            </p>
                            <button type="button" onClick={() => onDeleteMaterialDocuments('material_documents',file.id)}>
                              {' '}
                              Delete
                            </button>
                          </div>
                        )
                      )}
                  </div>
                </Grid>
              </Grid>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mt: 3 }}>
            <Button
              onClick={() => {
                navigate('/dashboard/programmes', { replace: true });
                clearData();
              }}
              variant="outlined"
              sx={{ marginRight: '1rem' }}
              size="large"
            >
              Cancel
            </Button>
            <LoadingButton size="large" type="submit" variant="contained" loading={loadingState}>
              Save
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <Toaster />
    </div>
  );
}

import { Icon } from '@mui/material';
import { toSVG } from '../../toSVG/toSVG';
import support from '../../assets/images/support.svg';
import setting from '../../assets/images/setting.svg';

import clients from '../../assets/images/clients.svg';
import programmes from '../../assets/images/programmes.svg';
import participants from '../../assets/images/participants.svg';
import coaches from '../../assets/images/Icon.png';
import session from '../../assets/images/session.png';
import feedback from '../../assets/images/feedback.png';
import profile from '../../assets/images/profile.png';

// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const data = JSON.parse(sessionStorage.getItem('mentoring_user'));

// console.log("data32132123",data)

let navConfig = [];
if (data?.role === 'company') {
  navConfig = [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: getIcon('eva:pie-chart-2-fill'),
    },

    {
      title: 'Clients',
      path: '/dashboard/clients',
      icon: toSVG(clients),
    },
    {
      title: 'Programmes',
      path: '/dashboard/programmes',
      icon: toSVG(programmes),
    },
    {
      title: 'Participants',
      path: '/dashboard/participants',
      icon: toSVG(participants),
    },
    {
      title: 'Coaches',
      path: '/dashboard/coaches',
      icon: toSVG(coaches),
    },
    {
      title: 'Sessions',
      path: '/dashboard/sessions',
      icon: toSVG(session),
    },
    {
      title: 'Feedback',
      path: '/dashboard/feedback',
      icon: toSVG(feedback),
    },
  ];
} else if (data?.role === 'admin') {
  navConfig = [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
      title: 'companies',
      path: '/dashboard/companies',
      icon: getIcon('eva:people-fill'),
    },
    {
      title: 'Clients',
      path: '/dashboard/clients',
      icon: toSVG(clients),
    },
    {
      title: 'Programmes',
      path: '/dashboard/programmes',
      icon: toSVG(programmes),
    },
    {
      title: 'Participants',
      path: '/dashboard/participants',
      icon: toSVG(participants),
    },
    {
      title: 'Coaches',
      path: '/dashboard/coaches',
      icon: toSVG(coaches),
    },
    {
      title: 'Sessions',
      path: '/dashboard/sessions',
      icon: toSVG(session),
    },
    {
      title: 'Feedback',
      path: '/dashboard/feedback',
      icon: toSVG(feedback),
    },
    // {
    //   title: 'product',
    //   path: '/dashboard/products',
    //   icon: getIcon('eva:shopping-bag-fill'),
    // },
    // {
    //   title: 'blog',
    //   path: '/dashboard/blog',
    //   icon: getIcon('eva:file-text-fill'),
    // },
    // {
    //   title: 'login',
    //   path: '/login',
    //   icon: getIcon('eva:lock-fill'),
    // },
    // {
    //   title: 'register',
    //   path: '/register',
    //   icon: getIcon('eva:person-add-fill'),
    // },
    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: getIcon('eva:alert-triangle-fill'),
    // },
  ];
}

export const sidebarFooter =  (data?.role === 'coach') ? [
  {
    title: 'Settings',
    path: '/dashboard/profile',
    icon: toSVG(setting),
  },
  {
    title: 'Your Profile',
    path: '/dashboard/profileCoach',
    icon: toSVG(profile),
  },
] : [
  {
    title: 'Settings',
    path: '/dashboard/profile',
    icon: toSVG(setting),
  },
];
export default navConfig;

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { CSVLink } from 'react-csv';

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Divider,
  Grid,
  Icon,
  PaginationItem,
  Box,
  InputBase,
} from '@mui/material';

import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import Label from '../../../components/Label';
import Page from '../../../components/Page';
import SearchNotFound from '../../../components/SearchNotFound';
import { UserListHead, UserMoreMenu } from '../user';
import cloud from '../../../assets/images/cloud.svg';
import edit from '../../../assets/images/edit.svg';
import del from '../../../assets/images/delete.svg';
import cloud2 from '../../../assets/images/export.svg';
import { toSVG } from '../../../toSVG/toSVG';
import axios from 'axios';
import { base_url } from 'src/common';
import moment from 'moment';
import AddDialogue from './dialogues/add';
import EditDialogue from './dialogues/edit';
import DeleteDialogue from './dialogues/delete';
import MUIDataTable from 'mui-datatables';

const useStyle = makeStyles((theme) => ({
  pagination: {
    '& li': {
      '&:first-child': {
        marginRight: 'auto !important',
        '& button': {
          width: '6rem',
        },
      },
      '&:last-child': {
        marginLeft: 'auto !important',
        '& button': {
          width: '5rem',
        },
      },
    },
  },
  deleteBtn: {
    '& span': {
      margin: '0px',
    },
  },
  editBtn: {
    '& span': {
      margin: '0px',
    },
  },
  tableManagement: {
    '& .MuiToolbar-root ': {},
  },
}));
// ----------------------------------------------------------------------

const svgIcon = (
  <Icon>
    <img alt="edit" src={cloud} />
  </Icon>
);

const deleteIcon = (
  <Icon>
    <img alt="edit" src={del} />
  </Icon>
);

const editIcon = (
  <Icon>
    <img alt="edit" src={edit} />
  </Icon>
);
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  // marginLeft: 'auto !important',
  border: '1px solid gray',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  '& input': {
    width: '600px !important',
  },
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function Programmes() {
  const data = JSON.parse(sessionStorage.getItem('mentoring_user'));
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [count, setCount] = useState(0);
  const userRole = JSON.parse(sessionStorage.getItem('mentoring_user'));
  const classes = useStyle();
  const [page, setPage] = useState(1);
  const [loader, setloader] = useState(true);
  const [editData, setEditData] = useState(null);
  const rowsPerPage = 10;

  const [tableData, setTableData] = useState({});
  const [dataInCSV, setDataInCSV] = useState('');
  useEffect(() => {
     async function fetchData() {
    await axios
      .get(`${base_url}/api/programmes/export`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setDataInCSV(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
    fetchData();
  }, [tableData?.programmes]);

  const token = sessionStorage.getItem('libra_admin_token');

  const getRowOption = (apiValue) => {
    let firstValue = 0;
    let secondValue = 0;
    let thirdValue = 0;
    if (apiValue > 29) {
      if(apiValue > 500){
         firstValue = 10;
         secondValue = 100;
         thirdValue = apiValue
      }else{
       firstValue = Math.round(apiValue / 30) * 10
       secondValue = Math.round(apiValue / 20) * 10
       thirdValue = apiValue
      }
    }else if (apiValue > 20 && apiValue <= 29 ) {
      firstValue = 10;
      secondValue = 20;
      thirdValue = apiValue
    }else if(apiValue <= 20 && apiValue > 10){
      firstValue = 10;
      secondValue = apiValue;
    } else {
      firstValue = apiValue;
    }
    return { firstValue, secondValue, thirdValue }
  }

  const getList = async (noOfRow) => {
    // console.log("noOfRow=====>>>>",noOfRow)
    await axios
      .get(`${base_url}/api/programmes?page=${page}&per_page=${noOfRow ? noOfRow : 10}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {

        const { firstValue, secondValue, thirdValue } = getRowOption(res?.data?.data?.total);
        let rowOption = [];
        if (firstValue) rowOption.push(firstValue)
        if (secondValue) rowOption.push(secondValue)
        if (thirdValue) rowOption.push(thirdValue)
        setOptions({ ...options, rowsPerPageOptions: rowOption });
        setCount(Math.ceil(res?.data?.data?.total / rowsPerPage));
        setTableData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [options, setOptions] = useState({
    filterType: 'dropdown',
    responsive: 'standard',
    search: false,
    download: true,
    print: false,
    selectableRows: false,
    pagination: true,
    rowsPerPageOptions: [10, 20, 50, 'All'],
    onChangeRowsPerPage:  getList,
    downloadOptions: {
      filterOptions: { useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true }
    },
  });
  useEffect(() => {
    getList();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleEdit = (index) => {
    const data = tableData?.programmes[index];
    setEditData(data);
    navigate('/dashboard/programmes/edit-programme', {
      state: data,
    });
  };
  const handleDelete = (index) => {
    const data = tableData?.programmes[index];
    setEditData(data);
    setDeleteOpen(true);
  };

  const programmeOnClick = (data) => {
    navigate('/dashboard/programmes/programme-details', { state: data });
  };

  let columns = [];
  if (data?.role === 'admin' || data?.role === 'company') {
    columns = [
      // {
      //   name: '',
      //   label: '',
      //   options: {
      //     filter: false,
      //     customBodyRender: (value, tableMeta, update) => {
      //       let rowIndex = Number(tableMeta.rowIndex) + 1;
      //       return <span>{rowIndex}</span>;
      //     },
      //   },
      // },
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const arr = tableData?.programmes[tableMeta?.rowIndex];
            return (
              <>
                {userRole?.role === 'admin' || userRole?.role === 'company' ? (
                  <span>{value}</span>
                ) : (
                  <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => {
                      programmeOnClick(arr);
                    }}
                  >
                    {value}
                  </span>
                )}
              </>
            );
          },
        },
      },
      {
        name: 'clients',
        label: 'Clients',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
          },
        },
      },
      {
        name: 'participants',
        label: 'Participants',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
          },
        },
      },
      // {
      //   name: 'group',
      //   label: 'Participant group',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return <span>{value?.name || '---'}</span>;
      //     },
      //   },
      // },
      {
        name: 'coaches',
        label: 'Coaches',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
          },
        },
      },

      {
        name: '',
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  startIcon={deleteIcon}
                  className={classes.deleteBtn}
                  onClick={() => {
                    handleDelete(dataIndex);
                  }}
                />
                <Button
                  startIcon={editIcon}
                  className={classes.editBtn}
                  onClick={() => {
                    handleEdit(dataIndex);
                  }}
                />
              </Stack>
            );
          },
        },
      },
    ];
  } else if (data?.role === 'client') {
    columns = [
      // {
      //   name: '',
      //   label: '',
      //   options: {
      //     filter: false,
      //     customBodyRender: (value, tableMeta, update) => {
      //       let rowIndex = Number(tableMeta.rowIndex) + 1;
      //       return <span>{rowIndex}</span>;
      //     },
      //   },
      // },
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const arr = tableData?.programmes[tableMeta?.rowIndex];

            return (
              <>
                {userRole?.role !== 'admin' ? (
                  <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => {
                      programmeOnClick(arr);
                    }}
                  >
                    {value}
                  </span>
                ) : (
                  <span>{value}</span>
                )}
              </>
            );
          },
        },
      },
      {
        name: 'clients',
        label: 'Client sponsor name',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value[0]?.default_sponsor?.name || '---'}</span>;
          },
        },
      },
      {
        name: 'participants',
        label: 'Participants',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value?.length}</span>;

          },
        },
      },
      {
        name: 'group',
        label: 'Participant group',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
            // return <span>{value?.name || '---'}</span>;
          },
        },
      },
      {
        name: 'coaches',
        label: 'Coaches',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value} </span>;
            // return <span>{value?.length} </span>;
          },
        },
      },
    ];
  } else if (data?.role === 'coach') {
    columns = [
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const arr = tableData?.programmes[tableMeta?.rowIndex];
            return (
              <>
                {userRole?.role !== 'admin' ? (
                  <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => {
                      programmeOnClick(arr);
                    }}
                  >
                    {value}
                  </span>
                ) : (
                  <span>{value}</span>
                )}
              </>
            );
          },
        },
      },
      {
        name: 'clients',
        label: 'Client',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const clientNames = [];


            // value?.map(item => {
            //   if (item?.name) { clientNames.push(item?.name); }
            // });

            return <span> {value}</span>;
            // return <span> {clientNames?.length ? clientNames?.length > 1 ? clientNames.concat(', ') : clientNames[0] : '--'}</span>;

            /* return value?.map((item) => {
              return <span> {item?.name.length > 1 ? item?.name.concat(' ,') || '--': item?.name}</span>;
              // return <span> {item?.name.concat(' ,') || '--'}</span>;
            }); */
            // return <span>{value[0]?.name || '---'}</span>;
          },
        },
      },
      {
        name: 'participants',
        label: 'Participants',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{value}</span>;
          },
        },
      },
      {
        name: 'group',
        label: 'Participant group',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            // return <span>{value?.name || '---'}</span>;
            return <span>{value}</span>;
          },
        },
      },
    ];
  } else if (data?.role === 'participant') {
    columns = [
      {
        name: 'name',
        label: 'Programme Name',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const arr = tableData?.programmes[tableMeta?.rowIndex];
            return (
              <>
                {userRole?.role !== 'admin' ? (
                  <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => {
                      programmeOnClick(arr);
                    }}
                  >
                    {value}
                  </span>
                ) : (
                  <span>{value}</span>
                )}
              </>
            );
          },
        },
      },
      // {
      //   name: 'coaches',
      //   label: 'Coaches',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return <span>{value} </span>;
      //       // return <span>{value[0]?.name || '---'} </span>;
      //     },
      //   },
      // },
    ];
  }


  const onchangeHandler = (value) => {
    // setSearched(value);
    setTimeout(async () => {
      await axios
        .get(`${base_url}/api/programmes?search=${value}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setCount(Math.ceil(res?.data?.data?.programmes?.length / rowsPerPage));
          setTableData(res?.data?.data);
          console.log("res?.data?.data===========>>>>>>>>>>>>>>>>>>>>>",res?.data?.data)
        })
        .catch((err) => {
          console.log(err);
        });
    }, 0);
  };

  // const ExportDataFunc = (apiData) =>  apiData
  // });

  let exportTableData = [];
  if (tableData?.programmes) {
    exportTableData = [...tableData?.programmes].map((data, i) => {
      // let clientNames = data?.clients.map((client) => { return client?.name});
      let clientNames = data?.clients.map((client) => { return client?.name})?.join(", ");

      // clientNames = clientNames?.length > 1 ? clientNames.concat(", ") : clientNames[0];
      let participants = data?.participants?.length ? data?.participants?.length : 0;
      // let coaches = data?.coaches ? data?.coaches : 0;
      let coaches = data?.coaches?.map((coach) => {return coach.name});
      let group = data?.group?.name ? data?.group?.name : "";
      return {
        name: data.name,
        participants: participants,
        coaches: coaches,
        clients: clientNames,
        group: group,
      }
    })
  };

return (
  <Page title="Programmes">
    <Container maxWidth={false}>
      <Grid container sx={{ margin: '20px 0' }}>
        <Grid item xs={4} sm={6}>
          <Typography variant="h4" gutterBottom>
            Programmes
          </Typography>
        </Grid>
        <Grid item xs={0} sm={1} xl={3} />
        <Grid item xs={8} sm={5} xl={3}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            {/* <Button
                variant="outlined"
                sx={{ minHeight: '40px', color: '#344054', borderColor: '#D0D5DD' }}
                startIcon={svgIcon}
              >
                Import
              </Button> */}
            {/* <Button
                component="a"
                href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
                download="filename.csv"
                variant="outlined"
                sx={{
                  minHeight: '40px',
                  color: '#344054',
                  borderColor: '#D0D5DD',
                  marginRight: data.role === 'admin' || data.role === 'company' ? '15px' : null,
                }}
                startIcon={
                  <Icon>
                    <img alt="edit" src={cloud2} />
                  </Icon>
                }
              >
                Export
              </Button> */}
            {data?.role === 'admin' || data?.role === 'company' ? (
              <Button
                variant="contained"
                sx={{ minHeight: '40px' }}
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => {
                  navigate('/dashboard/programmes/add-programme', { replace: true });
                }}
              >
                Add New
              </Button>
            ) : null}
          </div>
        </Grid>
      </Grid>

      <Card sx={{ marginBottom: '24px' }}>
        <Box className={classes.tableManagement}>
          <Stack direction="row" alignItems="start" justifyContent="center" marginTop="24px">
            {/* <Typography variant="h4" sx={{ padding: '10px' }}>
              All companies{' '}
            </Typography>
            <Typography
              sx={{
                color: '#6941C6',
                backgroundColor: '#F9F5FF',
                borderRadius: '16px',
                width: '100px',
                textAlign: 'center',
              }}
            >
              {tableData?.length} records
            </Typography> */}
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search', placeholder: 'Search table' }}
                onChange={(e) => {
                  onchangeHandler(e.target.value);
                }}
              />
            </Search>
          </Stack>
          {/* <Divider /> */}

          <Scrollbar>
            <MUIDataTable
              title={
                <h6
                  style={{
                    fontFamily: 'Public Sans,sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '18px',
                    lineHeight: '28px',
                    color: '#101828',
                    textAlign: 'start',
                    display: 'flex',
                  }}
                >
                  All programmes
                  <span
                    style={{
                      fontFamily: 'Public Sans,sans-serif',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      fontSize: '12px',
                      lineHeight: '18px',
                      color: '#6941C6',
                      background: '#F9F5FF',
                      borderRadius: '16px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '2px 8px',
                      width: 'fit-content',
                      marginLeft: '1rem',
                    }}
                  >
                    {tableData?.total || tableData?.programmes?.length} records
                  </span>
                </h6>
              }
              data={exportTableData}
              columns={columns}
              options={options}

            />
          </Scrollbar>
          <Box className={classes.pagination}>
            <Pagination
              count={count}
              onChange={handleChangePage}
              page={page}
              shape="rounded"
              color="primary"
              defaultPage={1}
            />
          </Box>
        </Box>
      </Card>
    </Container>
    {/* <AddDialogue setAddOpen={setAddOpen} addOpen={addOpen} getList={getList} /> */}
    {/* <EditDialogue setEditOpen={setEditOpen} editOpen={editOpen} getList={getList} editData={editData} /> */}
    <DeleteDialogue setDeleteOpen={setDeleteOpen} deleteOpen={deleteOpen} getList={getList} editData={editData} />
  </Page>
);
}

import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { toSVG } from '../../toSVG/toSVG';
import support from '../../assets/images/support.svg';
import profile from '../../assets/images/profile.png';

import clients from '../../assets/images/clients.svg';
import programmes from '../../assets/images/programmes.svg';
import participants from '../../assets/images/participants.svg';
import coaches from '../../assets/images/Icon.png';
import session from '../../assets/images/session.png';
import feedback from '../../assets/images/feedback.png';
import qualifications from '../../assets/images/qualifications.svg';
import Iconify from '../../components/Iconify';
//
import { sidebarFooter } from './NavConfig';
import logout from '../../assets/images/logout.svg';
import { Settings } from '@material-ui/icons';
import setting from '../../assets/images/setting.svg';

import { img_base_url } from 'src/common';

// ----------------------------------------------------------------------
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  paddingRight: '5px',
  // borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const data = JSON.parse(sessionStorage.getItem('mentoring_user'));
  let navConfig = [];
  if (data?.role === 'client') {
    navConfig = [
      {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon('eva:pie-chart-2-fill'),
      },

      // {
      //   title: 'Clients',
      //   path: '/dashboard/clients',
      //   icon: toSVG(clients),
      // },
      {
        title: 'Programmes',
        path: '/dashboard/programmes',
        icon: toSVG(programmes),
      },
      {
        title: 'Participants',
        path: '/dashboard/participants',
        icon: toSVG(participants),
      },
      {
        title: 'Coaches',
        path: '/dashboard/coaches',
        icon: toSVG(coaches),
      },
      {
        title: 'Sessions',
        path: '/dashboard/sessions',
        icon: toSVG(session),
      },
      {
        title: 'Feedback',
        path: '/dashboard/feedback',
        icon: toSVG(feedback),
      },
    ];
  } else if (data?.role === 'admin') {
    navConfig = [
      {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon('eva:pie-chart-2-fill'),
      },
      {
        title: 'Coaching Provider',
        path: '/dashboard/mentoring-companies',
        icon: getIcon('eva:people-fill'),
      },
      {
        title: 'Clients',
        path: '/dashboard/clients',
        icon: toSVG(clients),
      },
      {
        title: 'Programmes',
        path: '/dashboard/programmes',
        icon: toSVG(programmes),
      },
      {
        title: 'Participants',
        path: '/dashboard/participants',
        icon: toSVG(participants),
      },
      {
        title: 'Coaches',
        path: '/dashboard/coaches',
        icon: toSVG(coaches),
      },
      {
        title: 'Sessions',
        path: '/dashboard/sessions',
        icon: toSVG(session),
      },
      {
        title: 'Feedback',
        path: '/dashboard/feedback',
        icon: toSVG(feedback),
      },
      {
        title: 'Groups',
        path: '/dashboard/groups',
        icon: toSVG(participants),
      },
      {
        title: 'Qualifications',
        path: '/dashboard/qualifications',
        icon: toSVG(qualifications),
      },
      {
        title: 'Sectors',
        path: '/dashboard/sectors',
        icon: toSVG(session),
      },
    ];
  } else if (data?.role === 'participant') {
    navConfig = [
      {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon('eva:pie-chart-2-fill'),
      },

      {
        title: 'Programmes',
        path: '/dashboard/programmes',
        icon: toSVG(programmes),
      },

      {
        title: 'Coaches',
        path: '/dashboard/coaches',
        icon: toSVG(coaches),
      },

      
      {
        title: 'Sessions',
        path: '/dashboard/sessions',
        icon: toSVG(session),
      },
      {
        title: 'Feedback',
        path: '/dashboard/feedback',
        icon: toSVG(feedback),
      },
    ];
  } else if (data?.role === 'coach') {
    navConfig = [
      {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon('eva:pie-chart-2-fill'),
      },

      {
        title: 'Programmes',
        path: '/dashboard/programmes',
        icon: toSVG(programmes),
      },
      {
        title: 'Clients',
        path: '/dashboard/profileclient',
        icon: toSVG(clients),
      },
      {
        title: 'Participants',
        path: '/dashboard/participants',
        icon: toSVG(participants),
      },
      {
        title: 'Sessions',
        path: '/dashboard/sessions',
        icon: toSVG(session),
      },
      {
        title: 'Feedback',
        path: '/dashboard/feedback',
        icon: toSVG(feedback),
      },
      // {
      //   title: 'Settings',
      //   path: '/dashboard/profile',
      //   icon: toSVG(setting),
      // },
      /* {
        title: 'Your Profile',
        path: '/dashboard/profileCoach',
        icon: toSVG(profile),
      }, */
    ];
  } else if (data?.role === 'company') {
    navConfig = [
      {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon('eva:pie-chart-2-fill'),
      },
      // {
      //   title: 'Mentoring Companies',
      //   path: '/dashboard/mentoring-companies',
      //   icon: getIcon('eva:people-fill'),
      // },
      {
        title: 'Clients',
        path: '/dashboard/clients',
        icon: toSVG(clients),
      },
      {
        title: 'Programmes',
        path: '/dashboard/programmes',
        icon: toSVG(programmes),
      },
      {
        title: 'Participants',
        path: '/dashboard/participants',
        icon: toSVG(participants),
      },
      {
        title: 'Coaches',
        path: '/dashboard/coaches',
        icon: toSVG(coaches),
      },
      {
        title: 'Sessions',
        path: '/dashboard/sessions',
        icon: toSVG(session),
      },
      {
        title: 'Feedback',
        path: '/dashboard/feedback',
        icon: toSVG(feedback),
      },
      // {
      //   title: 'Groups',
      //   path: '/dashboard/groups',
      //   icon: toSVG(participants),
      // },
    ];
  }




  let sidebarFooter = [];
  if (data?.role === 'coach') {
    sidebarFooter =  [
    {
      title: 'Settings',
      path: '/dashboard/profile',
      icon: toSVG(setting),
    },
    {
      title: 'Your Profile',
      path: '/dashboard/profileCoach',
      icon: toSVG(profile),
    },
  ]} else{ sidebarFooter = [
    {
      title: 'Settings',
      path: '/dashboard/profile',
      icon: toSVG(setting),
    },
  ]}
 
  const userData = JSON.parse(sessionStorage.getItem('mentoring_user'));
  // console.log(userData);
  const indexString = userData?.email?.indexOf('@');
  const substring = userData?.email?.slice(indexString, userData?.email?.length);
  const EmailData = userData.email.substring(0, indexString);

  const logoutBtn = () => {
    navigate('/', { replace: true });
    sessionStorage.removeItem('libra_admin_token');
    sessionStorage.removeItem('mentoring_user');
    sessionStorage.removeItem('Company_id');
  };
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#101828' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <NavSection navConfig={sidebarFooter} />
      <Box>
        <Link underline="none" to="#">
          <AccountStyle>
            <Avatar src={`${img_base_url}${userData?.profile_picture}`} alt="photoURL" />
            <Box sx={{ ml: 1 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                {userData?.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre' }}>
                {EmailData.length > 5 ? `${EmailData.substring(0, 5)}...` : EmailData}
                {substring}
                {/* {userData?.email} */}
              </Typography>
            </Box>
            <Button
              sx={{
                width: 'fit-content',
                px: 1,
                py: 0.5,
                ml: 'auto',
                minWidth: 'max-content',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& span': {
                  marginRight: '0px',
                  marginLeft: '0px',
                },
              }}
              startIcon={toSVG(logout)}
              onClick={logoutBtn}
            />
          </AccountStyle>
        </Link>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: '#101828' },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: '#101828',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

import React, { useState, useEffect } from 'react'
import Page from '../../../../components/Page'
import Scrollbar from '../../../../components/Scrollbar';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Card, Stack, Button, Container, Typography, Grid, Icon, Box, InputBase } from '@mui/material';

const useStyle = makeStyles((theme) => ({
    pagination: {
        '& li': {
            '&:first-child': {
                marginRight: 'auto !important',
                '& button': {
                    width: '6rem',
                },
            },
            '&:last-child': {
                marginLeft: 'auto !important',
                '& button': {
                    width: '5rem',
                },
            },
        },
    },
    deleteBtn: {
        '& span': {
            margin: '0px',
        },
    },
    editBtn: {
        '& span': {
            margin: '0px',
        },
    },
    tableManagement: {
        '& .MuiToolbar-root ': {},
    },
}));




const AllSessions = () => {
    const classes = useStyle();
    const location = useLocation();
    const navigate = useNavigate();
    const { role } = JSON.parse(sessionStorage.getItem('mentoring_user')) || '';
    const [options, setOptions] = useState({
        filterType: 'dropdown',
        responsive: 'stacked',
        search: false,
        download: true,
        print: false,
        selectableRows: false,
        pagination: true,
        rowsPerPageOptions: [10, 20, 50, 'All'],
        // onChangeRowsPerPage: getList,
        downloadOptions: {
            filterOptions: { useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true }
        },
    });

    useEffect(() => {
        console.log("location.state.sessions", location.state)
    }, [])

    const sessionOnclick = (arr) => {
        // console.log("data===========>>>>>>>32132131323", arr.id)
        // if(location.state.sessions.id === data.id){

        // }
        location.state.sessions = location.state.sessions.filter(v => v.id === arr.id)


        // console.log("location.state.sessions32121321321321",location.state.sessions)
        // navigate('/dashboard/sessions/all-sessions', {
        //   state: data,
        // });
        navigate('/dashboard/sessions/session-details', {
            state: location.state,
        });
    };

    let columns = [];
    if (role === 'participant') {
        
            columns = [
                {
                    name: 'session_number',
                    label: 'Session',
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const arr = location.state.sessions[tableMeta?.rowIndex];
                            const number = arr.session_number;

                            return (                           
                                    <span
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        onClick={() => {
                                            sessionOnclick(arr);
                                        }}
                                    >
                                        {number}
                                    </span>                          
                            );
                        },
                    },
                },

                {
                    name: 'session_date',
                    label: 'Session Start',
                    options: {
                        filter: true,
                        sort: false,

                        customBodyRender: (value, tableMeta, updateValue) => {
                            const arr = location.state.sessions[tableMeta?.rowIndex];
                            const session_date = arr.session_date;
                            return (
                                <span>
                                    {moment(session_date).format('DD/MM/YYYY')}
                                </span>
                            );
                            // return <span>{value?.name}</span>;
                        },
                    },
                },
                {
                    name: 'session_completed',
                    label: 'Session Complete',
                    options: {
                        filter: false,
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const arr = location.state.sessions[tableMeta?.rowIndex];
                            const session_completed = arr.session_completed;
                            return (
                                <span>
                                    {moment(session_completed).format('DD/MM/YYYY')}
                                </span>
                            );
                            // return <span>{value?.name}</span>;
                        },
                    },
                },
                {
                    name: 'session_duration',
                    label: 'Session Duration',
                    options: {
                        filter: false,
                        sort: false,
                        customBodyRender: (dataIndex, tableMeta) => {
                            const arr = location.state.sessions[tableMeta?.rowIndex];
                            const session_duration = arr.session_duration;
                            return (
                                <span>
                                    {session_duration}
                                </span>
                            );
                        },
                    },
                },
            ];
        
    } else if (role === 'client') {
        columns = [
            {
                name: 'programme',
                label: 'Programme',
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value}</span>;
                        // return <span>{value?.name}</span>;
                    },
                },
            },
            {
                name: 'participant',
                label: 'Participant',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value}</span>;
                        // return <span>{value?.name}</span>;
                    },
                },
            },
            {
                name: 'coach',
                label: 'Coach',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value}</span>;
                        // return <span>{value?.name}</span>;
                    },
                },
            },
            {
                name: 'session_progress',
                label: 'Session progress',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRenderLite: (dataIndex) => {
                        const filterArray = location.state[dataIndex];
                        return (
                            <span>
                                {filterArray?.session_progress}
                            </span>
                        );
                    },
                },
            },
        ];
    } else {
        columns = [
            {
                name: 'client',
                label: 'Client',
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value}</span>;
                    },
                },
            },
            {
                name: 'programme',
                label: 'Programme',
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value}</span>;
                    },
                },
            },
            {
                name: 'participant',
                label: 'Participant',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value}</span>;
                    },
                },
            },
            {
                name: 'coach',
                label: 'Coach',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value}</span>;
                    },
                },
            },
            {
                name: 'session_progress',
                label: 'Session progress',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRenderLite: (dataIndex) => {
                        const filterArray = location.state[dataIndex];
                        return (
                            <span>
                                {filterArray?.session_progress}
                            </span>
                        );
                    },
                },
            },

            // {
            //     name: '',
            //     label: 'Actions',
            //     options: {
            //         filter: false,
            //         sort: false,
            //         customBodyRenderLite: (dataIndex) => {
            //             return (
            //                 <Stack direction="row" alignItems="center" spacing={1}>
            //                     <Button
            //                         startIcon={deleteIcon}
            //                         className={classes.deleteBtn}
            //                         onClick={() => {
            //                             handleDelete(dataIndex);
            //                         }}
            //                     />
            //                     <Button
            //                         startIcon={editIcon}
            //                         className={classes.editBtn}
            //                         onClick={() => {
            //                             handleEdit(dataIndex);
            //                         }}
            //                     />
            //                 </Stack>
            //             );
            //         },
            //     },
            // },
        ];
    }

    return (
        <Page title="Sessions">
            <Container maxWidth={false}>
                <Grid container sx={{ margin: '20px 0' }}>
                    <Grid item xs={3} sm={6}>
                        <Typography variant="h4" gutterBottom>
                            Sessions
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sm={5} xl={3}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                        </div>
                    </Grid>
                </Grid>

                <Card sx={{ marginBottom: '24px' }}>
                    <Box className={classes.tableManagement}>
                        <Stack direction="row" alignItems="start" justifyContent="center" marginTop="24px">
                        </Stack>

                        <Scrollbar>
                            <MUIDataTable
                                title={
                                    <h6
                                        style={{
                                            fontFamily: 'Public Sans,sans-serif',
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            fontSize: '18px',
                                            lineHeight: '28px',
                                            color: '#101828',
                                            textAlign: 'start',
                                            display: 'flex',
                                        }}
                                    >
                                        All Sub Sessions
                                        <span
                                            style={{
                                                fontFamily: 'Public Sans,sans-serif',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: '#6941C6',
                                                background: '#F9F5FF',
                                                borderRadius: '16px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '2px 8px',
                                                width: 'fit-content',
                                                marginLeft: '1rem',
                                            }}
                                        >
                                            {/* {tableData?.total || tableData?.session_groups?.length} records */}
                                        </span>
                                    </h6>
                                }
                                data={location.state.sessions}
                                columns={columns}
                                options={options}
                            />
                        </Scrollbar>
                    </Box>
                </Card>

            </Container>
        </Page>
    )
}

export default AllSessions
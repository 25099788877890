import { Dropzone, FileItem, FullScreenPreview } from '@dropzone-ui/react';
import { Formik } from 'formik';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
function MaterialDropZone(props) {
  const { values, setFieldValue, files, setFiles, resetTimer } = props;
  const [imageSrc, setImageSrc] = useState(undefined);
  const updateFiles = (incommingFiles) => {

    let update = [...values, ...incommingFiles];
    update = Array.from(new Set(update));
    // incommingFiles?.map((item) => update.push(item?.file));
    setFieldValue('material_documents', update);
    setFiles(incommingFiles);
    resetTimer();
  };
  const onDelete = (id) => {
    let update = [...values];
    setFieldValue('material_documents', update.filter((x) => x.id !== id));
    setFiles(files.filter((x) => x.id !== id));
  };
  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };
  const handleClean = (files) => {
    // console.log('list cleaned', files);
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.Custom', '.jpeg', '.png', '.jpg', '.docx', '.pdf'],
      //   'application/pdf': ['.pdf'],
      //   'application/docs': ['.docx'],
    },
    // accept: 'image/png, image/jpeg,image/jpg,application/pdf,application/docs',
  });
  return (
    <>
      <Dropzone
        accept={".Custom, .jpeg, .png, .jpg, .docx, .pdf, .ppt, .doc, .pptx, .xls, .xlsx, .csv, .bmp, .gif, .svg, .webp"}
        style={{ fontSize: "0.80em" }}
        view={'grid'}
        onChange={updateFiles}
        minHeight="195px"
        onClean={handleClean}
        header={false}
        value={files}
        // maxFiles={5}
        //header={false}
        // footer={false}
        maxFileSize={20971520}
        label="(Max. 20mb) can be uploaded"
        // label="Suleta tus archivos aquí"
        // accept="image/*, application/pdf"
        // uploadingMessage={"Uploading..."}
        // url="https://my-awsome-server/upload-my-file"
        // of course this url doens´t work, is only to make upload button visible
        //uploadOnDrop
        //clickable={false}
        fakeUploading
        //localization={"FR-fr"}
        // disableScroll
      >
        {files?.length &&
          files.map((file) => (
              <FileItem
                {...file}
                key={file.id}
                onDelete={onDelete}
                onSee={handleSee}
                //localization={"ES-es"}
                resultOnTooltip
                preview
                info
                hd
              />
          ))}
      </Dropzone>
      <FullScreenPreview imgSource={imageSrc} openImage={imageSrc} onClose={(e) => handleSee(undefined)} />
    </>
  );
}
export default MaterialDropZone;

import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';

// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// component
import Iconify from '../Iconify';
import axios from 'axios';
import { base_url } from 'src/common';

// ----------------------------------------------------------------------

export default function ProfileForm() {
  const navigate = useNavigate();
  const [loadingState, setloadingState] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const token = sessionStorage.getItem('libra_admin_token');

  const LoginSchema = Yup.object().shape({
    // name: Yup.string().required('Name is required'),
    new_password: Yup.string().required('Password is required'),
    new_password_confirmation: Yup.string().required('Confirm Password is required'),
    old_password: Yup.string().required('Old Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      // name: '',
      old_password: '',
      new_password: '',
      new_password_confirmation: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setloadingState(true);
      console.log('values', values);
      // navigate('/dashboard', { replace: true });
      axios
        .put(`${base_url}/api/user/change-password`, values, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setloadingState(false);
          console.log(res);
          if (res?.data?.status === true) {
            toast.success(res?.data?.data?.message);
            setTimeout(() => {
              sessionStorage.removeItem('mentoring_user');
              sessionStorage.removeItem('libra_admin_token');
              navigate('/login', { replace: true });
            }, 2000);
          }
        })
        .catch((err) => {
          setloadingState(false);
          console.log(err?.response);
          toast.error(err?.response?.data?.error);
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const handleShowOldPassword = () => {
    setShowOldPassword((show) => !show);
  };
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {/* <TextField
              fullWidth
              autoComplete="username"
              type="text"
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            /> */}
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showOldPassword ? 'text' : 'password'}
              label="Old Password"
              {...getFieldProps('old_password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowOldPassword} edge="end">
                      <Iconify icon={showOldPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.old_password && errors.old_password)}
              helperText={touched.old_password && errors.old_password}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="New Password"
              {...getFieldProps('new_password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.new_password && errors.new_password)}
              helperText={touched.new_password && errors.new_password}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showConfirmPassword ? 'text' : 'password'}
              label="Confirm Password"
              {...getFieldProps('new_password_confirmation')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowConfirmPassword} edge="end">
                      <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.new_password_confirmation && errors.new_password_confirmation)}
              helperText={touched.new_password_confirmation && errors.new_password_confirmation}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
            {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Forgot Password?"
          /> */}
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loadingState}>
            Save
          </LoadingButton>
        </Form>
      </FormikProvider>
      <Toaster />
    </>
  );
}

import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { base_url, img_base_url } from 'src/common';
import ProgrammeImg from '../../../../assets/images/programmeImg.png';
import DownloadBtn from '../../../../assets/images/downloadBtn.png';
import { saveAs } from 'file-saver';

const useStyle = makeStyles((theme) => ({
  blueDiv: {
    background: '#1570EF',
    height: '240px',
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12rem',
    '& img': {
      borderRadius: '50%',
    },
  },
  name: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '30px',
    lineHeight: '38px',
    color: '#101828',
    paddingBottom: '4px',
    paddingTop: '2rem',
  },
  email: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#667085',
  },
  about: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#101828',
    marginTop: '400px',
    paddingBottom: '8px',
  },
  about2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#101828',
    marginTop: '64px',
    paddingBottom: '8px',
  },
  aboutSubTxt: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#667085',
  },
  btnDownload: {
    background: '#1570EF',
    height: '40px',
    border: '1px solid #1570EF',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    marginRight: '24px',
  },
}));

function ProgrammeDetail() {
  const location = useLocation();
  const classes = useStyle();

  const ItineraryClick = (data) => {
    saveAs(`${img_base_url}/${data?.path}`, 'example.pdf');
  };
  const materialClick = (data) => {
    saveAs(`${img_base_url}/${data?.path}`, 'example.pdf');
  };
  return (
    <Box>
      <Box className={classes.blueDiv}>
        <Box className={classes.imageContainer}>
          <img src={ProgrammeImg} alt="profile" />
          <Box sx={{ marginLeft: '24px' }}>
            <Typography variant="h1" className={classes.name}>
              {location?.state?.name}
            </Typography>
            {/* <Typography variant="body1" className={classes.email}>
              For more information please email us: name@company.com
            </Typography> */}
          </Box>
        </Box>
      </Box>
      <Box sx={{ paddingLeft: '3rem' }}>
        <Typography variant="h4" className={classes.about}>
          Itinerary
        </Typography>
        <div className={classes.aboutSubTxt} dangerouslySetInnerHTML={{ __html: location?.state?.itinerary }} />
        <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '64px', flexWrap: 'wrap' }}>
          {location?.state?.itinerary_documents?.map((data, index) => {
            let tempFileName = data.path.split("/")
            let fileName = tempFileName[tempFileName.length - 1]
            console.log(tempFileName)
            return (
              <>
                <Button
                  variant="contained"
                  startIcon={<img src={DownloadBtn} />}
                  onClick={() => ItineraryClick(data)}
                  className={classes.btnDownload}
                  style={{ marginTop: '5px' }}
                >
                  {/* File name {index + 1} */}
                  {fileName}
                </Button>
              </>
            );
          })}
        </Box>
      </Box>
      <Box sx={{ paddingLeft: '3rem' }}>
        <Typography variant="h4" className={classes.about2}>
          Programme materials
        </Typography>
        <div className={classes.aboutSubTxt} dangerouslySetInnerHTML={{ __html: location?.state?.program_material }} />
        <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '64px', flexWrap: 'wrap' }}>
          {location?.state?.material_documents?.map((data, index) => {
            let temp = data.path.split("/")
            let fname = temp[temp.length - 1]
            return (
              <>
                {console.log("fname", fname)}
                <Button
                  variant="contained"
                  startIcon={<img src={DownloadBtn} />}
                  onClick={() => materialClick(data)}
                  className={classes.btnDownload}
                  style={{ marginTop: '5px' }}
                >
                  {/* File name {index + 1} */}
                  {fname}
                </Button>
              </>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default ProgrammeDetail;

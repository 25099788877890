// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box } from '@mui/material';
// components
import Page from '../components/Page';
import ProfileForm from 'src/components/profile';

// sections

// ----------------------------------------------------------------------

export default function Profile() {
  return (
    <Page title="Profile">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Profile Settings
        </Typography>

        <Box>
          <ProfileForm />
        </Box>
      </Container>
    </Page>
  );
}

import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';

// material
import { Link, Stack, Checkbox, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// component
import axios from 'axios';
import { base_url } from 'src/common';

// ----------------------------------------------------------------------

export default function ForgetPassword() {
  const navigate = useNavigate();
  const [loadingState, setloadingState] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setloadingState(true);
      console.log('values', values);
      // navigate('/dashboard', { replace: true });
      axios
        .post(`${base_url}/api/forgot-password`, values)
        .then((res) => {
          console.log(res);
          if (res?.data?.status === true) {
            toast.success(res?.data?.data?.message);
            setloadingState(false);
            navigate('login', { replace: true });
          }
        })
        .catch((err) => {
          setloadingState(false);
          console.log(err?.response?.data?.error);
          toast.error(err?.response?.data?.error || 'Network Error!');
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loadingState}>
              Reset
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <Toaster />
    </>
  );
}

import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//

import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Profile from './pages/Profile';
import DashboardApp from './pages/DashboardApp';
import Clients from './sections/@dashboard/clients/Clients';
import Sponsors from './sections/@dashboard/sponsors/Sponsors';
import Companies from './sections/@dashboard/companies/companies';
import Participants from './sections/@dashboard/participants/Participants';
import Coaches from './sections/@dashboard/coaches/Coaches';
// import profileCoach from './sections/@dashboard/profileCoach/CoachesProfile';
import Session from './sections/@dashboard/sessions/Session';
import Feedback from './sections/@dashboard/feedback/Feedback';
import ForgetPasswordMain from './sections/auth/ForgetPasswordMain';
import Groups from './sections/@dashboard/groups/Groups';
import AddDialogue from './sections/@dashboard/participants/dialogues/add';
import EditDialogue from './sections/@dashboard/participants/dialogues/edit';
import AddCoach from './sections/@dashboard/coaches/dialogues/addCoach';
import Programmes from './sections/@dashboard/programmes/Programmes';
import AddProgramme from './sections/@dashboard/programmes/dialogues/add';
import EditProgramme from './sections/@dashboard/programmes/dialogues/edit';
import EditCoach from './sections/@dashboard/coaches/dialogues/edit';
import CoachDetail from './sections/@dashboard/coaches/dialogues/coachDetail';
import ProgrammeDetail from './sections/@dashboard/programmes/dialogues/programmeDetails';
import AddSession from './sections/@dashboard/sessions/dialogues/add';
import EditSession from './sections/@dashboard/sessions/dialogues/edit';
import AddFeedback from './sections/@dashboard/feedback/dialogues/add';
import EditFeedback from './sections/@dashboard/feedback/dialogues/edit';
import SessionDetails from './sections/@dashboard/sessions/dialogues/detailsPage';
import FeedbackDetails from './sections/@dashboard/feedback/dialogues/feedBackDetails';
import AdminAddProgramme from './sections/@dashboard/programmes/dialogues/adminAddProgramme';
import AdminAddParticipant from './sections/@dashboard/participants/dialogues/adminAddParticipant';
import AdminAddCoach from './sections/@dashboard/coaches/dialogues/adminAddCoach';
import AdminAddSession from './sections/@dashboard/sessions/dialogues/adminAddSession';
import AdminEditSession from './sections/@dashboard/sessions/dialogues/adminEditSession';
import AdminEditParticipant from './sections/@dashboard/participants/dialogues/adminEditParticipant';
import AdminEditCoach from './sections/@dashboard/coaches/dialogues/adminEditCoach';
import AdminEditProgramme from './sections/@dashboard/programmes/dialogues/adminEditProgramme';
import CoachesProfile from './sections/@dashboard/profileCoach/CoachesProfile';
import ProfileClient from './sections/@dashboard/clients/ProfileClient';
import AllSessions from './sections/@dashboard/sessions/dialogues/allSessions';
import Qualifications from './sections/@dashboard/qualifications/Qualifications';
import Sectors from './sections/@dashboard/sectors/Sectors';

// ----------------------------------------------------------------------

export default function Router() {
  const token = sessionStorage.getItem('libra_admin_token');
  const { role } = JSON.parse(sessionStorage.getItem('mentoring_user')) || '';

  return useRoutes([
    {
      path: '/dashboard',
      element: token != null ? <DashboardLayout /> : <Navigate to="/login" />,

      children: [
        { path: 'app', element: <DashboardApp /> },
        {
          path: 'clients',
          element: role === 'company' || role === 'admin' ? <Clients /> : <Navigate to="/dashboard/app" />,
        },
        {
          path: 'profileclient',
          element: role === 'coach' ? <ProfileClient /> : <Navigate to="/dashboard/app" />,
        },
        {
          path: 'mentoring-companies',
          element: role && role === 'admin' ? <Companies /> : <Navigate to="/dashboard/app" />,
        },
        {
          path: 'clients/sponsors',
          element: role === 'company' || role === 'admin' ? <Sponsors /> : <Navigate to="/dashboard/app" />,
        },
        { path: 'programmes', element: <Programmes /> },
        {
          path: 'programmes/add-programme',
          element:
            role === 'company' || role === 'admin' ? (
              role === 'admin' ? (
                <AdminAddProgramme />
              ) : (
                <AddProgramme />
              )
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'programmes/edit-programme',
          element:
            role === 'company' || role === 'admin' ? (
              role === 'admin' ? (
                <AdminEditProgramme />
              ) : (
                <EditProgramme />
              )
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'programmes/programme-details',
          element: role && role !== 'admin' ? <ProgrammeDetail /> : <Navigate to="/dashboard/app" />,
        },
        {
          path: 'participants',
          element: role && role !== 'participant' ? <Participants /> : <Navigate to="/dashboard/app" />,
        },
        {
          path: 'participants/add-participant',
          element:
            role && role !== 'participant' ? (
              role === 'admin' ? (
                <AdminAddParticipant />
              ) : (
                <AddDialogue />
              )
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'participants/edit-participant',
          element:
            role && role !== 'participant' ? (
              role === 'admin' ? (
                <AdminEditParticipant />
              ) : (
                <EditDialogue />
              )
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'profileCoach',
          element: role === 'coach' ? <CoachesProfile /> : <Navigate to="/dashboard/app" />,
        },
        {
          path: 'coaches',
          element:
            role === 'company' || role === 'admin' || role === 'client'  || role === 'participant' ? (
              <Coaches />
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'coaches/add-coach',
          element:
            role === 'company' || role === 'admin' ? (
              role === 'admin' ? (
                <AdminAddCoach />
              ) : (
                <AddCoach />
              )
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'coaches/edit-coach',
          element:
            role === 'company' || role === 'admin' || role === 'client' ? (
              role === 'admin' ? (
                <AdminEditCoach />
              ) : (
                <EditCoach />
              )
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'coaches/coach-detail',
          element: role && (role === 'client'|| role === 'admin' || role === 'participant') ? <CoachDetail /> : <Navigate to="/dashboard/app" />,
        },
        { path: 'sessions', element: <Session /> },
        { path: 'sessions/all-sessions', element:<AllSessions/> }, 
        {
          path: 'sessions/add-session',
          element:
            role && role !== 'participant' ? (
              role === 'admin' ? (
                <AdminAddSession />
              ) : (
                <AddSession />
              )
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'sessions/edit-session',
          element:
            role && role !== 'participant' ? (
              role === 'admin' ? (
                <AdminEditSession />
              ) : (
                <EditSession />
              )
            ) : (
              <Navigate to="/dashboard/app" />
            ),
        },
        {
          path: 'sessions/session-details',
          element: <SessionDetails />,
        },
        { path: 'groups', element: role && role === 'admin' ? <Groups /> : <Navigate to="/dashboard/app" /> },
        {
          path: 'feedback',
          element: <Feedback />,
        },
        {
          path: 'feedback/feedback-details',
          element: <FeedbackDetails />,
        },
        {
          path: 'feedback/add-feedback',
          element: role && role === 'participant' ? <AddFeedback /> : <Navigate to="/dashboard/app" />,
        },
        {
          path: 'feedback/edit-feedback',
          element: role === 'admin' || role === 'participant' ? <EditFeedback /> : <Navigate to="/dashboard/app" />,
        },
        { path: 'profile', element: <Profile /> },
        { path: 'support', element: <DashboardApp /> },
        { path: 'qualifications', element: <Qualifications /> },
        { path: 'sectors', element: <Sectors /> },
        { path: '/dashboard', element: <Navigate to="/dashboard/app" /> },
      ],
    },
    {
      path: '/',

      element: token == null ? <LogoOnlyLayout /> : <Navigate to="/dashboard/app" />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: 'forget_password', element: <ForgetPasswordMain /> },
        { path: '*', element: <Navigate to="/login" /> },
        { path: '/', element: <Navigate to="/login" /> },
      ],
    },
  ]);
}

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { base_url } from 'src/common';
import { Autocomplete, Box, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RatingStar } from 'rating-star';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const useStyle = makeStyles((theme) => ({
  modalMain: {
    width: '530px',

    // height: '530px',

    // backgroundColor: theme.palette.primary.dark,

    border: '1px solid rgba(239, 239, 239, 0.1)',

    borderRadius: '10px',

    borderColor: '#fff',
    padding: '15px 15px',
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '15px 15px',
    },
    '& .heading': {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
    },
  },
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '38px',
    color: '#101828',
    paddingBottom: '32px',
  },
  management: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingTop: '32px',
    paddingBottom: '4px',
  },
  body1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
  },
  ParticipantDetails: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingBottom: '4px',
  },
  body2text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
    marginTop: '0px !important',
  },
  btncontainer: {
    '& .Mui-selected': {
      color: '#6941C6',
    },
    '& button': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#344054',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '12px',
      paddingRight: '12px',
      marginRight: '1rem',
      border: '0px !important',
      borderRadius: '5px !important',
      '&:focus': {
        color: '#6941C6',
      },
      '& svg': {
        width: '20px !important',
        height: '20px !important',
        marginLeft: '12px',
      },
    },
  },
  addButton: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '144px',
    height: '40px',
    background: '#1570EF',
    border: '1px solid #1570EF',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    '&:hover': {
      background: '#1570EF',
    },
  },
  dataToShow: {
    height: '56px',
    width: '100%',
    borderRadius: '8px',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    padding: '15px',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    background: '#F9FAFB',
  },
  allInputFields: {
    // '& input': {
    //   boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    //   borderRadius: '8px',
    //   background: '#F9FAFB',
    // },
    '& .MuiOutlinedInput-root': {
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      background: '#F9FAFB',
    },
    '& .MuiTextField-root': {
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      background: '#F9FAFB',
    },
  },
}));

export default function AddFeedback(props) {
  const navigate = useNavigate();
  const classes = useStyle();
  const formData = new FormData();

  const [loadingState, setloadingState] = useState(false);

  const [allClients, setallClients] = useState([]);
  const [allParticipants, setallParticipants] = useState([]);
  const [allCoaches, setallCoaches] = useState([]);
  const [allProgrammes, setallProgrammes] = useState([]);
  const [allSessions, setallSessions] = useState([]);
  const [rating, setRating] = useState(0);
  const [SessionGroupID, setSessionGroupID] = useState([]);

  const token = sessionStorage.getItem('libra_admin_token');
  const getClients = async () => {
    await axios
      .get(`${base_url}/api/clients`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallClients(res?.data?.data?.clients);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getParticipant = async () => {
    await axios
      .get(`${base_url}/api/participants`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallParticipants(res?.data?.data?.participants);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCoaches = async () => {
    await axios
      .get(`${base_url}/api/coaches`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallCoaches(res?.data?.data?.coaches);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getProgrammes = async () => {
    await axios
      .get(`${base_url}/api/programmes`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        // console.log(res?.data?.data?.programmes);
        setallProgrammes(res?.data?.data?.programmes);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSessions = async () => {
    await axios
      .get(`${base_url}/api/session-groups`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res?.data?.data?.session_groups);
        setallSessions(res?.data?.data?.session_groups);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getClients();
    getParticipant();
    getCoaches();
    getProgrammes();
    getSessions();
  }, []);

  const LoginSchema = Yup.object().shape({
    // client_id: Yup.string().required('Client name is required'),
    // participant_id: Yup.string().required('Participant name is required'),
    // coach_id: Yup.string().required('Coach name is required'),
    // programme_id: Yup.string().required('Programme name is required'),
    session_id: Yup.string().required('Session ID is required'),
    session_group_id: Yup.string().required('Session group ID is required'),
    feedback: Yup.string().required('Feedback is required').max(1000, 'maximum 1000 characters'),
    rating: Yup.string().required('Rating is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // client_id: '',
      // participant_id: '',
      // coach_id: '',
      // programme_id: '',
      session_groups: '',
      session_group_id: '',
      feedback: '',
      rating: '',
      session_id: '',
      feedback_date:'',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setloadingState(true);

      console.log(values);

      axios
        .post(`${base_url}/api/feedback`, values, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setloadingState(false);
          if (res?.data?.status === true) {
            toast.success('Feedback added successfully');
            clearData();
            setTimeout(() => {
              clearData();
              navigate('/dashboard/feedback', { replace: true });
            }, 2000);
            // getList();
          }
        })
        .catch((err) => {
          setloadingState(false);

          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            toast.error('Server Error');
          }
        });
    },
  });

  const { errors, touched, handleBlur, setFieldValue, resetForm, handleSubmit, getFieldProps, values } = formik;

  const clearData = () => {
    resetForm();
  };
  const onRatingChange = (val) => {
    setRating(val);
    console.log(typeof val);
    setFieldValue('rating', val);
  };

  return (
    <div className={classes.allInputFields}>
      <Typography variant="h1" className={classes.heading}>
        Feedback
      </Typography>
      <Divider />
      <Typography variant="body1" className={classes.management}>
        Overview
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        This is where you can manage feedback about the sessions.
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack
            spacing={3}
            style={{ background: '#FFFFFF', padding: '24px', borderRadius: '10px', border: '1px solid #EAECF0' }}
          >
            <Grid container style={{ marginBottom: '50px' }}>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Session Group ID
                </Typography>
              </Grid>
              {console.log('allSessions', allSessions)}
              <Grid item xs={12} sm={12} lg={4}>
                <Stack spacing={2} alignItems="center">
                  {' '}
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allSessions}
                    onChange={(e, value) => {
                      setFieldValue('session_group_id', value?.id);

                      setSessionGroupID(value);
                    }}
                    onOpen={handleBlur}
                    getOptionLabel={(options) =>  options?.id}
                    // getOptionLabel={(options) => options?.sessions?.map((item) =>{return console.log("console31321",item), item?.session_group_id || []})}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.session_group_id && errors.session_group_id)}
                        fullWidth
                        helperText={touched.session_group_id && errors.session_group_id}
                        name="session_group_id"
                        variant="outlined"
                        label="Session Group ID"
                      />
                    )}
                    fullWidth={true}
                  />
                  
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={SessionGroupID?.sessions || []}
                    onChange={(e, value) => {
                      setFieldValue('session_id', value?.id);
                    }}
                    onOpen={handleBlur}
                    getOptionLabel={(options) => options?.session_number?.toString() || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.session_id && errors.session_id)}
                        fullWidth
                        helperText={touched.session_id && errors.session_id}
                        name="session_id"
                        variant="outlined"
                        label="Session number"
                      />
                    )}
                    fullWidth={true}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} lg={5}></Grid>
            </Grid>

            {/* Client */}
            <Grid container>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Client
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                {/* <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allClients}
                  onChange={(e, value) => {
                    setFieldValue('client_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  // renderOption={(option) => <>{option.name}</>}
                  getOptionLabel={(allClients) => allClients?.name}
                  // includeInputInList
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.client_id && errors.client_id)}
                      fullWidth
                      helperText={touched.client_id && errors.client_id}
                      name="client_id"
                      variant="outlined"
                      label="Client"
                    />
                  )}
                  fullWidth={true}
                /> */}
                <Box className={classes.dataToShow}>{SessionGroupID?.client?.name}</Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={5}></Grid>
            </Grid>

            {/* Coach name */}
            <Grid container style={{ marginTop: '50px' }}>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Coach name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                {/* <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allCoaches}
                  onChange={(e, value) => {
                    setFieldValue('coach_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.coach_id && errors.coach_id)}
                      fullWidth
                      helperText={touched.coach_id && errors.coach_id}
                      name="coach_id"
                      variant="outlined"
                      label="Coach name"
                    />
                  )}
                  fullWidth={true}
                /> */}
                <Box className={classes.dataToShow}>{SessionGroupID?.coach?.name}</Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={5}></Grid>
            </Grid>
            <Grid container style={{ marginTop: '50px' }}>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Participant name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                {/* <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allParticipants}
                  onChange={(e, value) => {
                    setFieldValue('participant_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.participant_id && errors.participant_id)}
                      fullWidth
                      helperText={touched.participant_id && errors.participant_id}
                      name="participant_id"
                      variant="outlined"
                      label="Participant Name"
                    />
                  )}
                  fullWidth={true}
                /> */}
                <Box className={classes.dataToShow}>{SessionGroupID?.participant?.name}</Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={5}></Grid>
            </Grid>

            {/* Programme name */}
            <Grid container style={{ marginTop: '50px' }}>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Programme name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={4}>
                {/* <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allProgrammes}
                  onChange={(e, value) => {
                    setFieldValue('programme_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.programme_id && errors.programme_id)}
                      fullWidth
                      helperText={touched.programme_id && errors.programme_id}
                      name="programme_id"
                      variant="outlined"
                      label="Programme Name"
                    />
                  )}
                  fullWidth={true}
                /> */}
                <Box className={classes.dataToShow}>{SessionGroupID?.programme?.name}</Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={5}></Grid>
            </Grid>

            <Grid container style={{ marginTop: '50px' }}>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Feedback
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                  }}
                >
                  What did you think of the session?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={7}>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                    textAlign: 'end',
                  }}
                >
                  {values.feedback.length || 0}/1000 charaters
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="username"
                  multiline
                  rows={5}
                  type="text"
                  placeholder="I found the session great, I would like to work more on..."
                  {...getFieldProps('feedback')}
                  error={Boolean(touched.feedback && errors.feedback)}
                  helperText={touched.feedback && errors.feedback}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={2}></Grid>
            </Grid>
{/* 
            <Grid container style={{ marginTop: '50px' }}>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Feedback Date
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={7}>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                    textAlign: 'end',
                  }}
                >
                  {values.feedback.length || 0}/1000 charaters
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="username"
                  multiline
                  rows={5}
                  type="text"
                  placeholder="I found the session great, I would like to work more on..."
                  {...getFieldProps('feedback')}
                  error={Boolean(touched.feedback && errors.feedback)}
                  helperText={touched.feedback && errors.feedback}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={2}></Grid>
            </Grid> */}


            <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} sm={12} lg={3}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#344054',
                    }}
                  >
                    Feedback date
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} lg={7}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} style={{ width: '320px' }}>
                    <Stack spacing={3} style={{ width: '320px' }}>
                      <DatePicker
                        // label="Cohort year"
                        // disablePast
                        inputFormat="dd/MM/yyyy"
                        mask="__/__/____"
                        ampm={false}
                        // id={`detail[${alignment}].date_confirmed`}
                        // name={`detail[${alignment}].date_confirmed`}
                        value={formik.values?.feedback_date}
                        // disablePast
                      onChange={(value) => {
                        // console.log(moment(value).format('YYYY-MM-DD'));
                        formik.setFieldValue(
                          `feedback_date`,
                          moment(value).format('YYYY-MM-DD')
                        );
                      }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              formik.touched.feedback_date &&
                              Boolean(formik.errors.feedback_date)
                            }
                            helperText={
                              formik.touched.feedback_date &&
                              formik.errors.feedback_date
                            }
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              </Grid>

            <Grid container>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#344054',
                  }}
                >
                  Rating
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#667085',
                    marginRight: '6rem',
                  }}
                >
                  Rate the session out of 5. 1 being the lowest, 5 being the highest.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={9}>
                <RatingStar id="clickable" clickable rating={rating} onRatingChange={onRatingChange} />
                <Box
                  sx={{
                    lineHeight: '1.5',
                    fontSize: '0.75rem',
                    fontFamily: 'Public Sans,sans-serif',
                    fontWeight: '400',
                    textAlign: 'left',
                    marginTop: '3px',
                    marginRight: '14px',
                    marginBottom: '0',
                    marginLeft: '14px',
                    color: '#FF4842',
                  }}
                >
                  {errors.rating}
                </Box>
              </Grid>
            </Grid>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mt: 3 }}>
            <Button
              onClick={() => {
                navigate('/dashboard/feedback', { replace: true });
                clearData();
              }}
              variant="outlined"
              sx={{ marginRight: '1rem' }}
              size="large"
            >
              Cancel
            </Button>
            <LoadingButton size="large" type="submit" variant="contained" loading={loadingState}>
              Save
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      {/* <SessionTab /> */}

      <Toaster />
    </div>
  );
}

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';
// material
// import SearchBar from 'material-ui-search-bar';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

import { Card, Stack, Button, Container, Typography, Grid, Icon, Box, InputBase } from '@mui/material';
// components

import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import cloud from '../../../assets/images/cloud.svg';
import edit from '../../../assets/images/edit.svg';
import del from '../../../assets/images/delete.svg';
import cloud2 from '../../../assets/images/export.svg';
import axios from 'axios';
import { base_url } from 'src/common';
import DeleteDialogue from './dialogues/delete';
import MUIDataTable from 'mui-datatables';
import { addAPIProvider } from '@iconify/react';
// import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { isAfter } from 'date-fns/esm';

// import Scrollbar from 'src/components/Scrollbar';
const useStyle = makeStyles((theme) => ({
  pagination: {
    '& li': {
      '&:first-child': {
        marginRight: 'auto !important',
        '& button': {
          width: '6rem',
        },
      },
      '&:last-child': {
        marginLeft: 'auto !important',
        '& button': {
          width: '5rem',
        },
      },
    },
  },
  deleteBtn: {
    '& span': {
      margin: '0px',
    },
  },
  editBtn: {
    '& span': {
      margin: '0px',
    },
  },
  tableManagement: {
    '& .MuiToolbar-root ': {},
  },
}));
// ----------------------------------------------------------------------

const svgIcon = (
  <Icon>
    <img alt="edit" src={cloud} />
  </Icon>
);

const deleteIcon = (
  <Icon>
    <img alt="edit" src={del} />
  </Icon>
);

const editIcon = (
  <Icon>
    <img alt="edit" src={edit} />
  </Icon>
);
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  // marginLeft: 'auto !important',
  border: '1px solid gray',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  '& input': {
    width: '600px !important',
  },
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function Sessions() {
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [count, setCount] = useState(0);
  const { role } = JSON.parse(sessionStorage.getItem('mentoring_user')) || '';
  const classes = useStyle();

  const [page, setPage] = useState(1);

  // const [searched, setSearched] = useState('');
  const [editData, setEditData] = useState(null);

  //   const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPage = 10;

  const [tableData, setTableData] = useState({});
  const [dataInCSV, setDataInCSV] = useState('');
  const [sessionGroup, setSessionGroup] = useState();
  const [sessionDetail, setSessionDetail] = useState();

  useEffect(() => {
     async function fetchData() {
    await axios
      .get(`${base_url}/api/session-groups/export`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setDataInCSV(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
    fetchData();
  }, [tableData?.session_groups]);

  const token = sessionStorage.getItem('libra_admin_token');
  const getRowOption = (apiValue) => {
    let firstValue = 0;
    let secondValue = 0;
    let thirdValue = 0;
    if (apiValue > 29) {
      if (apiValue > 500) {
        firstValue = 10;
        secondValue = 100;
        thirdValue = apiValue
      } else {
        firstValue = Math.round(apiValue / 30) * 10
        secondValue = Math.round(apiValue / 20) * 10
        thirdValue = apiValue
      }
    } else if (apiValue > 20 && apiValue <= 29) {
      firstValue = 10;
      secondValue = 20;
      thirdValue = apiValue
    } else if (apiValue <= 20 && apiValue > 10) {
      firstValue = 10;
      secondValue = apiValue;
    } else {
      firstValue = apiValue;
    }
    return { firstValue, secondValue, thirdValue }
  }


  const getList = async (noOfRow) => {

    await axios
      .get(`${base_url}/api/session-groups`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const { firstValue, secondValue, thirdValue } = getRowOption(res?.data?.data?.total);
        let rowOption = [];
        if (firstValue) rowOption.push(firstValue)
        if (secondValue) rowOption.push(secondValue)
        if (thirdValue) rowOption.push(thirdValue)
        // setOptions({ ...options, rowsPerPageOptions: rowOption });
        setCount(Math.ceil(res?.data?.data?.total / rowsPerPage));
        console.log("res?.data?.data====>>>>>", res?.data?.data?.session_groups)
        let tempValue = res?.data?.data?.session_groups.map((value) => { return ({ client: value.client, coach: value.coach, participant: value.participant, programme: value.programme, sessions: value.sessions, sessionLength: value.participant_details?.no_of_sessions }) })
        // secondValue.push({})
        // let tempSession = tempValue?.sessions?.map(v => {return v} );
        // let sessionValue = [...tempValue,...tempSession]
        // console.log("sessionValue====>>>>>>>>",sessionValue)
        // console.log("res?.data?.data",res?.data?.data)
        setSessionDetail(res?.data?.data?.session_groups)
        console.log("res?.data?.data?.session_groups321", res?.data?.data?.session_groups?.sessions);
        let sessionValue = tempValue.flatMap(item =>
          item.sessions.map(session => {
            const { sessions, ...rest } = { ...session, ...item };
            return rest;
          })
        );



        // var newArray = [];
        // res?.data?.data?.session_groups?.map(value => {
        //   newArray = [...newArray, ...value.sessions];
        // })

        console.log(sessionValue);
        setSessionGroup(sessionValue)
        setTableData(sessionValue);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    search: false,
    download: true,
    print: false,
    selectableRows: false,
    exportButton: true,
    pagination: true,
    rowsPerPageOptions: [10, 20, 50],
    onChangeRowsPerPage: getList,
    downloadOptions: {
      filterOptions: { useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true }
    },
  }


  useEffect(() => {
    getList();
  }, [page]);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //   console.log('tableData', tableData);

  const handleEdit = (data) => {
    // const data = tableData[index];
    let sendingData = sessionDetail.find(value => value.id === data.session_group_id);
    setEditData(sendingData);
    navigate('/dashboard/sessions/edit-session', {
      state: sendingData,
    });
  };
  const sessionOnclick = (data) => {
    // console.log("data===========>>>>>>>",data)
    // navigate('/dashboard/sessions/all-sessions', {
    //   state: data,
    // });   
    let sendingData = sessionDetail.find(value => value.id === data.session_group_id);
    navigate('/dashboard/sessions/session-details', {
      state: sendingData,
    });
  };
  const handleDelete = (data) => {
    // const data = tableData?.session_groups[index];
    let sendingData = sessionDetail.find(value => value.id === data.session_group_id);
    setEditData(sendingData);
    setDeleteOpen(true);
  };
  const data = JSON.parse(sessionStorage.getItem('mentoring_user'));
  let columns = [];
  if (role === 'participant') {
    columns = [

      {
        name: 'client',
        label: 'Name',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let arr = tableData[tableMeta?.rowIndex];
            const name = arr?.client?.name;
            return (
              <>
                <span
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => {
                    sessionOnclick(arr);
                  }}
                >
                  {tableData[tableMeta?.rowIndex]?.session_number === 1 ? name : ""}
                </span>
              </>
            );
          },

        },
      },
      {
        name: 'programme',
        label: 'Programme',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{tableData[tableMeta?.rowIndex]?.session_number === 1 ? value : ""}</span>;
            // return <span>{value?.name}</span>;
          },
        },
      },

      {
        name: 'coach',
        label: 'Coach',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{tableData[tableMeta?.rowIndex]?.session_number === 1 ? value : ""}</span>;
            // return <span>{value?.name}</span>;
          },
        },
      },

      {
        name: 'session_progress',
        label: 'Session progress',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            console.log("sessionGroup.sessionLength", sessionGroup)
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.session_progress} of {sessionGroup[dataIndex].sessionLength}
              </span>
            );
          },
          // customBodyRenderLite: (dataIndex) => {
          //   const filterArray = exportTableData[dataIndex];
          //   // const iterator = filterArray.values();
          //   console.log("filterArray?.session_progress", filterArray)
          //   const elements = [];
          //   {
          //     for (let i = 1; i <= filterArray?.session_progress; i++) {
          //       elements.push(
          //         <div>
          //           {i} of {filterArray?.session_progress}
          //         </div>
          //       );
          //     }
          //   }
          //   return elements;
          // },
        },
      },

      {
        name: 'themes',
        label: 'Themes',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.themes}
              </span>
            );
          },
        },
      },
      
      {
        name: 'notes',
        label: 'Notes',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.notes}
              </span>
            );
          },
        },
      },
      {
        name: 'session_duration',
        label: 'Session Duration',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.session_duration}
              </span>
            );
          },
        },
      },
      {
        name: 'session_completed',
        label: 'Session Completed',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.session_completed}
              </span>
            );
          },
        },
      },
      {
        name: 'session_status',
        label: 'Session Status',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.session_status}
              </span>
            );
          },
        },
      },
    ];
  } else if (role === 'client') {
    columns = [
      // {
      //   name: '',
      //   label: '',
      //   options: {
      //     filter: false,
      //     customBodyRender: (value, tableMeta, update) => {
      //       let rowIndex = Number(tableMeta.rowIndex) + 1;
      //       return <span>{rowIndex}</span>;
      //     },
      //   },
      // },
      // {
      //   name: 'name',
      //   label: 'Name',
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       const arr = tableData?.session_groups[tableMeta?.rowIndex];
      //       return (
      //         <>
      //           <span
      //             style={{ cursor: 'pointer', textDecoration: 'underline' }}
      //             onClick={() => {
      //               sessionOnclick(arr);
      //             }}
      //           >
      //             {value}
      //           </span>
      //         </>
      //       );
      //     },
      //   },
      // },
      // {
      //   name: 'client',
      //   label: 'Client',
      //   options: {
      //     filter: true,
      //     sort: false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return <span>{value?.name}</span>;
      //     },
      //   },
      // },
      {
        name: 'programme',
        label: 'Programme',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{tableData[tableMeta?.rowIndex]?.session_number === 1 ? value : ""}</span>;
            // return <span>{value?.name}</span>;
          },
        },
      },
      {
        name: 'participant',
        label: 'Participant',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{tableData[tableMeta?.rowIndex]?.session_number === 1 ? value : ""}</span>;
            // return <span>{value?.name}</span>;
          },
        },
      },
      {
        name: 'coach',
        label: 'Coach',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{tableData[tableMeta?.rowIndex]?.session_number === 1 ? value : ""}</span>;
            // return <span>{value?.name}</span>;
          },
        },
      },
      {
        name: 'session_progress',
        label: 'Session progress',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.session_progress} of {sessionGroup[dataIndex].sessionLength}
              </span>
            );
          },
        },
      },

      // {
      //   name: 'session_complete',
      //   label: 'Session Complete',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     customBodyRenderLite: (dataIndex, value) => {
      //       const filterArray = exportTableData[dataIndex];
      //       return (
      //         <span>
      //           {filterArray?.session_complete}
      //         </span>
      //       );
      //     },
      //   },
      // },
      {
        name: 'themes',
        label: 'Themes',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.themes}
              </span>
            );
          },
        },
      },
      {
        name: 'notes',
        label: 'Notes',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.notes}
              </span>
            );
          },
        },
      },
      {
        name: 'session_duration',
        label: 'Session Duration',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.session_duration}
              </span>
            );
          },
        },
      },
      {
        name: 'session_completed',
        label: 'Session Completed',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.session_completed}
              </span>
            );
          },
        },
      },
      {
        name: 'session_status',
        label: 'Session Status',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.session_status}
              </span>
            );
          },
        },
      },
      // {
      //   name: '',
      //   label: 'Actions',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     customBodyRenderLite: (dataIndex) => {
      //       return (
      //         <Stack direction="row" alignItems="center" spacing={1}>
      //           <Button
      //             startIcon={deleteIcon}
      //             className={classes.deleteBtn}
      //             onClick={() => {
      //               handleDelete(dataIndex);
      //             }}
      //           />
      //           {data.role !== "client" &&
      //             <Button
      //               startIcon={editIcon}
      //               className={classes.editBtn}
      //               onClick={() => {
      //                 handleEdit(dataIndex);
      //               }}
      //             />
      //           }
      //         </Stack>
      //       );
      //     },
      //   },
      // },
    ];
  } else {
    columns = [
      // { 
      //   name: '',
      //   label: '',
      //   options: {
      //     filter: false,
      //     customBodyRender: (value, tableMeta, update) => {
      //       let rowIndex = Number(tableMeta.rowIndex) + 1;
      //       return <span>{rowIndex}</span>;
      //     },
      //   },
      // },
      // {
      //   name: 'name',
      //   label: 'Name',
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       const arr = tableData?.session_groups[tableMeta?.rowIndex];
      //       return (
      //         <>
      //           <span
      //             style={{ cursor: 'pointer', textDecoration: 'underline' }}
      //             onClick={() => {
      //               sessionOnclick(arr);
      //             }}
      //           >
      //             {value}
      //           </span>
      //         </>
      //       );
      //     },
      //   },
      // },
      {
        name: 'client',
        label: 'Client',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{tableData[tableMeta?.rowIndex]?.session_number === 1 ? value : ""}</span>;
          },
        },
      },
      {
        name: 'programme',
        label: 'Programme',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{tableData[tableMeta?.rowIndex]?.session_number === 1 ? value : ""}</span>;
          },
        },
      },
      {
        name: 'participant',
        label: 'Participant',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{tableData[tableMeta?.rowIndex]?.session_number === 1 ? value : ""}</span>;
          },
        },
      },
      {
        name: 'coach',
        label: 'Coach',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <span>{tableData[tableMeta?.rowIndex]?.session_number === 1 ? value : ""}</span>;
          },
        },
      },
      {
        name: 'session_progress',
        label: 'Session progress',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.session_progress} of {sessionGroup[dataIndex].sessionLength}
              </span>
            );
          },
        },
      },

      // {
      //   name: 'session_complete',
      //   label: 'Session Complete',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     customBodyRenderLite: (dataIndex, value) => {
      //       const filterArray = exportTableData[dataIndex];
      //       return (
      //         <span>
      //           {filterArray?.session_complete}
      //         </span>
      //       );
      //     },
      //   },
      // },
      {
        name: 'themes',
        label: 'Themes',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.themes}
              </span>
            );
          },
        },
      },
      {
        name: 'notes',
        label: 'Notes',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.notes}
              </span>
            );
          },
        },
      },
      {
        name: 'session_duration',
        label: 'Session Duration',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.session_duration}
              </span>
            );
          },
        },
      },
      {
        name: 'session_completed',
        label: 'Session Completed',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.session_completed}
              </span>
            );
          },
        },
      },
      {
        name: 'session_status',
        label: 'Session Status',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex, value) => {
            const filterArray = exportTableData[dataIndex];
            return (
              <span>
                {filterArray?.session_status}
              </span>
            );
          },
        },
      },
      {
        name: '',
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (dataIndex, tableMeta) => {
            let rowData = tableData[tableMeta?.rowIndex]
            let arr = tableData[tableMeta?.rowIndex];
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                {tableData[tableMeta?.rowIndex]?.session_number === 1 ?
                  <>
                    <Button
                      startIcon={deleteIcon}
                      className={classes.deleteBtn}
                      onClick={() => {
                        handleDelete(arr);
                      }}
                    />
                    <Button
                      startIcon={editIcon}
                      className={classes.editBtn}
                      onClick={() => {
                        handleEdit(arr);
                      }}
                    /> </> : ""}
              </Stack>
            );
          },
        },
      },
    ];
  }

  const onchangeHandler = (value) => {
    // setSearched(value);
    setTimeout(async () => {
      await axios
        .get(`${base_url}/api/session-groups?search=${value}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setCount(Math.ceil(res?.data?.data?.session_groups?.length / rowsPerPage));
          setTableData(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000);
  };
  let exportTableData = [];
  if (sessionGroup) {
    exportTableData = [...sessionGroup].map((data, i) => {
      let clientNames = data?.client?.name;
      let programme = data?.programme?.name;
      let participant = data?.participant?.name;
      let coaches = data?.coach?.name;
      let session_duration = data?.session_duration;
      let session_completed = data?.session_completed;
      let themes = data?.themes;
      let notes = data?.notes;

      let totalSession = data?.session_number ? data?.session_number : 0;
      // console.log(data);
      // let session_complete = moment().isAfter(data.session_completed);
      let session_status = data?.session_status ? data?.session_status : "--";//[0]?.toUpperCase() + data?.status?.slice(1);
      // let session_complete = data.session_completed < new Date() ? "Completed" : "Not Completed";
      // let totalSession = data?.sessions?.length ? data?.sessions?.length : 0;

      return {
        name: data.name,
        programme: programme,
        participant: participant,
        coach: coaches,
        client: clientNames,
        session_progress: totalSession,
        notes: notes,
        themes: themes,
        session_completed: session_completed,
        // session_complete: session_complete ? "Completed" : "Not Completed",
        session_status: session_status,
        session_duration: session_duration
        // session_progress: `${data.completed_sessions} of ${totalSession}`
        // session_progress: sessionGroup.map(v => v.session_number)
      }
    });
  }

  return (
    <Page title="Sessions">
      <Container maxWidth={false}>
        <Grid container sx={{ margin: '20px 0' }}>
          <Grid item xs={3} sm={6}>
            <Typography variant="h4" gutterBottom>
              Sessions
            </Typography>
          </Grid>
          <Grid item xs={0} sm={1} xl={3} />
          <Grid item xs={9} sm={5} xl={3}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              {/* <Button
                variant="outlined"
                sx={{ minHeight: '40px', color: '#344054', borderColor: '#D0D5DD' }}
                startIcon={svgIcon}
              >
                Import
              </Button> */}
              {/* <Button
                component="a"
                href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
                download="filename.csv"
                variant="outlined"
                sx={{
                  minHeight: '40px',
                  color: '#344054',
                  borderColor: '#D0D5DD',
                  marginRight: role === 'participant' ? null : '15px',
                }}
                startIcon={
                  <Icon>
                    <img alt="edit" src={cloud2} />
                  </Icon>
                }
              >
                Export
              </Button> */}
              {role === 'participant' ? null : (
                <Button
                  variant="contained"
                  sx={{ minHeight: '40px' }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={() => {
                    navigate('/dashboard/sessions/add-session', { replace: true });
                  }}
                >
                  Add New
                </Button>
              )}
            </div>
          </Grid>
        </Grid>

        <Card sx={{ marginBottom: '24px' }}>
          <Box className={classes.tableManagement}>
            <Stack direction="row" alignItems="start" justifyContent="center" marginTop="24px">
              {/* <Typography variant="h4" sx={{ padding: '10px' }}>
              All companies{' '}
            </Typography>
            <Typography
              sx={{
                color: '#6941C6',
                backgroundColor: '#F9F5FF',
                borderRadius: '16px',
                width: '100px',
                textAlign: 'center',
              }}
            >
              {tableData?.length} records
            </Typography> */}
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search', placeholder: 'Search table' }}
                  onChange={(e) => {
                    onchangeHandler(e.target.value);
                  }}
                />
              </Search>
            </Stack>
            {/* <Divider /> */}

            <Scrollbar>
              <MUIDataTable
                title={
                  <h6
                    style={{
                      fontFamily: 'Public Sans,sans-serif',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      fontSize: '18px',
                      lineHeight: '28px',
                      color: '#101828',
                      textAlign: 'start',
                      display: 'flex',
                    }}
                  >
                    All Sessions
                    <span
                      style={{
                        fontFamily: 'Public Sans,sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#6941C6',
                        background: '#F9F5FF',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '2px 8px',
                        width: 'fit-content',
                        marginLeft: '1rem',
                      }}
                    >
                      {tableData?.total || tableData?.session_groups?.length} records
                    </span>
                  </h6>
                }
                data={exportTableData}
                columns={columns}
                options={options}
              // grouping={true} // Enable grouping
              // groupModel={groupingModel}
              />

            </Scrollbar>
            {/* <Box className={classes.pagination}>
              <Pagination
                count={count}
                onChange={handleChangePage}
                page={page}
                shape="rounded"
                color="primary"
                defaultPage={1}
              />
            </Box> */}
          </Box>
        </Card>
      </Container>
      {/* <AddDialogue setAddOpen={setAddOpen} addOpen={addOpen} getList={getList} /> */}
      {/* <EditDialogue setEditOpen={setEditOpen} editOpen={editOpen} getList={getList} editData={editData} /> */}
      <DeleteDialogue setDeleteOpen={setDeleteOpen} deleteOpen={deleteOpen} getList={getList} editData={editData} />
    </Page>
  );
}

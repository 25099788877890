import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import Iconify from 'src/components/Iconify';
import axios from 'axios';
import { base_url } from 'src/common';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const useStyle = makeStyles((theme) => ({
  modalMain: {
    width: '530px',

    // height: '530px',

    // backgroundColor: theme.palette.primary.dark,

    border: '1px solid rgba(239, 239, 239, 0.1)',

    borderRadius: '10px',

    borderColor: '#fff',
    padding: '15px 15px',
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '15px 15px',
    },
    '& .heading': {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
    },
  },
}));

export default function AddDialogue(props) {
  const { setAddOpen, addOpen, getList } = props;
  const classes = useStyle();
  const [loadingState, setloadingState] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const token = sessionStorage.getItem('libra_admin_token');

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Enter proper email address').required('Email is required'),
    password: Yup.string().required('CPassword is required'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setloadingState(true);
      console.log('values', values);
      // navigate('/dashboard', { replace: true });
      axios
        .post(`${base_url}/api/companies`, values, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setloadingState(false);
          console.log(res);
          if (res?.data?.status === true) {
            setAddOpen(false);
            toast.success('Coaching Provider added successfully');
            clearData();
            getList();
          }
        })
        .catch((err) => {
          setloadingState(false);

          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            toast.error('Server Error');
          }
        });
    },
  });

  const { errors, touched, values, isSubmitting, setFieldValue, resetForm, handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };
  const clearData = () => {
    resetForm();
  };
  return (
    <div>
      <Dialog
        open={addOpen}
        onClose={() => {
          setAddOpen(false);
          clearData();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ classes: { root: classes.modalMain } }}
      >
        <DialogTitle id="alert-dialog-title" className="heading">
          {'Add Mentoring Coaching Provider'}
        </DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ marginTop: '0.5rem' }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  label="Confirm Password"
                  {...getFieldProps('password_confirmation')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowConfirmPassword} edge="end">
                          <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                  helperText={touched.password_confirmation && errors.password_confirmation}
                />
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mt: 3 }}>
                <Button
                  onClick={() => {
                    setAddOpen(false);
                    clearData();
                  }}
                  variant="outlined"
                  sx={{ marginRight: '1rem' }}
                  size="large"
                >
                  Cancel
                </Button>
                <LoadingButton size="large" type="submit" variant="contained" loading={loadingState}>
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
        {/* <DialogActions>
          <Button
            onClick={() => {
              setAddOpen(false);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
      <Toaster />
    </div>
  );
}

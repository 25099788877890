import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';
// material
// import SearchBar from 'material-ui-search-bar';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

import { Card, Stack, Button, Container, Typography, Grid, Icon, Box, InputBase } from '@mui/material';
// components

import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import cloud from '../../../assets/images/cloud.svg';
import edit from '../../../assets/images/edit.svg';
import del from '../../../assets/images/delete.svg';
import cloud2 from '../../../assets/images/export.svg';
import axios from 'axios';
import { base_url } from 'src/common';
import DeleteDialogue from './dialogues/delete';
import MUIDataTable from 'mui-datatables';
import ImportPartcipant from './dialogues/importParticipant';
import { img_base_url } from 'src/common';
// import Scrollbar from 'src/components/Scrollbar';
const useStyle = makeStyles((theme) => ({
  pagination: {
    '& li': {
      '&:first-child': {
        marginRight: 'auto !important',
        '& button': {
          width: '6rem',
        },
      },
      '&:last-child': {
        marginLeft: 'auto !important',
        '& button': {
          width: '5rem',
        },
      },
    },
  },
  deleteBtn: {
    '& span': {
      margin: '0px',
    },
  },
  editBtn: {
    '& span': {
      margin: '0px',
    },
  },
  tableManagement: {
    '& .MuiToolbar-root ': {},
  },
}));
// ----------------------------------------------------------------------

const svgIcon = (
  <Icon>
    <img alt="edit" src={cloud} />
  </Icon>
);

const deleteIcon = (
  <Icon>
    <img alt="edit" src={del} />
  </Icon>
);

const editIcon = (
  <Icon>
    <img alt="edit" src={edit} />
  </Icon>
);
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  // marginLeft: 'auto !important',
  border: '1px solid gray',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  '& input': {
    width: '600px !important',
  },
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));
const data = JSON.parse(sessionStorage.getItem('mentoring_user'));
export default function Participants() {
  const { role } = JSON.parse(sessionStorage.getItem('mentoring_user')) || '';
  const { all_coach } = JSON.parse(sessionStorage.getItem('all_coach')) || '';
  const company_id = sessionStorage.getItem('Company_id') || '';
  const formData = new FormData();
  const hiddenFileInput = useRef(null);
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [importOpen, setimportOpen] = useState(false);

  const classes = useStyle();

  const [page, setPage] = useState(1);

  // const [searched, setSearched] = useState('');
  const [editData, setEditData] = useState(null);

  //   const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPage = 10;

  const [tableData, setTableData] = useState({});
  const [dataInCSV, setDataInCSV] = useState('');
  useEffect(() => {
     async function fetchData() {
    await axios
      .get(`${base_url}/api/participants/export`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log("participants data check", res.data)
        setDataInCSV(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
    fetchData();
  }, [tableData?.participants]);

  const token = sessionStorage.getItem('libra_admin_token');
  const getRowOption = (apiValue) => {
    let firstValue = 0;
    let secondValue = 0;
    let thirdValue = 0;
    if (apiValue > 29) {
      if (apiValue > 500) {
        firstValue = 10;
        secondValue = 100;
        thirdValue = apiValue
      } else {
        firstValue = Math.round(apiValue / 30) * 10
        secondValue = Math.round(apiValue / 20) * 10
        thirdValue = apiValue
      }
    } else if (apiValue > 20 && apiValue <= 29) {
      firstValue = 10;
      secondValue = 20;
      thirdValue = apiValue
    } else if (apiValue <= 20 && apiValue > 10) {
      firstValue = 10;
      secondValue = apiValue;
    } else {
      firstValue = apiValue;
    }
    return { firstValue, secondValue, thirdValue }
  }
  const getList = async (noOfRow) => {
    await axios
      .get(`${base_url}/api/participants?page=${page}&per_page=${noOfRow ? noOfRow : 10}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        // console.log("participants data check for tabel data",res.data.data.total);
        const { firstValue, secondValue, thirdValue } = getRowOption(res?.data?.data?.total);
        let rowOption = [];
        if (firstValue) rowOption.push(firstValue)
        if (secondValue) rowOption.push(secondValue)
        if (thirdValue) rowOption.push(thirdValue)
        setOptions({ ...options, rowsPerPageOptions: rowOption });
        setCount(Math.ceil(res?.data?.data?.total / rowsPerPage));
        setTableData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [options, setOptions] = useState({
    filterType: 'dropdown',
    responsive: 'standard',
    search: false,
    download: true,
    print: false,
    selectableRows: false,
    pagination: true,
    rowsPerPageOptions: [10, 20, 50, 'All'],
    onChangeRowsPerPage: getList,
    downloadOptions: {
      filterOptions: { useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true }
    },
  });

  useEffect(() => {
    getList();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    console.log(newPage, event);
    setPage(newPage);
  };

  //   console.log('tableData', tableData);

  const handleEdit = (index) => {
    const data = tableData?.participants[index];
    setEditData(data);
    navigate('/dashboard/participants/edit-participant', {
      state: data,
    });
  };
  const handleDelete = (index) => {
    const data = tableData?.participants[index];
    console.log(data);
    setEditData(data);
    setDeleteOpen(true);
  };

  const columns = [
    // {
    //   name: '',
    //   label: '',
    //   options: {
    //     filter: false,
    //     customBodyRender: (value, tableMeta, update) => {
    //       let rowIndex = Number(tableMeta.rowIndex) + 1;
    //       return <span>{rowIndex}</span>;
    //     },
    //   },
    // },
    {
      name: 'profile_picture',
      label: 'Profile',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (profile_picture) => {
          if (profile_picture) {
            
            return (
              <>
              <img
                src={`${img_base_url}/${profile_picture}`}
                alt="profile"
                width="40px"
                height="40px"
                style={{ borderRadius: '50%' }}
                />
            </>
          );
          } else {
            return (
              <>profile</>
            )
        }
        },
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (name) => {
          return <span style={{ wordBreak: 'break-all' }}>{name}</span>;
        },
      },
    },
    {
      name: 'client',
      label: 'Client',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (client) => {
          return <span style={{ wordBreak: 'break-all' }}>{client}</span>;
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (email) => {
          return <span style={{ wordBreak: 'break-all' }}>{email}</span>;
        },
      },
    },
    {
      name: 'programmes',
      label: 'Programmes',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (programmes) => {
          return <span>{programmes}</span>;
        },
      },
    },
    // {
    //   name: 'participant_details',
    //   label: 'Group',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <span>{value?.group?.name}</span>;
    //     },
    //   },
    // },
    {
      name: 'coach',
      label: 'Coaches',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{value || '---'} </span>;
        },
      },
    },

    {
      name: '',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                startIcon={deleteIcon}
                className={classes.deleteBtn}
                onClick={() => {
                  handleDelete(dataIndex);
                }}
              />
              <Button
                startIcon={editIcon}
                className={classes.editBtn}
                onClick={() => {
                  handleEdit(dataIndex);
                }}
              />
            </Stack>
          );
        },
      },
    },
  ];

  // const options = {
  //   filterType: 'dropdown',
  //   // filter: false,
  //   search: false,
  //   download: true,
  //   print: false,
  //   selectableRows: false,
  //   pagination: true,
  //   rowsPerPageOptions: [10, 50, 500],
  //   onChangeRowsPerPage: getList,
  //   downloadOptions: {
  //     // customCSVdata: fullServersideData,  
  //   },
  //   filterOptions: { useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true, }
  //   // textLabels: {
  //   //   pagination: {
  //   //     next: 'Next >',
  //   //     previous: '< Previous',
  //   //     rowsPerPage: 'Total items Per Page',
  //   //     displayRows: 'OF',
  //   //   },
  //   // },
  // };
  const onchangeHandler = (value) => {
    // setSearched(value);
    setTimeout(async () => {
      await axios
        .get(`${base_url}/api/participants?search=${value}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          // console.log(res?.data?.data);
          setCount(Math.ceil(res?.data?.data?.participants?.length / rowsPerPage));
          setTableData(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 0);
  };
  // console.log("participants data check for tabel data",tableData?.total);
  // {console.log("tabledataLength",tableData?.participants)}
  let filteredTableData = tableData?.participants?.map(data => ({
    ...data, client: data?.participant_details?.client?.name,
    programmes: data?.participant_programmes?.map(programmeName => { return programmeName?.name }).toString()
  }))

  let exportTableData = [];
  if (filteredTableData) {
    exportTableData = [...filteredTableData].map((data, i) => {
      let coach = data?.participant_details?.coach?.name;
      let email = data?.email ? data?.email : "";
      let client = data?.client ? data?.client : "";
      let programmes = data?.programmes ? data?.programmes : "";
      let profile_picture = data?.profile_picture;
      return {
        name: data.name,
        email: email,
        coach: coach,
        client: client,
        programmes: programmes,
        profile_picture,
      }
    });}
    
    return (
      <Page title="Participants" >
        <Container maxWidth={false}>
          <Grid container sx={{ margin: '20px 0' }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4" gutterBottom>
                Participants
              </Typography>
            </Grid>
            <Grid item xs={0} sm={1} xl={3} />
            <Grid item xs={12} sm={5} xl={3}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                {role !== 'participant' ? (
                  <>
                    <Button
                      variant="outlined"
                      sx={{ minHeight: '40px', color: '#344054', borderColor: '#D0D5DD', marginRight: '10px' }}
                      startIcon={svgIcon}
                      onClick={() => setimportOpen(true)}
                    >
                      Import
                    </Button>
                  </>
                ) : null}
                {/* s */}
                <Button
                  variant="contained"
                  sx={{ minHeight: '40px' }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={() => {
                    navigate('/dashboard/participants/add-participant', { replace: true });
                  }}
                >
                  Add New
                </Button>
              </div>
            </Grid>
          </Grid>

          <Card sx={{ marginBottom: '24px' }}>
            <Box className={classes.tableManagement}>
              <Stack direction="row" alignItems="start" justifyContent="center" marginTop="24px">
                {/* <Typography variant="h4" sx={{ padding: '10px' }}>
              All companies{' '}
            </Typography>
            <Typography
              sx={{
                color: '#6941C6',
                backgroundColor: '#F9F5FF',
                borderRadius: '16px',
                width: '100px',
                textAlign: 'center',
              }}
            >
              {tableData?.length} records
            </Typography> */}
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search', placeholder: 'Search table' }}
                    onChange={(e) => {
                      onchangeHandler(e.target.value);
                    }}
                  />
                </Search>
              </Stack>
              {/* <Divider /> */}

              <Scrollbar>
                <MUIDataTable
                  exportButton={true}
                  title={
                    <h6
                      style={{
                        fontFamily: 'Public Sans,sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '18px',
                        lineHeight: '28px',
                        color: '#101828',
                        textAlign: 'start',
                        display: 'flex',
                      }}
                    >
                      All participants
                      <span
                        style={{
                          fontFamily: 'Public Sans,sans-serif',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          fontSize: '12px',
                          lineHeight: '18px',
                          color: '#6941C6',
                          background: '#F9F5FF',
                          borderRadius: '16px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '2px 8px',
                          width: 'fit-content',
                          marginLeft: '1rem',
                        }}
                      >
                        {tableData?.total} records
                      </span>
                    </h6>
                  }
                  data={exportTableData}

                  columns={columns}
                  options={options}
                />
                {/* {console.log("table data from table",filteredTableData)} */}
              </Scrollbar>
              <Box className={classes.pagination}>
                <Pagination
                  count={count}
                  onChange={handleChangePage}
                  page={page}
                  shape="rounded"
                  color="primary"
                  defaultPage={1}
                />
              </Box>
            </Box>
          </Card>
        </Container>
        <ImportPartcipant setimportOpen={setimportOpen} importOpen={importOpen} getList={getList} />
        <DeleteDialogue setDeleteOpen={setDeleteOpen} deleteOpen={deleteOpen} getList={getList} editData={editData} />
      </Page>
    );
  }

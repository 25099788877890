import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';
// material
// import SearchBar from 'material-ui-search-bar';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { CSVLink } from 'react-csv';

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Divider,
  Grid,
  Icon,
  PaginationItem,
  Box,
  InputBase,
} from '@mui/material';
// components

import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import Label from '../../../components/Label';
import Page from '../../../components/Page';
import SearchNotFound from '../../../components/SearchNotFound';
import { UserListHead, UserMoreMenu } from '../user';
import cloud from '../../../assets/images/cloud.svg';
import edit from '../../../assets/images/edit.svg';
import del from '../../../assets/images/delete.svg';
import cloud2 from '../../../assets/images/export.svg';
import { toSVG } from '../../../toSVG/toSVG';
import axios from 'axios';
import { base_url, img_base_url } from 'src/common';
import moment from 'moment';
import DeleteDialogue from './dialogues/delete';
import MUIDataTable from 'mui-datatables';

// import Scrollbar from 'src/components/Scrollbar';
const useStyle = makeStyles((theme) => ({
  pagination: {
    '& li': {
      '&:first-child': {
        marginRight: 'auto !important',
        '& button': {
          width: '6rem',
        },
      },
      '&:last-child': {
        marginLeft: 'auto !important',
        '& button': {
          width: '5rem',
        },
      },
    },
  },
  deleteBtn: {
    '& span': {
      margin: '0px',
    },
  },
  editBtn: {
    '& span': {
      margin: '0px',
    },
  },
  tableManagement: {
    '& .MuiToolbar-root ': {},
  },
}));
// ----------------------------------------------------------------------

const svgIcon = (
  <Icon>
    <img alt="edit" src={cloud} />
  </Icon>
);

const deleteIcon = (
  <Icon>
    <img alt="edit" src={del} />
  </Icon>
);

const editIcon = (
  <Icon>
    <img alt="edit" src={edit} />
  </Icon>
);
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  // marginLeft: 'auto !important',
  border: '1px solid gray',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  '& input': {
    width: '600px !important',
  },
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function Clients() {
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [count, setCount] = useState(null);

  const classes = useStyle();

  const [page, setPage] = useState(1);
  const [dataThird, setDataThird] = useState();
  // const [searched, setSearched] = useState('');
  const [editData, setEditData] = useState(null);

  //   const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPage = 10;

  const [tableData, setTableData] = useState({});
  const [dataInCSV, setDataInCSV] = useState('');
  
  const [allQualifications, setallQualifications] = useState([]);

  const getAllQualifications = async () => {
          await axios
            .get(`${base_url}/api/qualifications`, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
              setallQualifications(res?.data?.data?.qualifications);
            })
            .catch((err) => {
              console.log(err);
            });
      };
      
  useEffect(() => {
  getAllQualifications();  
  },[]);
  useEffect(() => {
     async function fetchData() {
    await axios
      .get(`${base_url}/api/coaches/export`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setDataInCSV(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
    fetchData();
  }, [tableData?.coaches]);

  const token = sessionStorage.getItem('libra_admin_token');
  const userRole = JSON.parse(sessionStorage.getItem('mentoring_user'));
  const getRowOption = (apiValue) => {
    let firstValue = 0;
    let secondValue = 0;
    let thirdValue = 0;
    if (apiValue > 29) {
      if (apiValue > 500) {
        firstValue = 10;
        secondValue = 100;
        thirdValue = apiValue
      } else {
        firstValue = Math.round(apiValue / 30) * 10
        secondValue = Math.round(apiValue / 20) * 10
        thirdValue = apiValue
      }
    } else if (apiValue > 20 && apiValue <= 29) {
      firstValue = 10;
      secondValue = 20;
      thirdValue = apiValue
    } else if (apiValue <= 20 && apiValue > 10) {
      firstValue = 10;
      secondValue = apiValue;
    } else {
      firstValue = apiValue;
    }
    return { firstValue, secondValue, thirdValue }
  }
  const getList = async (noOfRow) => {
    await axios
      .get(`${base_url}/api/coaches?page=${page}&per_page=${noOfRow ? noOfRow : 10}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const { firstValue, secondValue, thirdValue } = getRowOption(res?.data?.data?.total);
        let rowOption = [];
        if (firstValue) rowOption.push(firstValue)
        if (secondValue) rowOption.push(secondValue)
        if (thirdValue) rowOption.push(thirdValue)
        setOptions({ ...options, rowsPerPageOptions: rowOption });
        setCount(Math.ceil(res?.data?.data?.total / rowsPerPage));
        setTableData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [options, setOptions] = useState({
    filterType: 'dropdown',
    responsive: 'standard',
    search: false,
    download: true,
    print: false,
    selectableRows: false,
    pagination: true,
    rowsPerPageOptions: [10, 20, 50, 'All'],
    onChangeRowsPerPage: getList,
    downloadOptions: {
      filterOptions: { useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true }
    },
  });
  useEffect(() => {
    getList();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    console.log(newPage, event);
    setPage(newPage);
  };

  //   console.log('tableData', tableData);

  const handleEdit = (index) => {
    const data = tableData?.coaches[index];
    setEditData(data);
    navigate('/dashboard/coaches/edit-coach', { state: data });
  };
  const handleDelete = (index) => {
    const data = tableData?.coaches[index];
    console.log(data);
    setEditData(data);
    setDeleteOpen(true);
  };
  const coachOnClick = (data) => {
    navigate('/dashboard/coaches/coach-detail', { state: data });
  };

  // let dataInsert = tableData?.coaches;
  // tableData?.coaches?.map((item) => dataInsert?.push(item?.clients));
  // console.log('dataInsert', dataInsert);
  // let dataTwo = [];
  // dataInsert?.map((item) => dataTwo?.push(item?.name));
  // console.log('dataTwo', dataTwo);
  // for (let indexc of dataTwo) {
  //   console.log('indexc', indexc);
  //   // setDataThird(indexc);
  // }
  // dataTwo?.map((item) => {
  //
  // });
  // console.log("userRole ===>>>", userRole)
  const columns = userRole?.role !== 'participant' ? [
    // {
    //   name: '',
    //   label: '',
    //   options: {
    //     filter: false,
    //     customBodyRender: (value, tableMeta, update) => {
    //       let rowIndex = Number(tableMeta.rowIndex) + 1;
    //       return <span>{rowIndex}</span>;
    //     },
    //   },
    // },
    {
      name: 'profile_picture',
      label: 'Profile',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (profile_picture) => {
          if (profile_picture) {
            
            return (
              <>
              <img
                src={`${img_base_url}/${profile_picture}`}
                alt="profile"
                width="40px"
                height="40px"
                style={{ borderRadius: '50%' }}
                />
            </>
          );
          } else {
            return (
              <>profile</>
            )
        }
        },
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const arr = tableData?.coaches[tableMeta?.rowIndex];
          return (
            <>
              {userRole?.role === 'client' ? (
                <span
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => {
                    coachOnClick(arr);
                  }}
                >
                  {value}
                </span>
              ) : (
                <span onClick={() => {
                  coachOnClick(arr);
                }}>{value}</span>
              )}
            </>
          );
        },
      },
    },

    userRole?.role !== 'client' ?

      {
        name: 'coach_profile',
        label: 'Client',
        options: {
          filter: true,
          sort: false,

          customBodyRender: (value, tableMeta, updateValue) => {
            const arrie = tableData?.coaches[tableMeta?.rowIndex];
            return (
              <>
                {arrie?.clients?.map((item,index) => {
                  const commaSeparated = [item?.name];
                  return arrie?.clients.length > 1  ? <>{arrie?.clients.length-1 !== index ? <span>{commaSeparated.concat(", ")}</span>: <span>{commaSeparated}</span> }</>: <span>{commaSeparated}</span>
                })} </>
            );
          },
        },
      } : "",

    // {
    //   name: 'coach_programmes',
    //   label: 'Programmes',
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <span>{value?.length}</span>;
    //     },
    //   },
    // },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (email) => {
          return <span style={{ wordBreak: 'break-all' }}>{email}</span>;
        },
      },
    },
    {
      name: 'qualifications',
      label: 'Qualifications',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (qualifications) => {
           const formattedQualifications = qualifications.join(', ');
          return <span style={{ wordBreak: 'break-all' }}>{formattedQualifications}</span>;
        },
      },
    },
    {
      name: 'phone_number',
      label: 'Phone',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{value}</span>;
          // return <span>{value !== null ? `${value}` : '---'}</span>;
        },
      },
    },
    // {
    //   name: '',
    //   label: 'Programmes',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRenderLite: (dataIndex) => {
    //       const singleObject = tableData?.coaches[dataIndex];

    //       return <span>{singleObject?.permissions?.length}</span>;
    //     },
    //   },
    // },
    // {
    //   name: '',
    //   label: 'Sponsors',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRenderLite: (dataIndex) => {
    //       const singleObject = tableData?.coaches[dataIndex];

    //       return <span>{singleObject?.sponsors?.length}</span>;
    //     },
    //   },
    // },
    // {
    //   name: '',
    //   label: 'Primary sponsor name',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRenderLite: (dataIndex) => {
    //       const singleObject = tableData?.coaches[dataIndex];

    //       const sponsorsArray = singleObject?.sponsors?.filter((arr) => arr?.is_default === true);

    //       return <span>{sponsorsArray[0]?.name || '---'}</span>;
    //     },
    //   },
    // },
    // {
    //   name: '',
    //   label: 'Primary sponsor email',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRenderLite: (dataIndex) => {
    //       const singleObject = tableData?.coaches[dataIndex];

    //       const sponsorsArray = singleObject?.sponsors?.filter((arr) => arr?.is_default === true);

    //       return <span>{sponsorsArray[0]?.email || '---'}</span>;ss
    //     },
    //   },
    // },
    {
      name: '',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                startIcon={deleteIcon}
                className={classes.deleteBtn}
                onClick={() => {
                  handleDelete(dataIndex);
                }}
              />
              {userRole.role !== 'client' &&
                <Button
                  startIcon={editIcon}
                  className={classes.editBtn}
                  onClick={() => {
                    handleEdit(dataIndex);
                  }}
                />
              }
            </Stack>
          );
        },
      },
    },
  ] : [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const arr = tableData?.coaches[tableMeta?.rowIndex];
          return (
            <>
              {userRole?.role === 'client' ? (
                <span
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => {
                    coachOnClick(arr);
                  }}
                >
                  {value}
                </span>
              ) : (
                <span style={{ cursor: 'pointer' }} onClick={() => {
                  coachOnClick(arr);
                }}>{value}</span>
              )}
            </>
          );
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (email) => {
          return <span style={{ wordBreak: 'break-all' }}>{email}</span>;
        },
      },
    },
    {
      name: 'phone_number',
      label: 'Phone',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{value}</span>;
          // return <span>{value !== null ? `${value}` : '---'}</span>;
        },
      },
    },
  ];
  const onchangeHandler = (value) => {
    // setSearched(value);
    setTimeout(async () => {
      await axios
        .get(`${base_url}/api/coaches?search=${value}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setCount(Math.ceil(res?.data?.data?.coaches?.length / rowsPerPage));
          setTableData(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 0);
  };
  let exportTableData = [];
  if (tableData?.coaches) {
    exportTableData = [...tableData?.coaches].map((data, i) => {
      let clientNames = data?.clients.map((client) => { return client.name });
      // clientNames =  clientNames?.length > 1 ? clientNames.concat(", ") : clientNames[0];
      console.log("clientNames", clientNames)
      let email = data?.email;
      let phone_number = data?.phone_number;
      let profile_picture = data?.profile_picture;
      let coach_qualification = [];
      data?.coach_qualification?.map((item) => {
        allQualifications?.filter((inner) => {
          if (item?.qualification_id == inner?.id) {
            // console.log(item);
          coach_qualification.push(inner.qualification_name);
          }
        });
      });
       
      // console.log(coach_qualification);
      return {
        name: data.name,
        coach_profile: clientNames,
        email: email,
        phone_number: phone_number,
        profile_picture: profile_picture,
        qualifications: coach_qualification,

      }
    });
  }
  return (
    <Page title="Coaches">
      <Container maxWidth={false}>
        <Grid container sx={{ margin: '20px 0' }}>
          <Grid item xs={3} sm={6}>
            <Typography variant="h4" gutterBottom>
              Coaches
            </Typography>
          </Grid>
          <Grid item xs={0} sm={1} xl={3} />
          <Grid item xs={9} sm={5} xl={3}>
            {(userRole?.role === 'client' || userRole?.role === 'participant') ? null : (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                {/* <Button
                  variant="outlined"
                  sx={{ minHeight: '40px', color: '#344054', borderColor: '#D0D5DD' }}
                  startIcon={svgIcon}
                >
                  Import
                </Button> */}

                {/* <Button
                  component="a"
                  href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
                  download="filename.csv"
                  variant="outlined"
                  sx={{ minHeight: '40px', color: '#344054', borderColor: '#D0D5DD', marginRight: '15px' }}
                  startIcon={
                    <Icon>
                      <img alt="edit" src={cloud2} />
                    </Icon>
                  }
                >
                  Export
                </Button> */}

                <Button
                  variant="contained"
                  sx={{ minHeight: '40px' }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={() => {
                    navigate('/dashboard/coaches/add-coach', { replace: true });
                  }}
                >
                  Add New
                </Button>
              </div>
            )}
          </Grid>
        </Grid>

        <Card sx={{ marginBottom: '24px' }}>
          <Box className={classes.tableManagement}>
            <Stack direction="row" alignItems="start" justifyContent="center" marginTop="24px">
              {/* <Typography variant="h4" sx={{ padding: '10px' }}>
              All companies{' '}
            </Typography>
            <Typography
              sx={{
                color: '#6941C6',
                backgroundColor: '#F9F5FF',
                borderRadius: '16px',
                width: '100px',
                textAlign: 'center',
              }}
            >
              {tableData?.length} records
            </Typography> */}
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search', placeholder: 'Search table' }}
                  onChange={(e) => {
                    onchangeHandler(e.target.value);
                  }}
                />
              </Search>
            </Stack>
            {/* <Divider /> */}

            <Scrollbar>
              <MUIDataTable
                exportButton={true}
                title={
                  <h6
                    style={{
                      fontFamily: 'Public Sans,sans-serif',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      fontSize: '18px',
                      lineHeight: '28px',
                      color: '#101828',
                      textAlign: 'start',
                      display: 'flex',
                    }}
                  >
                    All Coaches
                    <span
                      style={{
                        fontFamily: 'Public Sans,sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#6941C6',
                        background: '#F9F5FF',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '2px 8px',
                        width: 'fit-content',
                        marginLeft: '1rem',
                      }}
                    >
                      {tableData?.total || tableData?.coaches?.length} records
                    </span>
                  </h6>
                }
                data={exportTableData}
                columns={columns}
                options={options}
              />
            </Scrollbar>
            <Box className={classes.pagination}>
              <Pagination
                count={count}
                onChange={handleChangePage}
                page={page}
                shape="rounded"
                color="primary"
                defaultPage={1}
              />
            </Box>
          </Box>
        </Card>
      </Container>
      {/* <AddDialogue setAddOpen={setAddOpen} addOpen={addOpen} getList={getList} /> */}
      {/* <EditDialogue setEditOpen={setEditOpen} editOpen={editOpen} getList={getList} editData={editData} /> */}
      <DeleteDialogue setDeleteOpen={setDeleteOpen} deleteOpen={deleteOpen} getList={getList} editData={editData} />
    </Page>
  );
}

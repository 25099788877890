import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { img_base_url } from 'src/common';

const useStyle = makeStyles((theme) => ({
  blueDiv: {
    background: '#1570EF',
    height: '240px',
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12rem',
    '& img': {
      border: '4px solid #FFFFFF',
      boxShadow: ' 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      borderRadius: '50%',
      width: '160px',
      height: '160px',
    },
  },
  name: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '30px',
    lineHeight: '38px',
    color: '#101828',
    paddingBottom: '4px',
    paddingTop: '2rem',
  },
  email: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#667085',
  },
  about: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#101828',
    marginTop: '400px',
    paddingBottom: '8px',
    paddingTop:"30px"
  },
  aboutSubTxt: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#667085',
  },
  qualification: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#9E77ED',
    paddingBottom: '8px',
    
    
  },
  qualificationbody: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#6941C6',
  },
}));

function CoachDetail() {
  const location = useLocation();
  const classes = useStyle();
  console.log(location);
  return (
    <Box>
      <Box className={classes.blueDiv}>
        <Box className={classes.imageContainer}>
          <img src={`${img_base_url}/${location?.state?.profile_picture}`} alt="profile" />
          <Box sx={{ marginLeft: '24px' }}>
            <Typography variant="h1" className={classes.name}>
              {location?.state?.name}
            </Typography>
            <Typography variant="body1" className={classes.email}>
              {location?.state?.email}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ paddingLeft: '3rem' }} style={{ backgroundColor:"#fff"}}>
        <Typography variant="h4" className={classes.about}>
          About your Coach
        </Typography>
        <div
          className={classes.aboutSubTxt}
          dangerouslySetInnerHTML={{ __html: location?.state?.coach_profile?.biography }}
        />
        <Box sx={{ paddingTop: '44px',  }}>
          <div style={{backgroundColor:'#F9F5FF',marginRight:"30px", padding: '25px 0px 25px 30px', borderRadius:"5px"}}>
          <Typography variant="h4" className={classes.qualification}>
            Qualifications
          </Typography>
          <Typography variant="body1" className={classes.qualificationbody}>
            {location?.state?.coach_profile?.qualifications}
          </Typography>
          </div>
          <div style={{marginTop:"25px",backgroundColor:'#F9F5FF',marginRight:"30px", padding: '25px 0px 25px 30px', borderRadius:"5px"}}>
          <Typography variant="h4" className={classes.qualification}>
            Sector experience
          </Typography>
          <Typography variant="body1" className={classes.qualificationbody}>
            {location?.state?.coach_profile?.sector_experience}
          </Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default CoachDetail;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

import { Card, Stack, Button, Container, Typography, Grid, Icon, Box, InputBase } from '@mui/material';
// components

import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import cloud from '../../../assets/images/cloud.svg';
import edit from '../../../assets/images/edit.svg';
import del from '../../../assets/images/delete.svg';
import cloud2 from '../../../assets/images/export.svg';
import axios from 'axios';
import { base_url } from 'src/common';
import AdminAddDialogue from './dialogues/adminAdd';
import AddDialogue from './dialogues/add';
import EditDialogue from './dialogues/edit';
import DeleteDialogue from './dialogues/delete';
import MUIDataTable from 'mui-datatables';

// import Scrollbar from 'src/components/Scrollbar';
const useStyle = makeStyles((theme) => ({
  pagination: {
    // paddingBottom: '24px',
    '& li': {
      '&:first-child': {
        marginRight: 'auto !important',
        '& button': {
          width: '6rem',
        },
      },
      '&:last-child': {
        marginLeft: 'auto !important',
        '& button': {
          width: '5rem',
        },
      },
    },
  },
  deleteBtn: {
    '& span': {
      margin: '0px',
    },
  },
  editBtn: {
    '& span': {
      margin: '0px',
    },
  },
  tableManagement: {
    '& .MuiToolbar-root ': {},
  },
}));
// ----------------------------------------------------------------------

const svgIcon = (
  <Icon>
    <img alt="edit" src={cloud} />
  </Icon>
);

const deleteIcon = (
  <Icon>
    <img alt="edit" src={del} />
  </Icon>
);

const editIcon = (
  <Icon>
    <img alt="edit" src={edit} />
  </Icon>
);
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  // marginLeft: 'auto !important',
  border: '1px solid gray',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  '& input': {
    width: '600px !important',
  },
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function Clients() {
  const { role } = JSON.parse(sessionStorage.getItem('mentoring_user')) || '';
  const navigate = useNavigate();
  const [addOpen, setAddOpen] = useState(false);
  const [addOpenAdmin, setAddOpenAdmin] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [count, setCount] = useState(null);

  const classes = useStyle();

  const [page, setPage] = useState(1);

  // const [searched, setSearched] = useState('');
  const [editData, setEditData] = useState(null);

  //   const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPage = 10;

  const getRowOption = (apiValue) => {
    let firstValue = 0;
    let secondValue = 0;
    let thirdValue = 0;
    if (apiValue > 29) {
      if(apiValue > 500){
         firstValue = 10;
         secondValue = 100;
         thirdValue = apiValue
      }else{
       firstValue = Math.round(apiValue / 30) * 10
       secondValue = Math.round(apiValue / 20) * 10
       thirdValue = apiValue
      }
    }else if (apiValue > 20 && apiValue <= 29 ) {
      firstValue = 10;
      secondValue = 20;
      thirdValue = apiValue
    }else if(apiValue <= 20 && apiValue > 10){
      firstValue = 10;
      secondValue = apiValue;
    } else {
      firstValue = apiValue;
    }
    return { firstValue, secondValue, thirdValue }
  }

  const getList = async (noOfRow) => {
    await axios
      .get(`${base_url}/api/clients?page=${page}&per_page=${noOfRow ? noOfRow : 10}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {

        const { firstValue, secondValue, thirdValue } = getRowOption(res?.data?.data?.total);
        let rowOption = [];
        if (firstValue) rowOption.push(firstValue)
        if (secondValue) rowOption.push(secondValue)
        if (thirdValue) rowOption.push(thirdValue)

        setOptions({ ...options, rowsPerPageOptions: rowOption });
        setCount(Math.ceil(res?.data?.data?.total / rowsPerPage));
        setTableData(res?.data?.data);

      })
      .catch((err) => {
        console.log(err);
      });



  };

  const [options, setOptions] = useState({
    filterType: 'dropdown',
    responsive: 'standard',
    // filter: false,
    search: false,
    download: true,
    print: false,
    selectableRows: false,
    pagination: true,
    rowsPerPageOptions: [10, 20, 50],
    onChangeRowsPerPage: getList,
    downloadOptions: {
      filterOptions: { useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true }
    }
    // textLabels: {
    //   pagination: {
    //     next: 'Next >',
    //     previous: '< Previous',
    //     rowsPerPage: 'Total items Per Page',
    //     displayRows: 'OF',
    //   },
    // },
  });
  const [tableData, setTableData] = useState({});
  const [dataInCSV, setDataInCSV] = useState('');
  useEffect(() => {
     async function fetchData() {
    await axios
      .get(`${base_url}/api/clients/export`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setDataInCSV(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    }
    fetchData();
  }
   , [tableData?.clients]);

  const token = sessionStorage.getItem('libra_admin_token');
  // const getList = async (noOfRow) => {
  //   await axios
  //     .get(`${base_url}/api/clients?page=${page}&per_page=${noOfRow ? noOfRow : 10}`, { headers: { Authorization: `Bearer ${token}` } })
  //     .then((res) => {
  //       console.log('res', res);
  //       setCount(Math.ceil(res?.data?.data?.total / rowsPerPage));
  //       setTableData(res?.data?.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    getList();
  }, [page]);

  const handleChangePage = (event, newPage) => {
    console.log(newPage, event);
    setPage(newPage);
  };

  //   console.log('tableData', tableData);

  const handleEdit = (index) => {
    const data = tableData?.clients[index];
    setEditData(data);
    setEditOpen(true);
  };
  const handleDelete = (index) => {
    const data = tableData?.clients[index];
    console.log(data);
    setEditData(data);
    setDeleteOpen(true);
  };

  const sponsorsOnclick = (value) => {
    navigate('/dashboard/clients/sponsors', {
      state: value,
    });
  };

  const columns = [
    // {
    //   name: '',
    //   label: '',
    //   options: {
    //     filter: false,
    //     customBodyRender: (value, tableMeta, update) => {
    //       let rowIndex = Number(tableMeta.rowIndex) + 1;
    //       return <span>{rowIndex}</span>;
    //     },
    //   },
    // },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const arr = tableData?.clients[dataIndex];
          return (
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => {
                sponsorsOnclick(arr);
              }}
            >
              {arr?.name}
            </span>
          );
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'programmes',
      label: 'Programmes',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const singleObject = tableData?.clients[dataIndex];

          return <span>{singleObject?.programmes?.length}</span>;
        },
      },
    },
    {
      name: 'sponsors',
      label: 'Sponsors',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value, dataIndex) => {
          const singleObject = tableData?.clients[dataIndex];

          return <span>{singleObject?.sponsors?.length}</span>;
        },
      },
    },
    {
      name: 'primary_sponsor_name',
      label: 'Primary sponsor name',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value, dataIndex) => {
          // const singleObject = exportTableData[dataIndex];
          const singleObject = tableData?.clients[dataIndex];
          // console.log(singleObject?.sponsors);
          const sponsorsArray = singleObject?.sponsors?.filter((arr) => arr?.is_default === 1);
          return <span>{sponsorsArray[0]?.name || '---'}</span>;
          // return <span>{value}</span>;
        },
      },
    },
    {
      name: 'primary_sponsor_email',
      label: 'Primary sponsor email',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value, dataIndex) => {
          const singleObject = tableData?.clients[dataIndex];
          // console.log(singleObject?.sponsors);
          const sponsorsArray = singleObject?.sponsors?.filter((arr) => arr?.is_default === 1);
          return <span>{sponsorsArray[0]?.email || '---'}</span>;
          // return <span style={{ wordBreak: 'break-all' }}>{value}</span>;
        },
      },
    },
    {
      name: 'primary_sponsor_job_title',
      label: 'Primary sponsor job title',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value, dataIndex) => {
          // const singleObject = exportTableData[dataIndex];
          const singleObject = tableData?.clients[dataIndex];
          // console.log(singleObject?.sponsors);
          const sponsorsArray = singleObject?.sponsors?.filter((arr) => arr?.is_default === 1);
          return <span>{sponsorsArray[0]?.job_title || '---'}</span>;
          // return <span>{value}</span>;
        },
      },
    },
    {
      name: '',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                startIcon={deleteIcon}
                className={classes.deleteBtn}
                onClick={() => {
                  handleDelete(dataIndex);
                }}
              />
              <Button
                startIcon={editIcon}
                className={classes.editBtn}
                onClick={() => {
                  handleEdit(dataIndex);
                }}
              />
            </Stack>
          );
        },
      },
    },
  ];

  // const options = {
  //   filterType: 'dropdown',
  //   responsive: 'stacked',
  //   // filter: false,
  //   search: false,
  //   download: true,
  //   print: false,
  //   selectableRows: false,
  //   pagination: true,
  //   rowsPerPageOptions: [10, 50, 500],
  //   onChangeRowsPerPage:  getList,
  //   downloadOptions: {
  //     filterOptions: { useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true }
  //   }
  //   // textLabels: {
  //   //   pagination: {
  //   //     next: 'Next >',
  //   //     previous: '< Previous',
  //   //     rowsPerPage: 'Total items Per Page',
  //   //     displayRows: 'OF',
  //   //   },
  //   // },
  // };
  const onchangeHandler = (value) => {
    // setSearched(value);
    setTimeout(async () => {
      await axios
        .get(`${base_url}/api/clients?search=${value}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setCount(Math.ceil(res?.data?.data?.clients?.length / rowsPerPage));
          setTableData(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 0);
  };

  let exportTableData = [];
  if (tableData?.clients) {
    // console.log("tableData?.clients===>>>>",tableData?.clients)
    exportTableData = [...tableData?.clients].map((data, i) => {
      // let clientNames =  data?.clients.map(client => client.name);
      // clientNames =  clientNames?.length > 1 ? clientNames.concat(", ") : clientNames[0];
      let email = data?.email;
      let programmes = data?.programmes?.length ? data?.programmes?.length : 0;
      let sponsors = data?.sponsors?.length ? data?.sponsors?.length : 0;
      let sponsor = data?.sponsors?.filter((arr) => arr?.is_default === false)
      return {
        name: data.name,
        email: email,
        programmes: programmes,
        sponsors: sponsors,
        primary_sponsor_name: sponsor?.name || "--",
        primary_sponsor_email: sponsor?.email || "--",
        primary_sponsor_job_title: sponsor?.job_title || "--",
        // sponsorsArray : sponsorsArray
        // profile_picture :profile_picture

      }
    });
  }
  return (
    <Page title="Clients">
      {/* {console.log("tableData?.clients", tableData?.clients)} */}
      <Container maxWidth={false}>
        <Grid container sx={{ margin: '20px 0' }}>
          <Grid item xs={3} sm={6}>
            <Typography variant="h4" gutterBottom>
              Clients
            </Typography>
          </Grid>
          <Grid item xs={0} sm={1} xl={3} />
          <Grid item xs={9} sm={5} xl={3}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              {/* <Button
                variant="outlined"
                sx={{ minHeight: '40px', color: '#344054', borderColor: '#D0D5DD' }}
                startIcon={svgIcon}
              >
                Import
              </Button> */}
              {/* <Button
                component="a"
                href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
                download="filename.csv"
                variant="outlined"
                sx={{ minHeight: '40px', color: '#344054', borderColor: '#D0D5DD', marginRight: '15px' }}
                startIcon={
                  <Icon>
                    <img alt="edit" src={cloud2} />
                  </Icon>
                }
              >
                Export
              </Button> */}
              <Button
                variant="contained"
                sx={{ minHeight: '40px' }}
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => {
                  if (role === 'admin') {
                    setAddOpenAdmin(true);
                  } else {
                    setAddOpen(true);
                  }
                }}
              >
                Add New
              </Button>
            </div>
          </Grid>
        </Grid>

        <Card sx={{ marginBottom: '24px' }}>
          <Box className={classes.tableManagement}>
            <Stack direction="row" alignItems="start" justifyContent="center" marginTop="24px">
              {/* <Typography variant="h4" sx={{ padding: '10px' }}>
              All companies{' '}
            </Typography>
            <Typography
              sx={{
                color: '#6941C6',
                backgroundColor: '#F9F5FF',
                borderRadius: '16px',
                width: '100px',
                textAlign: 'center',
              }}
            >
              {tableData?.length} records
            </Typography> */}
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search', placeholder: 'Search table' }}
                  onChange={(e) => {
                    onchangeHandler(e.target.value);
                  }}
                />
              </Search>
            </Stack>
            {/* <Divider /> */}
            {/* {console.log("options ==>", options)} */}
            <Scrollbar>
              <MUIDataTable
                title={
                  <h6
                    style={{
                      fontFamily: 'Public Sans,sans-serif',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      fontSize: '18px',
                      lineHeight: '28px',
                      color: '#101828',
                      textAlign: 'start',
                      display: 'flex',
                    }}
                  >
                    All clients
                    <span
                      style={{
                        fontFamily: 'Public Sans,sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#6941C6',
                        background: '#F9F5FF',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '2px 8px',
                        width: 'fit-content',
                        marginLeft: '1rem',
                      }}
                    >
                      {tableData?.total || tableData?.clients?.length} records
                    </span>
                  </h6>
                }
                data={exportTableData}
                columns={columns}
                options={options}
              />
            </Scrollbar>
            <Box className={classes.pagination}>
              <Pagination
                count={count}
                onChange={handleChangePage}
                page={page}
                shape="rounded"
                color="primary"
                defaultPage={1}
              />
            </Box>
          </Box>
        </Card>
      </Container>
      <AdminAddDialogue setAddOpen={setAddOpenAdmin} addOpen={addOpenAdmin} getList={getList} />
      <AddDialogue setAddOpen={setAddOpen} addOpen={addOpen} getList={getList} />
      <EditDialogue setEditOpen={setEditOpen} editOpen={editOpen} getList={getList} editData={editData} />
      <DeleteDialogue setDeleteOpen={setDeleteOpen} deleteOpen={deleteOpen} getList={getList} editData={editData} />
    </Page>
  );
}
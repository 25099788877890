// ----------------------------------------------------------------------

export default function Pagination(theme) {
    return {
        MuiTablePagination: {
        styleOverrides: {
           
      
        }
      }
    };
  }
  
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';

import axios from 'axios';
import { base_url } from 'src/common';
import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDropzone } from 'react-dropzone';
import uploadIcon from '../../../../assets/images/uploadIcon.png';
import { ErrorMessage } from 'formik';

const useStyle = makeStyles((theme) => ({
  modalMain: {
    width: '530px',

    // height: '530px',

    // backgroundColor: theme.palette.primary.dark,

    border: '1px solid rgba(239, 239, 239, 0.1)',

    borderRadius: '10px',

    borderColor: '#fff',
    padding: '15px 15px',
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '15px 15px',
    },
    '& .heading': {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
    },
  },
  dropzone: {
    textAlign: 'center',
    padding: '20px',
    width: '100%',
    backgroundColor: '#FFFFFF',
    color: '#bdbdbd',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px dashed #EAECF0',
    borderRadius: '8px',
    '& img': {
      width: '94px',
      height: '89px',
      margin: '8px',
    },
  },
}));

export default function ImportPartcipant(props) {
  const { setimportOpen, importOpen, getList } = props;
  const { role } = JSON.parse(sessionStorage.getItem('mentoring_user')) || '';
  const company_id = sessionStorage.getItem('Company_id') || '';
  const formData = new FormData();
  const classes = useStyle();
  const [loadingState, setloadingState] = useState(false);
  const [allCompanies, setallCompanies] = useState([]);
  const [base64File, setBase64File] = useState();

  const token = sessionStorage.getItem('libra_admin_token');

  const getAllCompanies = async () => {
    await axios
      .get(`${base_url}/api/companies`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallCompanies(res?.data?.data?.companies);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllCompanies();
  }, []);

  const AdminLoginSchema = Yup.object().shape({
    file: Yup.array().min(1, 'CSV file is required').required('CSV file is required').nullable(),
    company_id: Yup.string().required('Coaching Provider name is required'),
  });
  const UserLoginSchema = Yup.object().shape({
    file: Yup.array().min(1, 'CSV file is required').required('CSV file is required').nullable(),
  });
  let initData = {};
  if (role === 'admin') {
    initData.file = null;
    initData.company_id = '';
  } else {
    initData.file = null;
  }

  const formik = useFormik({
    initialValues: initData,
    validationSchema: role === 'admin' ? AdminLoginSchema : UserLoginSchema,
    onSubmit: (values) => {
      setloadingState(true);

      if (role === 'admin') {
        formData.append('company_id', values?.company_id);
      } else {
        formData.append('company_id', company_id);
      }

      formData.append('file', values?.file[0]);
      const file = values?.file[0];

      if(file){
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64URL = reader.result;
          setBase64File(base64URL)
        }

        reader.readAsDataURL(file);
      }
      console.log("file====================>>>>>>>>>>>>>>>",base64File)
      // let base64File;
      // getBase64(values?.file).then(result=> {
      //   setBase64File(result)
      // })



      axios
        .post(`${base_url}/api/participants/import`, formData, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (res?.data?.status) {
            toast.success(res?.data?.data?.message);
            setloadingState(false);
            setimportOpen(false);
            getList();
          }
        })
        .catch((err) => {
          setloadingState(false);

          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            toast.error('Server Error');
          }
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleBlur, setFieldValue, resetForm, handleSubmit, getFieldProps } =
    formik;

  const clearData = () => {
    resetForm();
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.Custom', '.csv'],
    },
    multiple: false,
  });
  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  useEffect(() => {
    setFieldValue('file', acceptedFiles);
  }, [acceptedFiles]);
  return (
    <div>
      <Dialog
        open={importOpen}
        // onClose={() => {
        //   setimportOpen(false);
        //   clearData();
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ classes: { root: classes.modalMain } }}
      >
        <DialogTitle id="alert-dialog-title" className="heading">
          {'Import Participants'}
        </DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ marginTop: '0.5rem' }}>
              <Stack spacing={3}>
                {role === 'admin' ? (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allCompanies}
                    onChange={(e, value) => {
                      setFieldValue('company_id', value?.id);
                    }}
                    onOpen={handleBlur}
                    getOptionLabel={(allClients) => allClients.name || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.company_id && errors.company_id)}
                        fullWidth
                        helperText={touched.company_id && errors.company_id}
                        name="company_id"
                        variant="outlined"
                        label="Coaching Provider"
                      />
                    )}
                    fullWidth={true}
                  />
                ) : null}

                <section className="container">
                  <Box>
                    <div {...getRootProps({ className: `${classes.dropzone}` })}>
                      <input {...getInputProps()} />
                      <p
                        style={{
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '16px',
                          color: '#959595',
                        }}
                      >
                        File types supported: CSV
                      </p>
                      <img src={uploadIcon} alt="upload" />
                      <p
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '20px',
                          color: '#667085',
                        }}
                      >
                        <span
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#6941C6',
                          }}
                        >
                          Click to upload{' '}
                        </span>
                        or drag and drop
                      </p>
                    </div>
                  </Box>

                  <Box
                    sx={{
                      lineHeight: '1.5',
                      fontSize: '0.75rem',
                      fontFamily: 'Public Sans,sans-serif',
                      fontWeight: '400',
                      textAlign: 'left',
                      marginTop: '4px',
                      marginRight: '14px',
                      marginBottom: '0',
                      marginLeft: '14px',
                      color: '#FF4842',
                    }}
                  >
                    {/* {errors.supporting_documents} */}
                    <ErrorMessage name="file" />
                  </Box>

                  <aside style={{ paddingLeft: '1rem' }}>
                    <h4>Accepted file:</h4>
                    <ul>{acceptedFileItems}</ul>
                    <h4>Rejected file:</h4>
                    <ul style={{ color: 'red' }}>{fileRejectionItems}</ul>
                  </aside>
                </section>
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mt: 3 }}>
                <Button
                  onClick={() => {
                    setimportOpen(false);
                    clearData();
                  }}
                  variant="outlined"
                  sx={{ marginRight: '1rem' }}
                  size="large"
                >
                  Cancel
                </Button>
                <LoadingButton size="large" type="submit" variant="contained" loading={loadingState}>
                  Import
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
      <Toaster />
    </div>
  );
}

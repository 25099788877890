import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import Iconify from 'src/components/Iconify';
import axios from 'axios';
import { base_url } from 'src/common';
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const useStyle = makeStyles((theme) => ({
  modalMain: {
    width: '530px',

    // height: '530px',

    // backgroundColor: theme.palette.primary.dark,

    border: '1px solid rgba(239, 239, 239, 0.1)',

    borderRadius: '10px',

    borderColor: '#fff',
    padding: '15px 15px',
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '15px 15px',
    },
    '& .heading': {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
    },
  },
  dialogueData: {
    padding: '0px !important',
    paddingLeft: '15px !important',
    paddingRight: '15px !important',
  },
}));

export default function DeleteDialogue(props) {
  const { setDeleteOpen, deleteOpen, getList, editData } = props;
  const classes = useStyle();
  const [loadingState, setloadingState] = useState(false);

  const token = sessionStorage.getItem('libra_admin_token');

  const handleDelete = async () => {
    setloadingState(true);

    // navigate('/dashboard', { replace: true });
    await axios
      .delete(`${base_url}/api/clients/${editData?.id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setloadingState(false);
        console.log(res);
        if (res?.data?.status === true) {
          setDeleteOpen(false);
          toast.success('Client deleted successfully');
          getList();
        }
      })
      .catch((err) => {
        setloadingState(false);
        console.log(err?.response);

        if (err?.response?.data?.error) {
          toast.error(err?.response?.data?.error);
        } else {
          toast.error('Server Error');
        }
      });
  };
  return (
    <div>
      <Dialog
        open={deleteOpen}
        onClose={() => {
          setDeleteOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ classes: { root: classes.modalMain } }}
      >
        <DialogTitle id="alert-dialog-title" className="heading">
          {'Delete Client'}
        </DialogTitle>
        <DialogContent className={classes.dialogueData}>
          <Typography variant="body1">Are you sure you want to delete this client</Typography>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mt: 3 }}>
            <Button
              onClick={() => {
                setDeleteOpen(false);
              }}
              variant="outlined"
              sx={{ marginRight: '1rem' }}
              size="large"
            >
              Cancel
            </Button>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              sx={{ background: 'red' }}
              loading={loadingState}
              onClick={handleDelete}
            >
              Delete
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
      <Toaster />
    </div>
  );
}

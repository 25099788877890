import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import Iconify from 'src/components/Iconify';
import axios from 'axios';
import { base_url } from 'src/common';
import { Autocomplete, Box, Divider, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FileUploader } from 'react-drag-drop-files';

const useStyle = makeStyles((theme) => ({
  modalMain: {
    width: '530px',

    // height: '530px',

    // backgroundColor: theme.palette.primary.dark,

    border: '1px solid rgba(239, 239, 239, 0.1)',

    borderRadius: '10px',

    borderColor: '#fff',
    padding: '15px 15px',
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '15px 15px',
    },
    '& .heading': {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
    },
  },
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '38px',
    color: '#101828',
    paddingBottom: '32px',
  },
  management: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingTop: '32px',
    paddingBottom: '4px',
  },
  body1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: '3rem',
    '& img': {
      width: '100%',
    },
    '& .imgBox': {
      width: '100px',
      height: '100px',
      borderRadius: '50%',
    },
    '& .uploadBox': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& label': {
        border: '0px !important',
        flexDirection: 'column !important',
        height: 'unset',
      },
      '& div': {
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        '& span': {
          // '&:last-child': {
          //   color: 'yellow !important',
          // },
        },
      },
    },
  },
  ParticipantDetails: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingBottom: '4px',
  },
  body2text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
    marginTop: '0px !important',
  },
}));

export default function AdminAddParticipant(props) {
  const navigate = useNavigate();
  const classes = useStyle();
  const [loadingState, setloadingState] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [allClients, setallClients] = useState([]);
  const [allGroups, setallGroups] = useState([]);
  const [allCoaches, setallCoaches] = useState([]);
  const [allProgrammes, setallProgrammes] = useState([]);
  const [allCompanies, setallCompanies] = useState([]);

  const [companyID, setcompanyID] = useState(null);
  const [file, setFile] = useState(null);
  const [Paths, setPaths] = useState(null);
  const token = sessionStorage.getItem('libra_admin_token');
  const getAllCompanies = async () => {
    await axios
      .get(`${base_url}/api/companies`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallCompanies(res?.data?.data?.companies);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const getClients = async () => {
  //   await axios
  //     .get(`${base_url}/api/clients`, { headers: { Authorization: `Bearer ${token}` } })
  //     .then((res) => {
  //       setallClients(res?.data?.data?.clients);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const getGroups = async () => {
    await axios
      .get(`${base_url}/api/groups`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallGroups(res?.data?.data?.groups);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const getCoaches = async () => {
  //   await axios
  //     .get(`${base_url}/api/coaches`, { headers: { Authorization: `Bearer ${token}` } })
  //     .then((res) => {
  //       setallCoaches(res?.data?.data?.coaches);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // const getProgrammes = async () => {
  //   await axios
  //     .get(`${base_url}/api/programmes`, { headers: { Authorization: `Bearer ${token}` } })
  //     .then((res) => {
  //       setallProgrammes(res?.data?.data?.programmes);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  useEffect(() => {
    getAllCompanies();
    // getClients();
    getGroups();
    // getCoaches();
    // getProgrammes();
  }, []);
  const fileTypes = ['JPEG', 'PNG', 'JPG'];
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Enter proper email address').required('Email is required'),
    company_id: Yup.string().required('Coaching Provider name is required'),
    password: Yup.string().required('Password is required'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
    client_id: Yup.string().required('Client name is required'),
    // group_id: Yup.string().required('Group name is required'),
    coach_id: Yup.string().required('Coach name is required'),
    programme_ids: Yup.array()
      .required('Programme name is required')
      .min(1, 'Programme field must have at least 1 item'),
    // e_programme_id: Yup.string().required('Programme id is required'),
    // e_client_id: Yup.string().required('Client id is required'),
    no_of_sessions: Yup.number()
      .min(0, 'No. of sessions must be greater than or equal to 0')
      .required('No. of sessions is required'),
    rank: Yup.string().required('Rank is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      client_id: '',
      group_id: '',
      company_id: '',
      profile_picture: null,
      coach_id: '',
      programme_ids: [],
      e_programme_id: '',
      e_client_id: '',
      no_of_sessions: '',
      rank: '',
      city: '',
      country: '',
      region: '',
      management_area: '',
      management_region: '',
      service_line: '',
      sub_service_line: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setloadingState(true);

        
      const formData = new FormData();
      
      // Append all form values to FormData
      Object.keys(values).forEach(key => {
        if (key === 'programme_ids') {
          // Convert programme_ids array to comma-separated string
          formData.append('programme_ids', values.programme_ids.map(item => item.id).join(','));
        } else {
          formData.append(key, values[key]);
        }
      });
      axios
        .post(`${base_url}/api/participants`, formData, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setloadingState(false);
          // console.log('res', res);
          if (res?.data?.status === true) {
            toast.success('participant added successfully');
            clearData();
            setTimeout(() => {
              clearData();
              navigate('/dashboard/participants', { replace: true });
            }, 2000);
            // getList();
          }
        })
        .catch((err) => {
          setloadingState(false);

          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            toast.success('participant added successfully');
            setTimeout(() => {
              clearData();
              navigate('/dashboard/participants', { replace: true });
            }, 2000);
            // toast.error('Server Error');
          }
        });
    },
  });

  const { errors, touched, handleBlur, setFieldValue, resetForm, handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };
  const clearData = () => {
    resetForm();
  };
  useEffect(() => {
    const selectedCompanyData = async () => {
      await axios
        .get(`${base_url}/api/companies/${companyID}/users`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setallClients(res?.data?.data?.clients);
          setallCoaches(res?.data?.data?.coaches);
        })
        .catch((err) => {
          console.log(err);
        });
      await axios
        .get(`${base_url}/api/companies/${companyID}/programmes`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setallProgrammes(res?.data?.data?.programmes);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    selectedCompanyData();
  }, [companyID]);
  const handleChange = (file) => {
    setFile(file);
    setPaths(URL.createObjectURL(file));
    setFieldValue('profile_picture', file);
  };
  return (
    <div>
      <Typography variant="h1" className={classes.heading}>
        Participants
      </Typography>
      <Divider />
      <Typography variant="body1" className={classes.management}>
        Management
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        Manage which coaches can manage this participant and which group they belong to.
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
              }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allCompanies}
                onChange={(e, value) => {
                  formik.resetForm();
                  setFieldValue('company_id', value?.id);
                  setcompanyID(value?.id);
                }}
                style={{ marginBottom: '25px' }}
                onOpen={handleBlur}
                getOptionLabel={(options) => options.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(touched.company_id && errors.company_id)}
                    fullWidth
                    helperText={touched.company_id && errors.company_id}
                    name="company_id"
                    variant="outlined"
                    label="Coaching Provider"
                  />
                )}
                fullWidth={true}
              />
              <Stack direction="row" spacing={5}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allClients}
                  onChange={(e, value) => {
                    setFieldValue('client_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  // renderOption={(option) => <>{option.name}</>}
                  getOptionLabel={(allClients) => allClients.name}
                  // includeInputInList
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.client_id && errors.client_id)}
                      fullWidth
                      helperText={touched.client_id && errors.client_id}
                      name="client_id"
                      variant="outlined"
                      label="Client"
                    />
                  )}
                  fullWidth={true}
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allCoaches}
                  onChange={(e, value) => {
                    setFieldValue('coach_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.coach_id && errors.coach_id)}
                      fullWidth
                      helperText={touched.coach_id && errors.coach_id}
                      name="coach_id"
                      variant="outlined"
                      label="Coach"
                    />
                  )}
                  fullWidth={true}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allGroups}
                  onChange={(e, value) => {
                    setFieldValue('group_id', value?.id);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // error={Boolean(touched.group_id && errors.group_id)}
                      // fullWidth
                      // helperText={touched.group_id && errors.group_id}
                      name="group_id"
                      variant="outlined"
                      label="Group Name"
                    />
                  )}
                  fullWidth={true}
                />
                <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  options={allProgrammes}
                  onChange={(e, value) => {
                    setFieldValue('programme_ids', value);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.programme_ids && errors.programme_ids)}
                      fullWidth
                      helperText={touched.programme_ids && errors.programme_ids}
                      name="programme_ids"
                      variant="outlined"
                      label="Programme Names"
                    />
                  )}
                  fullWidth={true}
                />
              </Stack>
            </Stack>
            
            <Typography variant="body1" className={classes.ParticipantDetails}>
              Participant details
            </Typography>
            <Typography variant="subtitle1" className={classes.body2text}>
              Add details for the participant.
            </Typography>
            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
                marginTop: '0px !important',
              }}
            >
              <Box className={classes.imageContainer}>
                <Box >
                  <img className="imgBox"
                    src={Paths ? `${Paths}` : '/static/icons/Avatar.png'}
                    // src="http://ec2-54-237-56-13.compute-1.amazonaws.com/public/storage/users/profile_images/fBm0o7vi4X6pSGwIN9TyMJm3paANqjdMw2y9cd33.png"
                    alt="coach"
                  />
                </Box>
                <Box className="uploadBox">
                  <Box sx={{ textAlign: 'center' }}>
                    <FileUploader handleChange={handleChange} types={fileTypes} />
                    <p>{file ? `Image name: ${file?.name}` : 'no image uploaded yet'}</p>
                  </Box>
                </Box>
              </Box>
              <Stack direction="row" spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Full name*"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email*"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  label="Password*"
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  label="Confirm Password*"
                  {...getFieldProps('password_confirmation')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowConfirmPassword} edge="end">
                          <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                  helperText={touched.password_confirmation && errors.password_confirmation}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Programme ID"
                  {...getFieldProps('e_programme_id')}
                  // error={Boolean(touched.e_programme_id && errors.e_programme_id)}
                  // helperText={touched.e_programme_id && errors.e_programme_id}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Client ID"
                  {...getFieldProps('e_client_id')}
                  // error={Boolean(touched.e_client_id && errors.e_client_id)}
                  // helperText={touched.e_client_id && errors.e_client_id}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Rank*"
                  {...getFieldProps('rank')}
                  error={Boolean(touched.rank && errors.rank)}
                  helperText={touched.rank && errors.rank}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="number"
                  label="No. of sessions*"
                  {...getFieldProps('no_of_sessions')}
                  error={Boolean(touched.no_of_sessions && errors.no_of_sessions)}
                  helperText={touched.no_of_sessions && errors.no_of_sessions}
                  inputProps={{
                    min: 0,
                  }}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Country"
                  {...getFieldProps('country')}
                  error={Boolean(touched.country && errors.country)}
                  helperText={touched.country && errors.country}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="City"
                  {...getFieldProps('city')}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Region"
                  {...getFieldProps('region')}
                  error={Boolean(touched.region && errors.region)}
                  helperText={touched.region && errors.region}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Mgt. Area"
                  {...getFieldProps('management_area')}
                  error={Boolean(touched.management_area && errors.management_area)}
                  helperText={touched.management_area && errors.management_area}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Mgt. Region"
                  {...getFieldProps('management_region')}
                  error={Boolean(touched.management_region && errors.management_region)}
                  helperText={touched.management_region && errors.management_region}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Service line"
                  {...getFieldProps('service_line')}
                  error={Boolean(touched.service_line && errors.service_line)}
                  helperText={touched.service_line && errors.service_line}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Sub-service line"
                  {...getFieldProps('sub_service_line')}
                  error={Boolean(touched.sub_service_line && errors.sub_service_line)}
                  helperText={touched.sub_service_line && errors.sub_service_line}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mt: 3 }}>
            <Button
              onClick={() => {
                navigate('/dashboard/participants', { replace: true });
                clearData();
              }}
              variant="outlined"
              sx={{ marginRight: '1rem' }}
              size="large"
            >
              Cancel
            </Button>
            <LoadingButton size="large" type="submit" variant="contained" loading={loadingState}>
              Save
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <Toaster />
    </div>
  );
}

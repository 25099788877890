import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { base_url } from 'src/common';
import { Autocomplete, Box, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { useDropzone } from 'react-dropzone';
import file from '../../../../assets/images/file.png';
import MaterialDropZone from './materialDropZone';
import ItineraryDropZone from './itineraryDropZone';

import fileIcon from '../../../../assets/images/file.svg';
import binIcon from '../../../../assets/images/delete.svg';
import { width } from '@mui/system';
import LinearProgress from '@material-ui/core/LinearProgress';
import { indexOf } from 'lodash-es';

const useStyle = makeStyles((theme) => ({
  modalMain: {
    width: '530px',

    // height: '530px',

    // backgroundColor: theme.palette.primary.dark,

    border: '1px solid rgba(239, 239, 239, 0.1)',

    borderRadius: '10px',

    borderColor: '#fff',
    padding: '15px 15px',
    '& .css-ypiqx9-MuiDialogContent-root': {
      padding: '15px 15px',
    },
    '& .heading': {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
    },
  },
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '38px',
    color: '#101828',
    paddingBottom: '32px',
  },
  management: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingTop: '32px',
    paddingBottom: '4px',
  },
  body1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
  },
  ParticipantDetails: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
    paddingBottom: '4px',
  },
  body2text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    paddingBottom: '40px',
    marginTop: '0px !important',
  },
  wrapper_class: {
    padding: '1rem',
    border: '1px solid #ccc',
    marginBottom: '1.5rem',
  },
  editor_class: {
    backgroundColor: 'lightgray',
    padding: '1rem',
    border: '1px solid #ccc',
  },
  toolbar_class: {
    border: '1px solid #ccc',
  },
  dropzone: {
    textSlign: 'center',
    padding: '20px',
    width: '512px',
    height: '126',
    backgroundColor: '#FFFFFF',
    color: '#bdbdbd',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px dashed #EAECF0',
    borderRadius: '8px',
    '& img': {
      width: '50px',
    },
  },
}));

export default function EditProgramme(props) {
  const navigate = useNavigate();
  const classes = useStyle();
  const location = useLocation();
  const formData = new FormData();
  const [loadingState, setloadingState] = useState(false);
  const [dateValue, setDateValue] = React.useState(null);
  const [allClients, setallClients] = useState([]);
  const [allGroups, setallGroups] = useState([]);
  const [allCoaches, setallCoaches] = useState([]);
  const [ItineraryeditorState, setItineraryEditorState] = useState(() => EditorState.createEmpty());
  const [materialeditorState, setmaterialEditorState] = useState(() => EditorState.createEmpty());
  const [itineraryFiles, setItineraryFiles] = useState([]);
  const [materialFiles, setMaterialFiles] = useState([]);
  const [uploadOrDownloadCount, setUploadOrDownloadCount] = useState([]);
  const [itinearyUploadFileCount, setItinearyUploadFileCount] = useState([]);

  useEffect(() => {
    setItineraryEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(location?.state?.itinerary || ''))
      )
    );
    setmaterialEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(location?.state?.program_material || ''))
      )
    );
    let temp = { ...values };
    temp.itinerary = location?.state?.itinerary || '';
    temp.program_material = location?.state?.program_material || '';
    temp.material_documents = location?.state?.material_documents || [];
    temp.itinerary_documents = location?.state?.itinerary_documents || [];
    setValues({ ...temp });
  }, []);
  const [groupval, setgroupVal] = useState({});
  const [clientval, setclientVal] = useState([]);
  const [coachval, setcoachVal] = useState([]);

  const token = sessionStorage.getItem('libra_admin_token');
  const getClients = async () => {
    await axios
      .get(`${base_url}/api/clients`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallClients(res?.data?.data?.clients);
        setclientVal(location?.state?.clients);
        setFieldValue('client_ids', location?.state?.clients);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getGroups = async () => {
    await axios
      .get(`${base_url}/api/groups`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallGroups(res?.data?.data?.groups);
        const groups = res?.data?.data?.groups?.find((item) => item.id === location?.state?.group_id);
        setgroupVal(groups);
        setFieldValue('group_id', groups?.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCoaches = async () => {
    await axios
      .get(`${base_url}/api/coaches`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setallCoaches(res?.data?.data?.coaches);
        setcoachVal(location?.state?.coaches);
        setFieldValue('coach_ids', location?.state?.coaches);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const date = new Date(`${location.state.cohort_year}-01-01`);

  useEffect(() => {
    getClients();
    getGroups();
    getCoaches();
    resetTimerForItinerary();
    setDateValue(date);
    setFieldValue('name', location?.state?.name);
    setFieldValue('cohort_year', location?.state?.cohort_year);
  }, []);

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    client_ids: Yup.array().required('Client name is required').min(1, 'Client field must have atleast 1 item'),
    group_id: Yup.string().required('Group name is required'),
    coach_ids: Yup.array().required('Coach name is required').min(1, 'Coach field must have atleast 1 item'),
    cohort_year: Yup.string().required('Cohort year is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      client_ids: [],
      group_id: '',
      coach_ids: [],
      cohort_year: '',
      itinerary: '',
      program_material: '',
      material_documents: [],
      itinerary_documents: [],
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setloadingState(true);
      const dupObj = { ...values };
      const ids = dupObj.coach_ids.map((item) => item.id);
      dupObj.coach_ids = ids.toString();
      const clientids = dupObj.client_ids.map((item) => item.id);
      dupObj.client_ids = clientids.toString();
      formData.append('_method', 'put');
      formData.append('name', dupObj?.name);
      formData.append('client_ids', dupObj?.client_ids);
      formData.append('coach_ids', dupObj?.coach_ids);
      formData.append('cohort_year', dupObj?.cohort_year);
      formData.append('group_id', dupObj?.group_id);
      formData.append('itinerary', dupObj?.itinerary);
      formData.append('program_material', dupObj?.program_material);
      if (dupObj.itinerary_documents.length > 0) {
        for (let i = 0; i < dupObj.itinerary_documents.length; i++) {
          if (!dupObj?.itinerary_documents[i]?.path) {
            formData.append('itinerary_documents[]', dupObj?.itinerary_documents[i].file);
          }
        }
      }

      if (dupObj.material_documents.length > 0) {
        for (let i = 0; i < dupObj.material_documents.length; i++) {
          if (!dupObj?.material_documents[i]?.path) {
            formData.append('material_documents[]', dupObj?.material_documents[i].file);
          }
        }
      }

      axios
        .post(`${base_url}/api/programmes/${location?.state?.id}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setloadingState(false);
          console.log(res);
          if (res?.data?.status === true) {
            toast.success('Updated successfully');
            clearData();
            setTimeout(() => {
              clearData();
              navigate('/dashboard/programmes', { replace: true });
            }, 2000);
          }
        })
        .catch((err) => {
          setloadingState(false);

          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            toast.error('Server Error');
          }
        });
    },
  });

  const { values, errors, touched, handleBlur, setValues, setFieldValue, resetForm, handleSubmit, getFieldProps } =
    formik;

  const clearData = () => {
    resetForm();
  };
  function onEditorStateChange(state) {
    setItineraryEditorState(state);
    ItineraryconvertContentToHTML();
  }
  const ItineraryconvertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return (
            <a href={entity.data.url} target={entity?.data?.targetOption}>
              {originalText}
            </a>
          );
        }
        return originalText;
      },
    })(ItineraryeditorState.getCurrentContent());
    setFieldValue('itinerary', currentContentAsHTML);
  };
  function onmaterialEditorStateChange(state) {
    setmaterialEditorState(state);
    materialconvertContentToHTML();
  }
  const materialconvertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return (
            <a href={entity.data.url} target={entity?.data?.targetOption}>
              {originalText}
            </a>
          );
        }
        return originalText;
      },
    })(materialeditorState.getCurrentContent());
    setFieldValue('program_material', currentContentAsHTML);
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.Custom', '.jpeg', '.png', '.jpg', '.docx', '.pdf'],
      //   'application/pdf': ['.pdf'],
      //   'application/docs': ['.docx'],
    },
    // accept: 'image/png, image/jpeg,image/jpg,application/pdf,application/docs',
  });
  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  useEffect(() => {
    let oldData = values.itinerary_documents?.length ? [...values.itinerary_documents] : [...location?.state?.itinerary_documents]
    setFieldValue('itinerary_documents', [...oldData, ...acceptedFiles]);
  }, [acceptedFiles]);

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const deleteDocHandler = (key, id) => {
    const file = values[key].find((f) => f.id === id);
    if (file?.path && file?.programme_id) {
      axios
        .post(`${base_url}/api/programmes/deletedoc/${id}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          let temp = { ...values };
          temp[key] = temp[key].filter((file) => file.id !== id);
          setValues({ ...temp });
        })
        .catch((err) => {
          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            toast.error('Server Error');
          }
        });
    } else {
      if (key === "material_documents") {
        let tempFile = [...materialFiles];
        tempFile = tempFile.filter((f) => f.id !== id);
        setMaterialFiles([...tempFile]);
      }
      let temp = { ...values };
      temp[key] = temp[key].filter((file) => file.id !== id);
      setValues({ ...temp });

    }
  };

  const handleEditorChange = (newEditorState) => {
    setItineraryEditorState(newEditorState);
  };

  const handlePastedText = (text, html, ItineraryEditorState) => {
    const contentState = ContentState.createFromText(text);
    const newEditorState = EditorState.push(
      ItineraryEditorState,
      contentState,
      "insert-characters"
    );
    // setItineraryEditorState(state);
    setItineraryEditorState(newEditorState);
    ItineraryconvertContentToHTML();
  };

  const resetTimerForItinerary = () => {
    setItinearyUploadFileCount([...itinearyUploadFileCount, 0]);
    // showTimerItinearyFile();
  }

  const showTimerItinearyFile = () => {
    const timer = setInterval(() => {
      setItinearyUploadFileCount(
        (beforeValue) => {
          const lastIndex = formik.values.itinerary_documents.length;
          if (!beforeValue[lastIndex] || beforeValue[lastIndex] < 100) {
            beforeValue[lastIndex] = (beforeValue[lastIndex] ? beforeValue[lastIndex] : 0) + 5;
          } else {
            beforeValue[lastIndex] = 100;
          }
          if (beforeValue[lastIndex] >= 100) {
            clearInterval(timer);
          }
          return [...beforeValue]
        });
    }, 500)
  }

  const showTimer = () => {
    const timer = setInterval(() => {
      setUploadOrDownloadCount(
        (beforeValue) => {
          const lastIndex = formik.values.material_documents.length;

          if (!beforeValue[lastIndex] || beforeValue[lastIndex] < 100) {
            beforeValue[lastIndex] = (beforeValue[lastIndex] ? beforeValue[lastIndex] : 0) + 5;
          } else {
            beforeValue[lastIndex] = 100;
          }
          if (beforeValue[lastIndex] >= 100) {
            clearInterval(timer);
          }
          return [...beforeValue]
        });
    }, 500)
  }

  /*  useEffect(()=>{ 
     return( showTimer())
   },[formik.values.material_documents.length]) */

  return (
    <div>
      <Typography variant="h1" className={classes.heading}>
        {location?.state?.name}
      </Typography>
      <Divider />
      <Typography variant="body1" className={classes.management}>
        Management
      </Typography>
      <Typography variant="body1" className={classes.body1}>
        Manage which client, coach and participant can view this programme.
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
              }}
            >
              <Stack direction="row" spacing={5}>
                <Autocomplete
                  disablePortal
                  value={clientval}
                  multiple
                  id="combo-box-demo"
                  options={allClients}
                  onChange={(e, value) => {
                    setFieldValue('client_ids', value);
                    setclientVal(value);
                  }}
                  onOpen={handleBlur}
                  // renderOption={(option) => <>{option.name}</>}
                  getOptionLabel={(allClients) => allClients?.name || ''}
                  // includeInputInList
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.client_ids && errors.client_ids)}
                      fullWidth
                      helperText={touched.client_ids && errors.client_ids}
                      name="client_ids"
                      variant="outlined"
                      label="Client"
                    />
                  )}
                  fullWidth={true}
                />
                <Autocomplete
                  value={coachval}
                  multiple
                  disablePortal
                  id="tags-standard"
                  options={allCoaches}
                  onChange={(e, value) => {
                    setFieldValue('coach_ids', value);
                    setcoachVal(value);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options?.name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.coach_ids && errors.coach_ids)}
                      fullWidth
                      helperText={touched.coach_ids && errors.coach_ids}
                      name="coach_ids"
                      variant="outlined"
                      label="Coaches"
                    />
                  )}
                  fullWidth={true}
                />
              </Stack>
              <Stack direction="row" sx={{ marginTop: '25px' }} spacing={5}>
                <Autocomplete
                  value={groupval}
                  disablePortal
                  id="combo-box-demo"
                  options={allGroups}
                  onChange={(e, value) => {
                    setFieldValue('group_id', value?.id);
                    setgroupVal(value);
                  }}
                  onOpen={handleBlur}
                  getOptionLabel={(options) => options.name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.group_id && errors.group_id)}
                      fullWidth
                      helperText={touched.group_id && errors.group_id}
                      name="group_id"
                      variant="outlined"
                      label="Participant group"
                    />
                  )}
                  fullWidth={true}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3} style={{ width: '100%' }}>
                    <DatePicker
                      views={['year']}
                      label="Cohort year"
                      disablePast
                      value={dateValue}
                      onChange={(value) => {
                        setDateValue(value);
                        setFieldValue('cohort_year', moment(value).format('YYYY'));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.cohort_year && errors.cohort_year)}
                          fullWidth
                          helperText={touched.cohort_year && errors.cohort_year}
                          name="cohort_year"
                          variant="outlined"
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Stack>
            </Stack>
            <Typography variant="body1" className={classes.ParticipantDetails}>
              Programme details
            </Typography>
            <Typography variant="subtitle1" className={classes.body2text}>
              Add details for the programme.
            </Typography>
            <Stack
              direction="column"
              sx={{
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px',
                padding: '25px',
                width: '100%',
                marginBottom: '32px',
                marginTop: '0px !important',
              }}
            >
              <Stack direction="row" spacing={5}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Programme name*"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Stack>
              <Grid container style={{ marginBottom: '50px', marginTop: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                      paddingBottom: '5px',
                    }}
                  >
                    Itinerary
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#667085',
                    }}
                  >
                    Add the programme Itinerary.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Editor
                    stripPastedStyles={false}
                    defaultEditorState={ItineraryeditorState}
                    editorState={ItineraryeditorState}
                    wrapperClassName={classes.wrapper_class}
                    editorClassName={classes.editor_class}
                    toolbarClassName={classes.toolbar_class}
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                      options: ['inline', 'blockType', 'list', 'link'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                      },
                    }}
                  />
                  If you not want to change files than dont select anything.
                  <section className="container">
                    {/* <Box>
                      <div style={{ width: 'auto' }} {...getRootProps({ className: `${classes.dropzone}` })}>
                        <input {...getInputProps()} />
                        {/* {formik?.values?.itinerary_documents ? formik?.values?.itinerary_documents : 
                          <>
                            <img src="/static/icons/Featuredicon.png" />
                            <p
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#667085',
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  color: '#6941C6',
                                }}
                              >
                                Click to upload
                              </span>
                              or drag and drop
                            </p>
                            <p
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '12px',
                                lineHeight: '18px',
                                textAlign: 'center',
                                color: '#667085',
                              }}
                            >

                              PDF, .docx or JPG,JPEG and PNG(max. 5mb)
                            </p>
                          </>
                          {/* } 
                      </div>
                    </Box> */}

                    <ItineraryDropZone
                      classes={classes}
                      values={formik.values.itinerary_documents}
                      setFieldValue={setFieldValue}
                      setFiles={setItineraryFiles}
                      resetTimer={() => { setItinearyUploadFileCount([...itinearyUploadFileCount, 0]); showTimerItinearyFile() }}
                      files={itineraryFiles}
                    />

                    {/* <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                      {formik.values.itinerary_documents.map((doc) => {
                        // let fileUrlParts = doc.path.split('/');
                        // let fileName = fileUrlParts[fileUrlParts.length - 1];
                        return (
                          <> */}
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>             
                      {formik.values.itinerary_documents.map((doc, index) => {
                        let tempFile = doc?.path?.split('/')
                        let itineraryFileName = tempFile?.[tempFile?.length - 1]
                        return (
                          <>
                            <div style={{ border: "1px solid #ccc", padding: "10px", display: "inline-grid", margin: "10px 10px 0px 0px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", gap: "15px", alignItems: "flex-start" }}>
                              <div style={{ display: "flex", gap: "15px", marginBottom: "10px" }}>
                                <img src={fileIcon} style={{ width: "20px"}} />
                                <p>{doc?.file?.name ? doc?.file?.name : itineraryFileName}</p>
                              </div>
                              <img src={binIcon} style={{cursor: "pointer" }} type="button" onClick={() => deleteDocHandler('itinerary_documents', doc.id)} />
                            </div>
                            {itinearyUploadFileCount?.[index] ?
                              <LinearProgress variant="determinate"
                                value={itinearyUploadFileCount[index]} />
                              : <></>}
                          </div>
                          </>
                        );
                      })}
                      {
                        itinearyUploadFileCount.map(item => (
                          <LinearProgress variant="determinate"
                            value={item} />
                        ))
                      }
                    </div>

                    {/* <div onClick={() =>  style={{ border: "1px solid #000", padding: "10px",display:"inline-grid" }}>
                      <div style={{ display: "flex", justifyContent: "space-between",gap:"15px",alignItems:"flex-start" }}>
                        <div style={{display:"flex",gap:"15px",marginBottom:"10px"}}>
                          <img src={fileIcon} style={{ width: "20px" }} />
                          <p>file name</p>
                        </div>
                        <img src={binIcon} />
                      </div>
                      <LinearProgress variant="determinate"
                        value={uploadOrDownloadCount} />
                    </div> */}

                    {/* <aside>
                      <h4>Accepted files:</h4>
                      <ul>{acceptedFileItems}</ul>
                      <h4>Rejected files:</h4>
                      <ul>{fileRejectionItems}</ul>
                    </aside> */}
                  </section>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '50px' }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: ' #344054',
                      paddingBottom: '5px',
                    }}
                  >
                    Programme materials
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#667085',
                    }}
                  >
                    Add the programme materials.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Editor
                    stripPastedStyles={false}
                    defaultEditorState={materialeditorState}
                    editorState={materialeditorState}
                    wrapperClassName={classes.wrapper_class}
                    editorClassName={classes.editor_class}
                    toolbarClassName={classes.toolbar_class}
                    onEditorStateChange={onmaterialEditorStateChange}
                    onPastedText={handlePastedText}
                    toolbar={{
                      options: ['inline', 'blockType', 'list', 'link'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                      },
                    }}
                  />
                  If you not want to change files than dont select anything.
                  <MaterialDropZone
                    classes={classes}
                    values={formik.values.material_documents}
                    setFieldValue={setFieldValue}
                    setFiles={setMaterialFiles}
                    resetTimer={() => { setUploadOrDownloadCount([...uploadOrDownloadCount, 0]); showTimer() }}
                    files={materialFiles}
                  />
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {formik.values.material_documents.map((doc, index) => {
                     let tempFile = doc?.path?.split('/')
                      let fileName = tempFile?.[tempFile?.length - 1]
                      return (
                        <>
                          <div style={{ border: "1px solid #ccc", padding: "10px", display: "inline-grid", margin: "10px 10px 0px 0px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", gap: "15px", alignItems: "flex-start" }}>
                              <div style={{ display: "flex", gap: "15px", marginBottom: "10px" }}>
                                <img src={fileIcon} style={{ width: "20px"}} />
                                <p>{doc?.materialFiles?.name ? doc?.materialFiles?.name : fileName}</p>
                              </div>
                              <img src={binIcon} style={{cursor: "pointer" }} type="button" onClick={() => deleteDocHandler('material_documents', doc.id)} />
                            </div>
                            {
                            uploadOrDownloadCount?.[index] ?
                              <LinearProgress variant="determinate"
                                value={uploadOrDownloadCount[index]} />
                              : <></>}
                          </div>

                          {/* <div style={{ textAlign: 'center' }}>
                            <img src={file} style={{ width: '100px', height: '100px' }} />
                            <button type="button" onClick={() => deleteDocHandler('material_documents', doc.id)}>
                              Delete
                            </button>
                          </div> */}
                        </>
                      );
                    })}
                    {
                     
                      uploadOrDownloadCount.map(item => (
                        // let fileUrlParts = item.path.split('/'),
                        // let fileName = fileUrlParts[fileUrlParts.length - 1],
                        <LinearProgress variant="determinate"
                          value={item} />
                      ))
                    }

                  </div>
                </Grid>
              </Grid>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mt: 3 }}>
            <Button
              onClick={() => {
                navigate('/dashboard/programmes', { replace: true });
                clearData();
              }}
              variant="outlined"
              sx={{ marginRight: '1rem' }}
              size="large"
            >
              Cancel
            </Button>
            <LoadingButton size="large" type="submit" variant="contained" loading={loadingState}>
              Save
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <Toaster />
    </div>
  );
}
import React, { useEffect, useState } from 'react';

// import deepcopy from 'deepcopy';
import ReactApexChart from 'react-apexcharts';

function Graph({ DashboardData }) {
  // const privacy = useSelector((state) => state.PortfolioTrackerReducer.privacy);
  const xAxisData = DashboardData?.map((item) => item?.date);
  const YAxisData = DashboardData?.map((item) => item?.count);

  const [state, setState] = useState({
    series: [
      {
        name: 'series1',
        data: YAxisData,
      },
    ],
    options: {
      chart: {
        height: 150,
        type: 'area',
        foreColor: '#ffffff',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#12B76A'],

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.3,
          colorStops: [
            {
              offset: 0,
              color: '#12B76A',
              opacity: 0.4,
            },
            {
              offset: 16.66,
              color: '#12B76A',
              opacity: 0.35,
            },
            {
              offset: 33.33,
              color: '#12B76A',
              opacity: 0.3,
            },
            {
              offset: 49.99,
              color: '#12B76A',
              opacity: 0.25,
            },
            {
              offset: 66.65,
              color: '#12B76A',
              opacity: 0.2,
            },
            {
              offset: 83.31,
              color: '#12B76A',
              opacity: 0.15,
            },
            {
              offset: 100,
              color: '#12B76A',
              opacity: 0.1,
            },
          ],
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        type: 'datetime',
        categories: xAxisData,
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        enabled: false,
        theme: 'dark',
        x: {
          show: false,
        },
        y: {
          show: false,
        },
        items: {
          display: 'none',
        },
      },
      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
  });

  // useEffect(() => {
  //   const cloneState = deepcopy(state);
  //   cloneState.options.yaxis.labels.show = privacy;
  //   setState(cloneState);
  // }, [privacy]);

  //   };

  return (
    <>
      <ReactApexChart options={state.options} series={state.series} type="area" height={150} width={'250px'} />
    </>
  );
}

export default Graph;

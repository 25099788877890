import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box } from '@mui/material';
// components
import { makeStyles } from '@mui/styles';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { base_url } from 'src/common';
import Graph from './DashboardGraph/positiveGraph';
import NegativeGraph from './DashboardGraph/negativePath';

const useStyle = makeStyles((theme) => ({
  cardContainer: {
    background: '#FFFFFF',
    border: '1px solid #EAECF0',
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    borderRadius: '8px',
    width: '100%',
    padding: '40px',
    paddingBottom: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    '& .headingTxt': {
      fontFamily: 'inherit',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '16px',
      color: '#101828',
      letterSpacing: '0.2px',
      paddingBottom: '24px',
    },
    '& .headingTxtnumber': {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '36px',
      letterSpacing: '-0.02em',
      color: '#101828',
      paddingBottom: '16px',
    },
    '& .percentage': {
      fontFamily: 'sans-serif',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      color: '#667085',
    },
  },
}));
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const classes = useStyle();
  const { role } = JSON.parse(sessionStorage.getItem('mentoring_user')) || '';
  const [DashboardData, setDashboardData] = useState('');
  const token = sessionStorage.getItem('libra_admin_token');
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios
      .get(`${base_url}/api/dashboard`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log("checking result for client dasgboard",res)
        setDashboardData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  if (role === 'company') {
    return (
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Dashboard
          </Typography>

          <Grid container columnSpacing={{ xs: 0, sm: 1, md: 2, lg: 4 }} rowSpacing={{ xs: 3, sm: 4, md: 4, lg: 5 }}>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Clients</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_clients}
                  </Typography>
                  {console.log("Dashboard value check",DashboardData)}
                  {DashboardData?.change_in_percentage_clients >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_clients || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_clients || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_clients >= 0 ? (
                  <>
                    {' '}
                    {DashboardData?.data_clients_count && <Graph DashboardData={DashboardData?.data_clients_count} />}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_clients_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_clients_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Programmes</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_programmes}
                  </Typography>
                  {DashboardData?.change_in_percentage_programmes >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_programmes || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_programmes || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_programmes >= 0 ? (
                  <>
                    {DashboardData?.data_programmes_count && (
                      <Graph DashboardData={DashboardData?.data_programmes_count} />
                    )}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_programmes_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_programmes_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Participants</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_participants}
                  </Typography>
                  {DashboardData?.change_in_percentage_participants >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_participants || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_participants || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_participants >= 0 ? (
                  <>
                    {DashboardData?.data_participants_count && (
                      <Graph DashboardData={DashboardData?.data_participants_count} />
                    )}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_participants_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_participants_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Coaches</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_coaches}
                  </Typography>
                  {DashboardData?.change_in_percentage_coaches >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_coaches || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_sessions || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_coaches >= 0 ? (
                  <>
                    {DashboardData?.data_coaches_count && <Graph DashboardData={DashboardData?.data_coaches_count} />}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_coaches_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_coaches_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  } else if (role === 'admin') {
    return (
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Dashboard
          </Typography>

          <Grid container columnSpacing={{ xs: 0, sm: 1, md: 2, lg: 4 }} rowSpacing={{ xs: 3, sm: 4, md: 4, lg: 5 }}>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Clients</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_clients}
                  </Typography>
                  {DashboardData?.change_in_percentage_clients >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_clients || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_clients || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_clients >= 0 ? (
                  <>
                    {' '}
                    {DashboardData?.data_clients_count && <Graph DashboardData={DashboardData?.data_clients_count} />}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_clients_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_clients_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Programmes</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_programmes}
                  </Typography>
                  {DashboardData?.change_in_percentage_programmes >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_programmes || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_programmes || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_programmes >= 0 ? (
                  <>
                    {DashboardData?.data_programmes_count && (
                      <Graph DashboardData={DashboardData?.data_programmes_count} />
                    )}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_programmes_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_programmes_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Participants</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_participants}
                  </Typography>
                  {DashboardData?.change_in_percentage_participants >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_participants || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_participants || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_participants >= 0 ? (
                  <>
                    {DashboardData?.data_participants_count && (
                      <Graph DashboardData={DashboardData?.data_participants_count} />
                    )}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_participants_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_participants_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Coaching Provider</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_companies}
                  </Typography>
                  {DashboardData?.change_in_percentage_companies >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_companies || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_companies || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_companies >= 0 ? (
                  <>
                    {DashboardData?.data_companies_count && (
                      <Graph DashboardData={DashboardData?.data_companies_count} />
                    )}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_companies_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_companies_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  } else if (role === 'participant') {
    return (
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Dashboard
          </Typography>

          <Grid container columnSpacing={{ xs: 0, sm: 1, md: 2, lg: 4 }} rowSpacing={{ xs: 3, sm: 4, md: 4, lg: 5 }}>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Programmes</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_programmes}
                  </Typography>
                  {DashboardData?.change_in_percentage_programmes >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_programmes || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_programmes || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_programmes >= 0 ? (
                  <>
                    {' '}
                    {DashboardData?.data_programmes_count && (
                      <Graph DashboardData={DashboardData?.data_programmes_count} />
                    )}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_programmes_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_programmes_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Sessions</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_sessions}
                  </Typography>
                  {DashboardData?.change_in_percentage_sessions >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_sessions || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_sessions || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_sessions >= 0 ? (
                  <>
                    {DashboardData?.data_sessions_count && <Graph DashboardData={DashboardData?.data_sessions_count} />}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_sessions_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_sessions_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Feedback</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_feedback}
                  </Typography>
                  {DashboardData?.change_in_percentage_feedback >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_feedback || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_feedback || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_feedback >= 0 ? (
                  <>
                    {DashboardData?.data_feedback_count && <Graph DashboardData={DashboardData?.data_feedback_count} />}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_feedback_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_feedback_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  } else if (role === 'coach') {
    return (
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Dashboard
          </Typography>
          <Grid container columnSpacing={{ xs: 0, sm: 1, md: 2, lg: 4 }} rowSpacing={{ xs: 3, sm: 4, md: 4, lg: 5 }}>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Clients</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_clients}
                  </Typography>
                  {DashboardData?.change_in_percentage_clients >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_clients || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_sessions || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_clients >= 0 ? (
                  <>
                    {DashboardData?.data_clients_count && <Graph DashboardData={DashboardData?.data_clients_count} />}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_clients_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_clients_count} />
                    )}
                  </>
                )}
              </Box>

              {/* <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Programmes</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_programmes}
                  </Typography>
                  {DashboardData?.change_in_percentage_programmes >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_programmes || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_programmes || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_programmes >= 0 ? (
                  <>
                    {' '}
                    {DashboardData?.data_programmes_count && (
                      <Graph DashboardData={DashboardData?.data_programmes_count} />
                    )}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_programmes_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_programmes_count} />
                    )}
                  </>
                )}
              </Box> */}
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Clients</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_clients}
                  </Typography>
                  {DashboardData?.change_in_percentage_clients >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_clients || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_sessions || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_clients >= 0 ? (
                  <>
                    {DashboardData?.data_clients_count && <Graph DashboardData={DashboardData?.data_clients_count} />}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_clients_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_clients_count} />
                    )}
                  </>
                )}
              </Box> */}
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Programmes</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_programmes}
                  </Typography>
                  {DashboardData?.change_in_percentage_programmes >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_programmes || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_programmes || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_programmes >= 0 ? (
                  <>
                    {' '}
                    {DashboardData?.data_programmes_count && (
                      <Graph DashboardData={DashboardData?.data_programmes_count} />
                    )}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_programmes_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_programmes_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Participants</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_participants}
                  </Typography>
                  {DashboardData?.change_in_percentage_participants >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_participants || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_participants || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_participants >= 0 ? (
                  <>
                    {DashboardData?.data_participants_count && (
                      <Graph DashboardData={DashboardData?.data_participants_count} />
                    )}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_participants_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_participants_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Feedback</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_feedback}
                  </Typography>
                  {DashboardData?.change_in_percentage_feedback >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_feedback || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_feedback || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_feedback >= 0 ? (
                  <>
                    {DashboardData?.data_feedback_count && <Graph DashboardData={DashboardData?.data_feedback_count} />}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_feedback_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_feedback_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  } else if (role === 'client') {
    return (
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Dashboard
          </Typography>

          <Grid container columnSpacing={{ xs: 0, sm: 1, md: 2, lg: 4 }} rowSpacing={{ xs: 3, sm: 4, md: 4, lg: 5 }}>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Programmes</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_programmes}
                  </Typography>
                  {DashboardData?.change_in_percentage_programmes >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_programmes || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_programmes || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_programmes >= 0 ? (
                  <>
                    {' '}
                    {DashboardData?.data_programmes_count && (
                      <Graph DashboardData={DashboardData?.data_programmes_count} />
                    )}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_programmes_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_programmes_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Sessions</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_sessions}
                  </Typography>
                  {DashboardData?.change_in_percentage_sessions >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_sessions || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_sessions || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_sessions >= 0 ? (
                  <>
                    {DashboardData?.data_sessions_count && <Graph DashboardData={DashboardData?.data_sessions_count} />}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_sessions_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_sessions_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Participants</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_participants}
                  </Typography>
                  {DashboardData?.change_in_percentage_participants >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_participants || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_participants || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_participants >= 0 ? (
                  <>
                    {DashboardData?.data_participants_count && (
                      <Graph DashboardData={DashboardData?.data_participants_count} />
                    )}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_participants_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_participants_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.cardContainer}>
                <Box>
                  <Typography className="headingTxt">Coaches</Typography>
                  <Typography variant="h6" className="headingTxtnumber">
                    {DashboardData?.total_coaches}
                  </Typography>
                  {DashboardData?.change_in_percentage_coaches >= 0 ? (
                    <Typography className="percentage">
                      <span style={{ color: '#027A48', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8593;</span>{' '}
                        {DashboardData?.change_in_percentage_coaches || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  ) : (
                    <Typography className="percentage">
                      <span style={{ color: '#B42318', fontWeight: '700' }}>
                        <span style={{ fontSize: '17px', fontWeight: '900' }}>&#8595;</span>{' '}
                        {DashboardData?.change_in_percentage_coaches || 0}%
                      </span>{' '}
                      vs last month
                    </Typography>
                  )}
                </Box>
                {DashboardData?.change_in_percentage_coaches >= 0 ? (
                  <>
                    {DashboardData?.data_coaches_count && <Graph DashboardData={DashboardData?.data_coaches_count} />}
                  </>
                ) : (
                  <>
                    {DashboardData?.data_coaches_count && (
                      <NegativeGraph DashboardData={DashboardData?.data_coaches_count} />
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
}
